import axios from "axios";

export const getRemainingImages = async (data) => {
    console.log('data', data);
    try {
        // const mobileimage= localStorage.getItem("mobileImg")
        const response = await axios.post(
            ` https://app.yatter.ai/webs/getstabilityremainimgapi.php`,
            data
        );
        console.log({ 'getRemainingImages': response })
        return response?.data;
    } catch (error) {
        return error;
    }
};