import axios from 'axios';

export const sendDataToWebService = async (data) => {
  const url = 'https://app.yatter.ai/webs/receiveimages.php';

  try {
    const response = await axios.post(url, data);
    console.log('Data successfully sent to the web service:', response.data);
  } catch (error) {
    console.error('Failed to send data to the web service:', error);
  }
};

export const GetAudiofromWebService = async (data) => {
  //const url = 'https://demo.stellans.com/react/getaudio.php';
  const url = 'https://app.yatter.ai/webs/getaudio.php';

  try {
    const response = await axios.post(url, data);
    console.log('res', response);
    console.log(response.data);
    return response.data;
    console.log('Data successfully sent to the web service:', response.data);
  } catch (error) {
    console.error('Failed to send data to the web service:', error);
  }
};

export const dwvideofromWebService = async (data) => {
  const url = 'https://app.yatter.ai/webs/dw.php';

  try {
    const response = await axios.post(url, data);
    console.log('res');
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to send data to the web service:', error);
  }
};

export const uploadImageGenerate = async (data, onUploadProgress) => {
  // return console.log('dta', data);
  const url = 'https://app.yatter.ai/webs/upload.php';

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress, // Use the callback for progress updates
    });
    // console.log('res', response);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to send data to the web service:', error);
  }
};

export const uploadMusicGenerate = async (data, onUploadProgress) => {
  // return console.log('dta', data);
  const url = 'https://app.yatter.ai/webs/uploadmusic.php';

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onUploadProgress, // Use the callback for progress updates
    });
    // console.log('res', response);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to send data to the web service:', error);
  }
};

export const getGenerateImage = async (val) => {
  console.log(val);
  try {
    const { data } = await axios.post(`https://app.yatter.ai/webs/upload_aiimages.php`, val);
    return data
  } catch (error) {
    return error.response.data
  }
}

export const postEmail = async (data) => {
  const url = 'https://app.yatter.ai/webs/sendemail.php';

  try {
    const response = await axios.post(url, data);
    console.log('Email successfully sent to the web service:', response);
    return response
  } catch (error) {
    console.error('Failed to email sent to the web service:', error);
    return error
  }
};

export const AdminpostEmail = async (data) => {
  const url = 'https://app.yatter.ai/webs/sendadminemail.php';

  try {
    const response = await axios.post(url, data);
    //console.log('Email successfully sent to the web service:', response);
    return true;
  } catch (error) {
    //console.error('Failed to email sent to the web service:', error);
    return error;
  }
};

export const postconfirmationEmail = async (data) => {
  const url = 'https://app.yatter.ai/webs/sendconfirmationemail.php';

  try {
    const response = await axios.post(url, data);
    console.log('Email successfully sent to the web service:', response);
    return response
  } catch (error) {
    console.error('Failed to email sent to the web service:', error);
    return error
  }
};

export const supportSubmit = async (val) => {
  console.log('val', val);
  try {
    const { data } = await axios.post(`https://yatter.ai/wp-json/yatter-send/v1/emailsend_continue`, val);
    return data
  } catch (error) {
    return error.response.data
  }
}




/*
// Example usage:
const data = {
  key: 'value',
  // Other data to send
};
sendDataToWebService(data);
*/