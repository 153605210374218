import React, { useEffect, useState, useRef, useContext } from "react";
import Layout from "../../components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUSerVideos } from "../../Api/getUserVideo/getUserVideo";
import ReadyVideoPopup from "../../components/ReadyVideoPopup/ReadyVideoPopup";
import mixpanel, { init } from "mixpanel-browser";
import ModalView from "../../components/common/Modal/ModalView";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";
import { sendRewardEmail } from "../../Api/sendRewardEmail/sendRewardEmail";
import ClaimRewardPopup from "../../components/ClaimRewardPopup/ClaimRewardPopup";
import ClaimRewardSuccessPopup from "../../components/ClaimRewardSuccessPopup/ClaimRewardSuccessPopup";
import MetaTags from "../../components/MetaTags/MetaTags";
import { Footer } from "../../components/Footer/Footer";
import Cookies from "js-cookie";
import { getAiScriptapi } from "../../Api/getAiScriptapi/getAiScriptapi";
import FreeTrialPopup from "../../components/RerenderSignup/FreeTrialPopup";
import axios from "axios";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import { postEmail } from "../../services/SendImageService";
import { updateVideo } from "../../Api/updateVideo/updateVideo";
import { VideoGenerationContext } from "../../VideoGenerationContext";
import ExpirePopup from "../../components/WarningPopup/ExpirePopup";
import OneDaypopup from "../../components/WarningPopup/oneDayPopup";
import { getRemainingImages } from "../../Api/getRemainingImages/getRemainingImages.js";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

export default function Dashboard() {
  const getformData = JSON.parse(localStorage.getItem("getFormData"));
  const scriptout = Cookies.get("scripttxt");


  const videoScript =
    getformData?.subformData?.question || getformData?.subformData?.userPrompt;
  const allData = JSON.parse(localStorage.getItem("alldata"));
  const {
    videoGenerations,
    isGenerating,
    initiateVideoGeneration,
    setVideoGenerations,
    clearIntervalForVideo,
    initialVideos,
    setInitialVideos,
    videosData,
    setVideosData,
    loading,
    setLoading,
    setUser,
    updateInitialVideos,
  } = useContext(VideoGenerationContext);
  // const [videosData, setVideosData] = useState({});
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [firstFourVideos, setFirstFourVideos] = useState([]);
  const [remainingVideos, setRemainingVideos] = useState([]);
  // const [initialVideos, setInitialVideos] = useState([])
  const [delayedRender, setDelayedRender] = useState(false);
  const [isModalPopOpen, setIsModalPopOpen] = useState(false);
  const [isSuccessModalPopOpen, setIsSuccessModalPopOpen] = useState(false);
  const [showAiScript, setShowAiScript] = useState(false);
  const [showCheckMark, setShowCheckMark] = useState(false);
  const [scriptLoading, setScriptLoading] = useState(false);
  const [uploadSelfProgress, setUploadSelfProgress] = useState(0);
  const [isAiTextAreaBlank, setIsAiTextAreaBlank] = useState(false);
  const [subformData, setSubFormData] = useState({
    question: getformData?.subformData?.question || "",
    qabout: getformData?.subformData?.qabout || "",
    inputText: getformData?.subformData?.inputText || "",
  });
  const [isError, setIsError] = useState(false);
  const [isTextAreaBlank, setIsTextAreaBlank] = useState(false);
  const [isUserTextAreaBlank, setIsUserTextAreaBlank] = useState(false);
  const [showUserPromptPopup, setShowUserPromptPopup] = useState(false);
  const [saveUserPrompt, setSaveUserPrompt] = useState(false);
  const [isImageModalPopOpen, setIsImageModalPopOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isFreeTrialPopupOpen, setIsFreeTrialPopupOpen] = useState(
    user ? false : true
  );
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timecompleted, settimeCompleted] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [callVideoApi, setCallVideoApi] = useState(true);
  const [videoPopup, setVideoPopup] = useState(false);
  const [VideoPath, setVideoPath] = useState();
  // const [oneDaypopup, setOneDaypopup] = useState(localStorage.getItem('oneDayPopupvalue') === false ? false : true);
  const initialPopupValue = localStorage.getItem('oneDayPopupvalue');
  const [oneDaypopup, setOneDaypopup] = useState(
    initialPopupValue !== null ? JSON.parse(initialPopupValue) : true
  );
  const [showExpirepopup, setShowExpirepopup] = useState(true);
  // const [videoGenerations, setVideoGenerations] = useState({});

  const days = localStorage.getItem('days')
  const hours = localStorage.getItem('hours')
  const min = localStorage.getItem('min')
  console.log('days', days, hours, min);

  const closeOneDayPopupModal = () => setOneDaypopup(false);
  const closeExpirePopup = () => setShowExpirepopup(false);

  const closePopModal = () => videoPopup(false);

  const videodirectlink = localStorage.getItem("video_url");

  const videoCount = localStorage.getItem("video_count");
  const location = useLocation();
  // console.log(location.state);

  const data = location?.state?.items || [];
  const updatedValue = location?.state?.updatedArray || location?.state?.items
  console.log('updatedValue', updatedValue);
  const userScript = localStorage.getItem("user_script");
  const [progress, setProgress] = useState(0);
  function formatMilliseconds(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${paddedSeconds}`;
  }
  function getFilenameFromURL(fileUrl) {
    const lastSlashIndex = fileUrl.lastIndexOf("/");
    return fileUrl.substring(lastSlashIndex + 1); // Extracts the substring after the last slash
  }
  console.log("videosData", videosData);
  var forreadyvideo = "";
  const wordLimit = user ? 600 : 400;
  console.log({ videodirectlink });
  const onClose = async () => {
    localStorage.removeItem("video_id");
    localStorage.removeItem("video_url");
    setShowModal(false);
  };
  const sendEmail = async (emaildata) => {
    console.log("emaildata", emaildata);
    const resp = await postEmail(emaildata);
    console.log("resp", resp);
    if (resp?.status === 200) {
      // localStorage.removeItem("Email");
      console.log("Video sent successfully to your email");
    } else {
      console.log("Error in sending email", resp);
      // toast.error("Error in sending email", resp);
      // localStorage.removeItem("Email");
    }
  };
  useEffect(() => {
    if (videodirectlink != "undefined" && videodirectlink) {
      setShowModal(true);
    }
  }, [videodirectlink]);

  console.log("location?.state?.call", location?.state?.call);
  useEffect(() => {
    const call = localStorage.getItem("generateVideo");
    console.log("call", call);
    if (call) {
      generateVideo(new Date().getTime());
      localStorage.removeItem("generateVideo");
    }
  }, [user]);

  const handlePayment = () => {
    // window.location.href = 'https://yatter.ai/checkout/?add-to-cart=1284';
    window.location.href = `https://yatter.ai/wp-sso-payment.php?key=${user?.data.user_email}`;
  };

  const generateVideo = (videoId, itemAndVideos) => {
    // const totalDuration = 120000;
    // let elapsedTime = 0;

    // const interval = setInterval(() => {
    //   elapsedTime += 1000;
    //   const calculatedProgress = (elapsedTime / totalDuration) * 100;

    //   setVideoGenerations((prev) => ({
    //     ...prev,
    //     [videoId]: {
    //       ...prev[videoId],
    //       progress: calculatedProgress,
    //       timeRemaining: formatMilliseconds(totalDuration - elapsedTime),
    //     },
    //   }));

    //   if (elapsedTime >= totalDuration) {
    //     if (forreadyvideo == "") {
    //       console.log("video not ready");
    //       elapsedTime = 60000;
    //     } else {
    //       localStorage.setItem("created-video-url", forreadyvideo);
    //       clearInterval(interval);
    // clearIntervalForVideo(videoId);
    //       setVideoGenerations((prev) => ({
    //         ...prev,
    //         [videoId]: {
    //           ...prev[videoId],
    //           progress: 100,
    //           completed: true,
    //         },
    //       }));
    //     }
    //   }
    // }, 1500);
    initiateVideoGeneration(videoId);
    console.log("HJBA", itemAndVideos);
    // localStorage.setItem('alldata', JSON.stringify(data)) //to be used for re-render

    console.log("updatedData1234", data);
    console.log("updatedValue", updatedValue);
    const source = axios.CancelToken.source();
    setCancelToken(source);

    const fetchItemsAndVideos = async () => {
      console.log("updatedValue1", updatedValue);
      if (updatedValue) {
        console.log("updatedValue2", updatedValue);
        // return
        try {
          const response = await axios.post(
            "https://app.yatter.ai/webs/receiveimages.php",
            {
              data: updatedValue,
            },
            { cancelToken: source.token }
          );
          // Handle response data here
          console.log(response?.data);
          // localStorage.setItem("all_images", JSON.stringify(items));

          if (response?.data?.video_url) {

            if (!user) {
              localStorage.setItem("video_id", response?.data?.video_id);
              localStorage.setItem("video_url", response?.data?.video_url);
              // settimeCompleted(true);
            }
            //setforready(response.data);
            const user_id = localStorage.getItem("user_id");
            //setforready(response.data);
            const videoUpdated = await updateVideo(
              user_id,
              response?.data?.video_id
            );
            if (videoUpdated == "successfully updated") {
              localStorage.removeItem("user_id");
            }
            localStorage.setItem("alldata", JSON.stringify(updatedValue));
            user && getUSerVideoCountHandle({ userid: user?.ID });
            localStorage.setItem(
              "created-video-url",
              response?.data?.video_url
            );
            // setTimeout(() => {
            forreadyvideo = response?.data?.video_url;
            // const randomID = Math.random().toString(36).substring(2);
            // const newObj = {
            //   [`videopath${randomID}`]: forreadyvideo, // Replace "Your value here" with the actual value you want to insert
            // };
            // setVideosData({ ...newObj, ...videosData });
            // setFirstFourVideos([forreadyvideo, ...firstFourVideos])
            console.log("initial in ", initialVideos);
            const temp = initialVideos;
            temp.unshift(response?.data?.video_url);
            console.log("tempinitial in ", temp);
            setInitialVideos(temp);
            updateInitialVideos(temp);
            // clearInterval(interval);
            // setTimeout(()=>{
            // clearIntervalForVideo(videoId);
            // settimeCompleted(true);
            // setVideoGenerations((prev) => ({
            //   ...prev,
            //   [videoId]: {
            //     // interval,
            //     progress: 100,
            //     // timeRemaining: formatMilliseconds(totalDuration),
            //     completed: false,
            //   },
            // }));
            // },4000);
            // console.log("ready is");
            //  console.log(forreadyvideo);
            const mixPanelVideo = async () => {
              user?.data?.ID && (await mixpanel.identify(user?.data.ID));
              await mixpanel.track(
                "While video was getting ready- Step 3",
                {
                  Email: user?.data.user_email,
                  "User Id": user?.data.ID || "",
                  "User Type": user?.orders.order_type || "guest",
                  Video: forreadyvideo,
                  "User Script": userScript,
                  "AI Script": scriptout,
                }
              );
              const searchParams = new URLSearchParams(
                window.location.search
              );
              const utmSource = searchParams.get("utm_source");
              await mixpanel.track("Created Video", {
                Email: user?.data.user_email,
                "User Id": user?.data.ID || "",
                "User Type": user?.orders.order_type || "guest",
                UTM: utmSource,
                Video: forreadyvideo,
                "User Script": userScript,
                "AI Script": scriptout,
              });
            };
            mixPanelVideo();
            if (user && forreadyvideo) {
              const filename = data && getFilenameFromURL(forreadyvideo);
              let fulllink =
                "https://app.yatter.ai/webs/video/index.php?v=" +
                filename;
              const emaildata = {
                email: user?.data?.user_email,
                url: fulllink,
              };
              console.log({ emaildata });
              console.log("user coming", user);
              sendEmail(emaildata);
            }
            // navigate("/ready", { state: { forreadyvideo, showMessage: true } });
            // navigate("/dashboard", {
            //   state: { forreadyvideo, showMessage: true,call:true },
            // });

            user &&
              toast.success("Video sent successfully to your email");
            // Cookies.remove('scripttxt')
            // window.addEventListener('load', () => {
            //   // Reset the current state to a default value
            //   window.history.replaceState({}, document.title, window.location);
            // });
            // setTimeout(() => {
            //     window.location.reload(); // Refresh the page
            // }, 5);
            // }, 5000);
          }
          if (timecompleted) {
            console.log("completed response");
            //const forreadyvideo=response.data;
            // setforready(response.data);
          } else {
            console.log("notcompleted yet");
          }
          // navigate("/dashboard", { state: { items: updatedData } });

          // clearInterval(interval);
          clearIntervalForVideo(videoId);
          settimeCompleted(true);
          setVideoGenerations((prev) => ({
            ...prev,
            [videoId]: {
              // interval,
              progress: 100,
              // timeRemaining: formatMilliseconds(totalDuration),
              completed: false,
            },
          }));
        } catch (itemsError) {
          if (axios.isCancel(itemsError)) {
            console.log("API request canceled");
          } else {
            console.error("Error fetching items:", itemsError);
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
          // clearInterval(interval);
          clearIntervalForVideo(videoId);
          settimeCompleted(true);
          setVideoGenerations((prev) => ({
            ...prev,
            [videoId]: {
              // interval,
              progress: 100,
              // timeRemaining: formatMilliseconds(totalDuration),
              completed: false,
            },
          }));
        }
      }
      else {
        console.log("updatedValue3", updatedValue);
        const upitems2=JSON.parse(localStorage.getItem("upitems"));
        const upitemscount2=localStorage.getItem("upitemscount");

        console.log("uptimes e",upitems2);
        console.log("couunt",upitemscount2);

        let items55;
        try {

          if((upitemscount2) && upitemscount2 < 5)
            {
              let remimages1= 5 - upitemscount2;
              const payloadrem = {
                textscript: videoScript,
                mobileimage: localStorage.getItem("mobileImg"),
                remimages:remimages1
              };
              const getImageRemResponse = await getRemainingImages(payloadrem);
             
              console.log('remain img',getImageRemResponse);
              console.log('uptime',upitems2);
              const newDataArray = [
                ...upitems2,
                ...getImageRemResponse.map(item => ({
                  url: item.data.url,
                  type: item.data.type
                }))
              ];
  
              console.log('newdatarray',newDataArray);
  
              items55 = newDataArray.map((item, index) => ({
                ...item,
                vidtext:videoScript
              }));
              console.log('finalnewdatarray',items55);
            }
          
            else
            {
              const payload = {
                textscript: videoScript,
                mobileimage: localStorage.getItem("mobileImg"),
              };
              const data5 = await axios.post(
                "https://app.yatter.ai/webs/getstability6imageapi.php",
                payload,
                { cancelToken: source.token }
              );
              // const items = itemsResponse?.data;
              // console.log("itemsResponse", data)
              const itemsResponse = data5?.data;
    
              // Extract the relevant objects into a new array
              items55 = itemsResponse?.map((item) => item.data[0]);
              console.log("Item Array:", items55);

            }

            console.log('here in',items55);
            console.log("lengh",items55?.length)


          if (items55?.length > 0) {
            const newItems = [...items55];
            const numericKeys = Object.keys(newItems).filter(
              (key) => !isNaN(Number(key))
            );
            const newKey =
              numericKeys.length > 0
                ? Math.max(...numericKeys.map(Number)) + 1
                : 0;
            let newlink = {
              audio: "https://app.yatter.ai/webs/staticcontent/echo.mp3",
            };
            newItems[newKey] = newlink;
            const data = {
              bgMusicData: {
                music_name:
                  "https://app.yatter.ai/webs/staticcontent/backmusic8.mp3",
                music_switch: true,
                Volume: 25,
                music_name_custom: "",
              },
              white_particles: false,
              voice: true,
              Caption: {
                caption_switch: true,
                caption_color: "&HFFFFFF&",
                caption_align: "Bottom",
              },
              MobileVideo: localStorage.getItem("mobileImg"),
              Scripted: { text: videoScript },
              wpuser: {
                id: user?.ID,
                useremail: user?.data?.user_email,
                usertype: user?.orders?.order_type,
              },
              businessInfo: {
                image: "",
                email: "",
                number: "",
                website: "",
              },
            };
            const updatedData = { ...newItems, ...data };
            console.log("updated data",updatedData);
            localStorage.removeItem("upitems");
            localStorage.removeItem("upitemscount");

            await axios
              .post(
                "https://app.yatter.ai/webs/receiveimages.php",
                {
                  data: updatedData,
                },
                { cancelToken: source.token }
              )
              .then((response) => {
                const handleFecthVideoResponse = async () => {
                  console.log(
                    "response.dataresponse.data",
                    response?.data,
                    response?.data?.video_url
                  );
                  if (!user) {
                    localStorage.setItem("video_id", response?.data?.video_id);
                    localStorage.setItem("video_url", response?.data?.video_url);
                    // settimeCompleted(true);
                  }
                  if (response?.data?.video_url) {
                    //setforready(response.data);
                    const user_id = localStorage.getItem("user_id");
                    //setforready(response.data);
                    const videoUpdated = await updateVideo(
                      user_id,
                      response?.data?.video_id
                    );
                    if (videoUpdated == "successfully updated") {
                      localStorage.removeItem("user_id");
                    }
                    localStorage.setItem("alldata", JSON.stringify(updatedData));
                    user && getUSerVideoCountHandle({ userid: user?.ID });
                    localStorage.setItem(
                      "created-video-url",
                      response?.data?.video_url
                    );
                    // setTimeout(() => {
                    forreadyvideo = response?.data?.video_url;
                    // const randomID = Math.random().toString(36).substring(2);
                    // const newObj = {
                    //   [`videopath${randomID}`]: forreadyvideo, // Replace "Your value here" with the actual value you want to insert
                    // };
                    // setVideosData({ ...newObj, ...videosData });
                    // setFirstFourVideos([forreadyvideo, ...firstFourVideos])
                    console.log("initial in ", initialVideos);
                    const temp = initialVideos;
                    temp.unshift(response?.data?.video_url);
                    console.log("tempinitial in ", temp);
                    setInitialVideos(temp);
                    updateInitialVideos(temp);
                    // clearInterval(interval);
                    // setTimeout(()=>{
                    // clearIntervalForVideo(videoId);
                    // settimeCompleted(true);
                    // setVideoGenerations((prev) => ({
                    //   ...prev,
                    //   [videoId]: {
                    //     // interval,
                    //     progress: 100,
                    //     // timeRemaining: formatMilliseconds(totalDuration),
                    //     completed: false,
                    //   },
                    // }));
                    // },4000);
                    // console.log("ready is");
                    //  console.log(forreadyvideo);
                    const mixPanelVideo = async () => {
                      user?.data?.ID && (await mixpanel.identify(user?.data.ID));
                      await mixpanel.track(
                        "While video was getting ready- Step 3",
                        {
                          Email: user?.data.user_email,
                          "User Id": user?.data.ID || "",
                          "User Type": user?.orders.order_type || "guest",
                          Video: forreadyvideo,
                          "User Script": userScript,
                          "AI Script": scriptout,
                        }
                      );
                      const searchParams = new URLSearchParams(
                        window.location.search
                      );
                      const utmSource = searchParams.get("utm_source");
                      await mixpanel.track("Created Video", {
                        Email: user?.data.user_email,
                        "User Id": user?.data.ID || "",
                        "User Type": user?.orders.order_type || "guest",
                        UTM: utmSource,
                        Video: forreadyvideo,
                        "User Script": userScript,
                        "AI Script": scriptout,
                      });
                    };
                    mixPanelVideo();
                    if (user && forreadyvideo) {
                      const filename = data && getFilenameFromURL(forreadyvideo);
                      let fulllink =
                        "https://app.yatter.ai/webs/video/index.php?v=" +
                        filename;
                      const emaildata = {
                        email: user?.data?.user_email,
                        url: fulllink,
                      };
                      console.log({ emaildata });
                      console.log("user coming", user);
                      sendEmail(emaildata);
                    }
                    // navigate("/ready", { state: { forreadyvideo, showMessage: true } });
                    // navigate("/dashboard", {
                    //   state: { forreadyvideo, showMessage: true,call:true },
                    // });

                    user &&
                      toast.success("Video sent successfully to your email");
                    // Cookies.remove('scripttxt')
                    // window.addEventListener('load', () => {
                    //   // Reset the current state to a default value
                    //   window.history.replaceState({}, document.title, window.location);
                    // });
                    // setTimeout(() => {
                    //     window.location.reload(); // Refresh the page
                    // }, 5);
                    // }, 5000);
                  } else if (response?.data?.errmsg) {
                    // navigate('/')
                    toast.error(
                      "Errors may happen because everyone wants to use Yatter AI at the same time. " +
                      "It seems it's taking longer than usual - go back to the beginning or " +
                      "send us a request to be on a free priority list at info@yatter.ai",
                      {
                        autoClose: 10000,
                      }
                    );
                    // clearInterval(interval);
                    clearIntervalForVideo(videoId);
                    settimeCompleted(true);
                    setVideoGenerations((prev) => ({
                      ...prev,
                      [videoId]: {
                        // interval,
                        progress: 100,
                        // timeRemaining: formatMilliseconds(totalDuration),
                        completed: false,
                      },
                    }));
                  }
                  if (timecompleted) {
                    console.log("completed response");
                    //const forreadyvideo=response.data;
                    // setforready(response.data);
                  } else {
                    console.log("notcompleted yet");
                  }
                };
                handleFecthVideoResponse();
              })
              .catch((error) => {
                if (axios.isCancel(error)) {
                  console.log("API request canceled");
                } else {
                  console.error("Error during the POST request", error);
                  toast.error(
                    "Errors may happen because everyone wants to use Yatter AI at the same time. " +
                    "It seems it's taking longer than usual - go back to the beginning or " +
                    "send us a request to be on a free priority list at info@yatter.ai",
                    {
                      autoClose: 10000,
                    }
                  );
                }
                // clearInterval(interval);
                clearIntervalForVideo(videoId);
                settimeCompleted(true);
                setVideoGenerations((prev) => ({
                  ...prev,
                  [videoId]: {
                    // interval,
                    progress: 100,
                    // timeRemaining: formatMilliseconds(totalDuration),
                    completed: false,
                  },
                }));
              });
            // navigate("/dashboard", { state: { items: updatedData } });
          } else {
            // navigate('/')
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
          // clearInterval(interval);
          clearIntervalForVideo(videoId);
          settimeCompleted(true);
          setVideoGenerations((prev) => ({
            ...prev,
            [videoId]: {
              // interval,
              progress: 100,
              // timeRemaining: formatMilliseconds(totalDuration),
              completed: false,
            },
          }));
        } catch (itemsError) {
          if (axios.isCancel(itemsError)) {
            console.log("API request canceled");
          } else {
            console.error("Error fetching items:", itemsError);
            toast.error(
              "Errors may happen because everyone wants to use Yatter AI at the same time. " +
              "It seems it's taking longer than usual - go back to the beginning or " +
              "send us a request to be on a free priority list at info@yatter.ai",
              {
                autoClose: 10000,
              }
            );
          }
          // clearInterval(interval);
          clearIntervalForVideo(videoId);
          settimeCompleted(true);
          setVideoGenerations((prev) => ({
            ...prev,
            [videoId]: {
              // interval,
              progress: 100,
              // timeRemaining: formatMilliseconds(totalDuration),
              completed: false,
            },
          }));
        }

      }
    };

    fetchItemsAndVideos();
    // }

    // return () => {
    //   clearInterval(interval);
    // clearIntervalForVideo(videoId);
    // };
  };
  console.log(
    "initialvideos,",
    initialVideos,
    "firstFourVideos",
    firstFourVideos
  );
  // useEffect(() => {
  //   return () => {
  //     // Clear all intervals when component unmounts
  //     Object.values(videoGenerations).forEach((video) =>
  //       clearInterval(video.interval)
  //     );
  //   };
  // }, [videoGenerations]);
  console.log("videoGenerations", videoGenerations);
  const simulateProgress = () => {
    let progress = 0;
    const intervalId = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        setUploadSelfProgress(progress);
      } else {
        clearInterval(intervalId);
      }
    }, 150); // Interval in milliseconds (150ms for smoother animation)
    return intervalId;
  };
  console.log("progress", progress);

  const fetchAiScript = async (e, premiumTip) => {
    e.preventDefault();
    console.log(
      "subformData?.userPrompt?.trim()",
      subformData?.userPrompt?.trim()
    );
    if (subformData?.userPrompt?.trim() === "" || !subformData?.userPrompt) {
      // toast.error('Type your video script to continue')
      setIsTextAreaBlank(true);
      return;
    }
    console.log(
      "!subformData?.question?.trim() ===",
      subformData?.question,
      subformData?.question !== ""
    );
    // if (subformData?.question !== "") {
    //   if(!isEdit){
    //     setShowAiScript(true)
    //     setShowCheckMark(true)
    //     return
    //   }
    // }

    localStorage.removeItem("all_images");
    localStorage.removeItem("pexel_videos");
    localStorage.removeItem("imagePageState");
    setScriptLoading(true);
    const intervalId = simulateProgress();
    try {
      // premiumTip && setSubFormData({...subformData, question: premiumTip});
      const script = subformData?.userPrompt?.trim();
      localStorage.setItem("user_script", script);
      user?.data?.ID && (await mixpanel.identify(user?.data.ID));
      await mixpanel.track("Choosing Script - Video Creation Step 1", {
        Email: user?.data.user_email,
        "User Id": user?.data.ID || "",
        "User Type": user?.orders.order_type || "guest",
        Script: script,
      });
      const data = await getAiScriptapi({ textscript: script });
      console.log("data geting ai script", data);
      if (data?.textscript) {
        console.log({ textscript: data?.textscript });
        const text = data?.textscript?.trim();
        setSubFormData({ ...subformData, question: text, userPrompt: script });
        setScriptLoading(false);
        clearInterval(intervalId);
        setUploadSelfProgress(0);
        setShowAiScript(true);
        setShowCheckMark(true);
        setIsTextAreaBlank(false);
        // setIsEdit(true);
      } else {
        toast.error(
          "If you can't proceed to the next step - not to worry. Our servers are experiencing a lot of traffic. Simply come back a little later and try it again. Thank you!",
          {
            autoClose: 10000,
          }
        );
        console.error({ error: data });
        const temp = { ...subformData, userPrompt: "" };
        setSubFormData(temp);
        setScriptLoading(false);
        clearInterval(intervalId);
        setUploadSelfProgress(0);
        setShowAiScript(false);
        // window.location.reload();
      }
    } catch (error) {
      toast.error(
        "If you can't proceed to the next step - not to worry. Our servers are experiencing a lot of traffic. Simply come back a little later and try it again. Thank you!",
        {
          autoClose: 10000,
        }
      );
      console.error("Error generating AI script:", error);
      const temp = { ...subformData, userPrompt: "" };
      setSubFormData(temp);
      setScriptLoading(false);
      clearInterval(intervalId);
      setUploadSelfProgress(0);
      setShowAiScript(false);
      // window.location.reload();
    }
  };
  const handleformSubmit = async (e, videoRatio) => {
    e.preventDefault();
    setIsImageModalPopOpen(false);
    localStorage.setItem("mobileImg", videoRatio);
    // if(!isEdit){
    //   navigate('/get-images')
    //   setShowAiScript(false)
    //   setShowCheckMark(false)
    //   return
    // }
    if (saveUserPrompt) {
      setSubFormData({ ...subformData, userPrompt: undefined });
    }
    if (!subformData?.userPrompt && !subformData?.question) {
      toast.error("Something went wrong! Please try writing your idea again.");
      return;
    }
    localStorage.removeItem("all_images");
    localStorage.removeItem("pexel_videos");
    localStorage.removeItem("imagePageState");
    console.log("subformData?.question", subformData?.question);
    const textAreaValue = saveUserPrompt
      ? subformData?.userPrompt
      : subformData?.question;
    // if (!Array.isArray(textAreaValue) && !textAreaValue?.trim()) {
    //     setIsTextAreaBlank(true);
    //     setLoading(false);
    // }

    const dataToStore = {
      subformData: {
        ...subformData,
        question: saveUserPrompt
          ? ""
          : subformData?.question?.replace(/(\r\n|\n|\r)/gm, ""),
        userPrompt: subformData?.userPrompt?.replace(/(\r\n|\n|\r)/gm, ""),
      },
      // caption: caption
    };
    // const image = await openai.images.generate({ model: "dall-e-3", prompt: subformData.question });
    // console.log('img', image.data);
    // console.log(image.data);

    console.log("subformDatasubformData", subformData);
    console.log("dataToStoredataToStore", dataToStore);
    console.log("subformData.qabout", subformData.qabout);
    // setLoading(true);
    const InputValueq = saveUserPrompt
      ? subformData?.userPrompt?.replace(/(\r\n|\n|\r)/gm, "")
      : subformData?.question?.replace(/(\r\n|\n|\r)/gm, "");
    console.log("subformData.question", subformData.question);
    localStorage.setItem("getFormData", JSON.stringify(dataToStore));
    Cookies.set("scripttxt", InputValueq, { path: "/", domain: ".yatter.ai" });
    Cookies.set("scripttxt", InputValueq);
    setSaveUserPrompt(false);
    user?.data?.ID && (await mixpanel.identify(user?.data.ID));
    await mixpanel.track("Choosing AI Script", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || "",
      "User Type": user?.orders.order_type || "guest",
      "AI Script": dataToStore?.subformData?.question,
      "User Script": subformData?.userPrompt?.trim(),
    });
    setIsModalPopOpen(false);
    generateVideo(new Date().getTime(), true);
    // if (user) {
    //   navigate("/get-images", {
    //     state: {
    //       subFormData: subformData,
    //     },
    //   });
    // } else {
    //   // generateVideo(new Date().getTime(), true);
    // }
  };

  const handlevalueChange = (event) => {
    // setIsEdit(true);
    const inputValue = event.target.value;
    console.log(
      "event.target.name",
      event.target.name,
      "event.target.value",
      inputValue
    );
    // const words = inputValue.trim().split(/\s+/); // Split by whitespace to get words

    if (inputValue.length > wordLimit) {
      setIsError(true);
      setSubFormData({
        ...subformData,
        [event.target.name]: inputValue,
        inputText: inputValue,
      });
    } else {
      setSubFormData({
        ...subformData,
        [event.target.name]: inputValue,
        inputText: inputValue,
      });
      setIsError(false);
      setIsTextAreaBlank(false);
      setIsUserTextAreaBlank(false);
      setIsAiTextAreaBlank(false);
    }
  };

  const handlePlay = (index) => {
    const video = document.getElementById(`video_${index}`);
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  };
  const handleVideoPopup = (index) => {
    // const video = document.getElementById(`video_${index}`);
    // const video = document.getElementById(`video_${index}`);
    // video.play();
    setVideoPath(index)
    setVideoPopup(true)
    return console.log('videoff', index);

  };
  const handleVideoPlay = () => {
    const video = document.getElementById(`video_${VideoPath}`);

    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const trackPath = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get("utm_source");
    user?.data?.ID && (await mixpanel.identify(user?.data.ID));
    await mixpanel.track("Dashboard - Home", {
      Email: user?.data.user_email,
      "User Type": user?.orders.order_type || "guest",
      Video: videodirectlink,
      "Number of Video Created": Object.keys(videosData).length,
      UTM: utmSource,
    });
  };
  useEffect(() => {
    if (Object.keys(videosData).length !== 0) {
      trackPath();
    }
  }, [videosData]);

  useEffect(() => {
    if (Object.keys(videosData).length > 4) {
      const videoUrls = Object.values(videosData);
      // Set the first 4 videos
      setFirstFourVideos(videoUrls.slice(0, 4));
      // Set the remaining videos after  6 seconds
      const timeout = setTimeout(() => {
        setRemainingVideos(videoUrls.slice(4));
        setDelayedRender(true);
      }, 6000);

      return () => clearTimeout(timeout);
    } else {
      const videoUrls = Object.values(videosData);
      setFirstFourVideos(videoUrls);
    }
  }, [videosData]);
  useEffect(() => {
    if (loading) {
      let limit;

      if (videosData && videosData.length < 10) {
        limit = 10000;
      } else if (
        videosData &&
        videosData.length >= 10 &&
        videosData.length < 20
      ) {
        limit = 15000;
      } else {
        limit = 20000;
      }
      const timeout = setTimeout(() => {
        setLoading(false);
      }, limit);

      return () => clearTimeout(timeout);
    }
  }, [loading]);

  const handleOneDaypopup = () => {
    console.log('close');
    setOneDaypopup(false)
    localStorage.setItem('oneDayPopupvalue', false)
  }

  //not in use code.
  // useEffect(() => {
  //   const timers = {};

  //   Object.entries(videoGenerations).forEach(([videoId, videoData]) => {
  //     if (videoData.progress < 100) {
  //       timers[videoId] = setInterval(() => {
  //         setProgress((prevProgress) => {
  //           const newProgress = { ...prevProgress };
  //           newProgress[videoId] =
  //             (newProgress[videoId] || videoData.progress) + 0.1;
  //           if (newProgress[videoId] >= 100) {
  //             clearInterval(timers[videoId]);
  //             newProgress[videoId] = 100; // Ensure it stops at 100
  //           }
  //           return newProgress;
  //         });
  //       }, 120); // 0.1% increment every 120ms for 2 minutes completion
  //     }
  //   });

  //   return () => {
  //     Object.values(timers).forEach(clearInterval);
  //   };
  // }, [videoGenerations]);

  const renderVideoBlocks = (videos, IsInitialVideos) => {
    return videos?.map((videoPath, index) => {
      const downloadPath = `https://app.yatter.ai/webs/video/index.php?v=${videoPath.substring(
        videoPath.lastIndexOf("/") + 1
      )}`;
      console.log("videoPath", videoPath);
      const videoName = videoPath.substring(
        videoPath.lastIndexOf("/") + 1,
        videoPath.lastIndexOf(".mp4")
      );

      return (
        <div className="video-block" key={`video_${videoPath}`}>
          <MetaTags
            keyword="AI video creator"
            description="Create amazing AI videos for free. Just use our powerful AI video creator. Yatter is the #1 text-to-video tool for everyone!"
          />
          <div className="video-inner">
            <button
              type="button"
              className="btn-circle play"
              onClick={(e) => {
                e.stopPropagation();
                const videoElement = document.getElementById(`video_${videoPath}`);
                if (videoElement.paused || videoElement.ended) {
                  // If video is paused or ended, play it
                  if (videoElement.requestFullscreen) {
                    videoElement.requestFullscreen();
                  } else if (videoElement.mozRequestFullScreen) {
                    videoElement.mozRequestFullScreen();
                  } else if (videoElement.webkitRequestFullscreen) {
                    videoElement.webkitRequestFullscreen();
                  } else if (videoElement.msRequestFullscreen) {
                    videoElement.msRequestFullscreen();
                  }
                  videoElement.play(); // Play the video
                } else {
                  // If video is playing, pause it
                  videoElement.pause(); // Pause the video
                }

                // Function to handle pausing video when exiting fullscreen mode
                const handleFullscreenChange = () => {
                  if (
                    !document.fullscreenElement &&
                    !document.webkitIsFullScreen &&
                    !document.mozFullScreen &&
                    !document.msFullscreenElement
                  ) {
                    videoElement.pause(); // Pause the video when exiting fullscreen mode
                  }
                };

                // Add event listener for fullscreen change
                document.addEventListener("fullscreenchange", handleFullscreenChange);
                document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
                document.addEventListener("mozfullscreenchange", handleFullscreenChange);
                document.addEventListener("MSFullscreenChange", handleFullscreenChange);
              }}
            >

              {/* <button
              type="button"
              className="btn-circle play"
              onClick={(e) => {
                // e.stopPropagation();
                // handlePlay(videoPath);
                handleVideoPopup(videoPath)
              }}
            > */}
              <span className="material-icons icon">play_arrow</span>
            </button>
            <video id={`video_${videoPath}`}>
              <source src={videoPath} type="video/mp4" />
            </video>
          </div>
          <div className="video-meta">
            <h5
              className="name"
              style={{ width: "180px", wordBreak: "break-all" }}
            >
              {videoName}
            </h5>
            {/* <a
              className="btn-download-flat"
              href={downloadPath}
              target="_blank"
            >
              <span className="material-symbols-outlined mr-1">download</span>
              Download
            </a> */}
          </div>
          <div className="video-action flex">
            <a
              type="button"
              className="btn-download-flat w-full !cursor-pointer"
              href={downloadPath}
              target="_blank"
            >
              <span className="material-symbols-outlined mr-1"> download </span>{" "}
              Export
            </a>
            {index === 0 && allData && IsInitialVideos && (
              <button
                type="button"
                className="btn-download-flat w-full cursor-pointer"
                onClick={() => navigate("/rerender")}
              >
                <span className="material-symbols-outlined mr-1"> edit </span>{" "}
                Edit
              </button>
            )}
          </div>
        </div >
      );
    });
  };
  const pageContainer = { maxWidth: "1280px", position: "static" };
  // useEffect(() => {
  //   return () => {
  //     if (cancelToken) {
  //       cancelToken.cancel();
  //     }
  //   };
  // }, [cancelToken]);
  return (
    <>
      <Layout HeaderName="Dashboard" hideFooter={true}>
        <div
          className={`page-container ${!user && "custome-page-container-dashboard"
            }`}
        >
          <div
            class="page-wrapper"
            // style={{
            //   opacity: `${
            //     videodirectlink != "undefined" && videodirectlink ? "0.5" : 1
            //   }`,
            // }}
            onClick={() => {
              !user && setIsFreeTrialPopupOpen(true);
            }}
          >
            <div className="my-generated-wrap">
              <div class="card-wrap">
                <div class="card-head">
                  <h2>My Professional AI videos</h2>
                </div>

                {user &&
                  Object.entries(videoGenerations).map(
                    // ([videoId, { progress, timeRemaining }], index) => (
                    ([videoId, videoData], index) =>
                      videoData.progress.toFixed(0) > 0 &&
                      videoData.progress.toFixed(0) < 100 && (
                        <div className="flex mb-6" key={videoId}>
                          {
                            //  index&&
                            //  <div className="danger-info">
                            //    <span className="material-icons icon">info</span>
                            //    Do not close this tab! You will lose your new video
                            //    completely!
                            //    </div>
                            index <= 0 &&
                            <div className="danger-info">
                              {console.log('index', index)}
                              <span className="material-icons icon">info</span>
                              Do not close this tab! You will lose your new video
                              completely!
                            </div>
                            //  : <div className="danger-info">
                            //   {console.log('index', index)}
                            //   <span className="material-icons icon">info</span>
                            //   Do not close this tab! You will lose your new video
                            //   completely!
                            // </div>
                          }
                        </div>
                      )
                  )}
                {/* <div className="flex mb-6">
                    <div className="danger-info">
                      <span className="material-icons icon">info</span>
                      Do not close this tab! You will lose your new video
                      completely!
                    </div>
                  </div> */}

                {/* { user?.claim_reward != "true"  &&  <div className="flex mb-6">
                  <div className="get-paid-wrap cursor-pointer" onClick={async() =>  {
                    if (videoCount >= 5 ){
                            user?.ID && (await mixpanel.identify(user?.ID));
                            await mixpanel.track("Reward claim clicked in dashboard", {
                              Email: user?.data?.user_email,
                              "User Id": user?.data?.ID || "",
                              "User Type": user?.orders?.order_type,
                            });
                            setIsModalPopOpen(true)
                          }
                          }}>
                    <div className="get-paid-btn" style={{ display: "none" }}>
                      <div className="get-paid-inner items-center">
                        <img className="dollar-coin" src={require("../../images/dollar-coin.svg").default} alt="dollar" />
                        <span>
                          <span className="font-bold">Yatter AI loves</span> free prizes! Get paid <span className="font-bold">$5 for your first 5 videos</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              } */}
                <div class="created-videos-wrap">
                  <Link class="video-block create-new" to="/">
                    <span class="material-symbols-outlined"> add_circle </span>

                    <p class="font-semibold mt-2 f-14">Make an instant video</p>
                  </Link>
                  {/* {Object.values(videosData).map((videoPath, index) => {
                  const downloadPath = `https://app.yatter.ai/webs/video/index.php?v=${videoPath.substring(
                    videoPath.lastIndexOf("/") + 1
                  )}`;
                  const videoName = videoPath.substring(
                    videoPath.lastIndexOf("/") + 1,
                    videoPath.lastIndexOf(".mp4")
                  );
                  return (
                    <div class="video-block" key={index}>
                      <div class="video-inner">
                        <button
                          type="button"
                          class="btn-circle play"
                          onClick={() => handlePlay(index)}
                        >
                          <span class="material-icons icon"> play_arrow </span>
                        </button>
                        <video id={`video_${index}`}>
                          <source src={videoPath} type="video/mp4" />
                        </video>
                      </div>
                      <div class="video-meta">
                        <h5
                          class="name"
                          style={{ width: "180px", wordBreak: "break-all" }}
                        >
                          {videoName}
                        </h5>
                        <a
                          class="btn-download-flat"
                          href={downloadPath}
                          target="_blank"
                        >
                          <span class="material-symbols-outlined mr-1">
                            {" "}
                            download{" "}
                          </span>{" "}
                          Download
                        </a>
                      </div>
                    </div>
                  );
                })} */}
                  {/* {user &&
                    progress.toFixed(0) != 100 &&
                    progress.toFixed(0) != 0 && ( */}
                  {user &&
                    Object.entries(videoGenerations).map(
                      // ([videoId, { progress, timeRemaining }], index) => (
                      ([videoId, videoData], index) =>
                        videoData.progress.toFixed(0) > 0 &&
                        videoData.progress.toFixed(0) < 100 && (
                          <div
                            className="video-block bg-light-gradient video-generating"
                            key={videoId}
                          >
                            <div className="ai-icon-animation mx-auto mb-2">
                              <img
                                className="ai-icon-1"
                                src={
                                  require("../../images/ai-icon.svg").default
                                }
                                alt="AI Icon"
                              />
                              <img
                                className="ai-icon-2"
                                src={
                                  require("../../images/ai-icon.svg").default
                                }
                                alt="AI Icon"
                              />
                              <img
                                className="ai-icon-3"
                                src={
                                  require("../../images/ai-icon.svg").default
                                }
                                alt="AI Icon"
                              />
                              <img
                                className="ai-icon-4"
                                src={
                                  require("../../images/ai-icon.svg").default
                                }
                                alt="AI Icon"
                              />
                            </div>
                            {/* ai-icon-animation */}
                            {/* <div className="text-center"> */}
                            {/* <h2 className="font-normal">
                            {videoData.progress.toFixed(0)}%
                          </h2> */}
                            <div className="text-center">
                              <h2 className="font-normal">
                                {videoData.progress.toFixed(0)}%
                              </h2>
                              {/* <p>{timeRemaining}</p> */}
                            </div>
                            {/* videoData.progress.toFixed(0) */}
                            <p
                              style={{ textAlign: "center" }}
                              className="font-normal mt-2 f-14 center"
                            >
                              {index === 0
                                ? "Your video is being finalized.."
                                : "Video is generating..."}
                            </p>
                            {
                              updatedValue &&
                              <div
                                className="flex justify-center mt-4"
                                onClick={() => {
                                  if (cancelToken) {
                                    console.log('cancel');
                                    cancelToken.cancel(); // Cancel the ongoing API request
                                    navigate("/rerender")
                                  }

                                  // clearInterval(intervalId);
                                }}
                              >
                                <button type="button" className="btn btn-gradient">
                                  Cancel
                                </button>
                              </div>
                            }
                          </div>
                        )
                    )}
                  {/* </div> */}
                  {/* )} */}
                  {!user && (
                    <div className="video-block">
                      <div className="video-inner">
                        <video>
                          <source
                            src={require("../../images/short-1.mp4")}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  )}

                  {initialVideos?.length > 0 &&
                    user &&
                    renderVideoBlocks(initialVideos, true)}
                  {user &&
                    renderVideoBlocks(
                      firstFourVideos,
                      initialVideos?.length === 0
                    )}
                  {user &&
                    delayedRender &&
                    !loading &&
                    renderVideoBlocks(remainingVideos)}
                </div>
                {loading && (
                  <>
                    <div
                      className="loader"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "auto",
                      }}
                    ></div>
                    <p className="my-3 text-center">
                      Your other videos are loading...
                    </p>
                  </>
                )}
              </div>
              {!showAiScript && !showCheckMark && !scriptLoading && (
                <form
                  className="card-wrap create-more-video-block"
                  onSubmit={fetchAiScript}
                >
                  <h4 className="mb-6">Create a new AI video:</h4>
                  <div className="enter-topic-wrap w-full mb-6">
                    <textarea
                      className="input-control m-0"
                      rows={4}
                      placeholder="Enter a topic for your video..."
                      onChange={handlevalueChange}
                      id="insert-script"
                      name="userPrompt"
                      value={subformData?.userPrompt}
                    />
                  </div>
                  {isError && (
                    <p style={{ color: "red", marginBottom: 10 }}>
                      Maximum {wordLimit} characters allowed!
                    </p>
                  )}
                  {isTextAreaBlank && (
                    <p className="error-message -mt-1 mb-3">
                      This field cannot be blank.
                    </p>
                  )}
                  <div className="flex items-center f-13 mb-8">
                    <span className="material-symbols-outlined f-16 mr-2">
                      {" "}
                      info{" "}
                    </span>
                    Our AI works best for business or personal video. Not
                    science fiction.
                  </div>
                  <div className="justify-btn-action">
                    <button
                      type="button"
                      className="btn btn-flat underline text-left mb-6 w-full"
                      onClick={(e) => {
                        if (user) {
                          e.preventDefault();
                          setIsError(false);
                          setShowUserPromptPopup(true);
                        }
                      }}
                    >
                      Type my own script instead
                    </button>
                    <button
                      type={`${user ? "submit" : "button"}`}
                      className="btn btn-gradient btn-text-icon w-full"
                      disabled={isError}
                    >
                      <span className="material-icons f-24 mr-2">
                        auto_awesome
                      </span>
                      Generate my video
                    </button>
                  </div>
                </form>
              )}

              {showAiScript && showCheckMark && !scriptLoading && (
                <div className="card-wrap create-more-video-block">
                  <h4 className="mb-6">Create a new AI video:</h4>
                  <div className="enter-topic-wrap w-full mb-6">
                    <div className="flex items-center justify-center mb-4 f-14 font-bold bg-light-gray p-2 radius-md">
                      <span className="material-icons f-20 gradient-text mr-2">
                        task_alt
                      </span>
                      Edit anything you need:
                    </div>
                    <textarea
                      id="insert-script"
                      name="question"
                      value={subformData?.question}
                      className="input-control m-0"
                      rows={8}
                      placeholder="Enter a topic for your video..."
                      onChange={handlevalueChange}
                    />
                  </div>
                  {isError && !showUserPromptPopup && (
                    <p style={{ color: "red", marginBottom: 10 }}>
                      Maximum {wordLimit} characters allowed!
                    </p>
                  )}
                  {isAiTextAreaBlank && (
                    <p className="error-message">This field cannot be blank.</p>
                  )}
                  <div className="justify-btn-action justify-btn-action-dashboard">
                    <button
                      type="button"
                      className="btn btn-flat underline text-left mb-6 w-full"
                      onClick={(e) => {
                        if (user) {
                          e.preventDefault();
                          setIsError(false);
                          setShowUserPromptPopup(true);
                        }
                      }}
                    >
                      Type my own script instead
                    </button>
                    <button
                      type="button"
                      className="btn btn-gradient btn-text-icon w-full"
                      onClick={() => {
                        if (user) {
                          if (
                            subformData?.question.trim() === "" ||
                            subformData?.question === null
                          ) {
                            setIsAiTextAreaBlank(true);
                            return;
                          }
                          setIsImageModalPopOpen(true);
                          setShowUserPromptPopup(false);
                        }
                      }}
                    >
                      <span className="material-icons f-24 mr-2">
                        auto_awesome
                      </span>
                      Generate my video
                    </button>
                  </div>
                </div>
              )}
            </div>
            <Footer />
          </div>

          {/* {showModal && ( <ReadyVideoPopup */}
          {/* videodirectlink={videodirectlink}
           onClose={onClose}
            >
           )} */}
        </div>
      </Layout>
      <ClaimRewardPopup
        isOpenPop={isModalPopOpen}
        setIsOpenPop={setIsModalPopOpen}
        setIsSuccessModalPopOpen={setIsSuccessModalPopOpen}
        isSuccessModalPopOpen={isSuccessModalPopOpen}
      />
      <ClaimRewardSuccessPopup
        isOpenPop={isSuccessModalPopOpen}
        setIsOpenPop={setIsSuccessModalPopOpen}
      />
      <ModalView isOpenPop={scriptLoading} increaseWidth={"680px"}>
        {/* <button
          type="button"
          className="btn-close-icon"
          onClick={() => {
            setShowUserPromptPopup(false);
          }}
        >
          <span className="material-icons"> close </span>
        </button>
        <h3 className="mb-3">Type in your video script</h3> */}
        <div class="choose-img-inputs ai-generated-img-loader container-xl mb-8 !ml-auto !mr-auto">
          <div class="w-full ai-generated-img-inner">
            <div class="flex justify-center p-4 pb-8">
              <div class="spinner-box">
                <div class="configure-border-1">
                  <div class="configure-core"></div>
                </div>
                <div class="configure-border-2">
                  <div class="configure-core"></div>
                </div>
              </div>
            </div>

            <p class="font-bold mb-3 text-center">
              We are creating a cool script for your video. Give us 15 seconds
            </p>

            <div class="flex items-center w-full">
              <div class="progress-bar mr-4">
                <div
                  class="progress"
                  style={{ width: `${uploadSelfProgress}%` }}
                ></div>
              </div>
              <h4 class="">{uploadSelfProgress.toFixed(0)}%</h4>
            </div>
          </div>
        </div>
      </ModalView>
      <ModalView isOpenPop={showUserPromptPopup} increaseWidth={"580px"}>
        <button
          type="button"
          className="btn-close-icon"
          onClick={() => {
            setShowUserPromptPopup(false);
          }}
        >
          <span className="material-icons"> close </span>
        </button>
        <h3 className="mb-3">Type in your video script</h3>
        <p className="script-info">
          <span class="material-symbols-outlined mr-1 text-base">info</span>{" "}
          Make sure to type your full script as this will be said inside your
          video.
        </p>
        <div className="motivation-trial">
          <div className="insert-script lg mb-10">
            <form>
              <textarea
                id="insert-script"
                name="userPrompt"
                value={subformData?.userPrompt}
                className="input-control m-0"
                placeholder="Enter your next video idea"
                onChange={handlevalueChange}
              ></textarea>
              {isError && (
                <p style={{ color: "red", marginBottom: 10 }}>
                  Maximum {wordLimit} characters allowed!
                </p>
              )}
              {isUserTextAreaBlank && (
                <p className="error-message">This field cannot be blank.</p>
              )}
              <button
                type="button"
                className="btn btn-gradient btn-text-icon !p-2 !text-sm"
                disabled={isError}
                onClick={() => {
                  if (
                    subformData?.userPrompt.trim() === "" ||
                    subformData?.userPrompt === null
                  ) {
                    setIsUserTextAreaBlank(true);
                    return;
                  }
                  setIsImageModalPopOpen(true);
                  setShowUserPromptPopup(false);
                  setSaveUserPrompt(true);
                }}
              >
                Create my free video
                <span className="material-icons f-24 ml-2 bounce-arrow">
                  arrow_right_alt
                </span>
              </button>
            </form>
          </div>
          {/* insert-script */}
        </div>
      </ModalView>
      <ModalView isOpenPop={isImageModalPopOpen}>
        {/* <button type="button" class="btn-close-icon" onClick={closeImagePopModal}><span class="material-icons"> close </span></button> */}

        <div class="">
          <button
            type="button"
            className="btn-close-icon"
            onClick={() => {
              setIsImageModalPopOpen(false);
            }}
          >
            <span className="material-icons"> close </span>
          </button>
          <div class="mb-6">
            <h1 class="mb-6 text-center">
              Do you want a mobile or desktop video?
            </h1>

            <div class="selector-block-wrap mb-0">
              <div
                class="selector-block"
                onClick={(e) => handleformSubmit(e, 1)}
              >
                <img
                  class="mb-3"
                  src={require("../../images/mobile-size.svg").default}
                  alt="Mobile Size"
                />
                <h5>
                  Mobile <span class="font-normal">(9:16)</span>
                </h5>
              </div>

              <div
                class="selector-block"
                onClick={(e) => handleformSubmit(e, 0)}
              >
                <img
                  class="mb-3"
                  src={require("../../images/desktop-size.svg").default}
                  alt="Desktop Size"
                />
                <h5>
                  Desktop <span class="font-normal">(16:9)</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </ModalView>

      {
        days > 0 && days < 7 &&
        <ModalView isOpenPop={oneDaypopup} closePopupModal={closeOneDayPopupModal}>
          {/* <div className=""> */}

          <OneDaypopup onClick={handleOneDaypopup} onPayment={handlePayment} />
          {/* </div> */}
          {/* <div>HIO</div> */}
        </ModalView>

      }
      {user?.orders?.order_type === 'free' && days == 0 && hours == 0 && min == 0 &&
        <ModalView isOpenPop={showExpirepopup} closePopupModal={closeExpirePopup}>
          <ExpirePopup onPayment={handlePayment} />
        </ModalView>

      }
      {/* <ModalView isOpenPop={videoPopup} closePopupModal={closePopModal}>

        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
          style={{ zIndex: 2147483648, padding: "10px", }}
        >
          <div
            className="reserve-my-spot-instantly-popup custom-modal  "
            style={{ backgroundColor: "white" }}
          >
            <button
              type="button"
              class="btn-close-icon"
              onClick={() => {
                setVideoPopup(false);
              }}
              style={{ bottom: 10 }}
            >
              <span class="material-icons"> close </span>
            </button>

            <video id={`video_${VideoPath}`} controls style={{ width: "100%", padding: 10 }}>
              <source
                src={VideoPath}
                type="video/mp4"
              />
            </video>
         


          </div>
        </div>
      </ModalView > */}
      <FreeTrialPopup
        isOpenPop={isFreeTrialPopupOpen}
        // isOpenPop={true}
        setIsOpenPop={setIsFreeTrialPopupOpen}
        setUser={setUser}
      // videoUrl={videoUrl}
      // setIsFreeTrialSuccessPopupOpen={setIsFreeTrialSuccessPopupOpen}
      />

    </>
  );
}
