import axios from "axios";

export const getUserVideoCount = async (userid) => {
  console.log('userid', userid)
  try {
    const response = await axios.post(
      `https://app.yatter.ai/webs/countvideo.php`,
      userid
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};