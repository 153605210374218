import axios from "axios";

export const updateVideo = async (userId,videoId) => {
  try {
    const response = await axios.get(
      `https://app.yatter.ai/webs/updatevideouser.php?uid=${userId}&tempuid=${videoId}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};