import React, { useState, useEffect } from "react";
import "../../css/ready.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  dwvideofromWebService,
  postEmail,
  AdminpostEmail,
} from "../../services/SendImageService";
import Topbar from "../../components/Topbar/Topbar";
import { Footer } from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { toast } from "react-toastify";
import { signup } from "../../Api/Auth/signup";
import { login } from "../../Api/Auth/login";
import { useGoogleLogin } from "@react-oauth/google";
import { googleAuth } from "../../Api/googleAuth/googleAuth";
import { updateVideo } from "../../Api/updateVideo/updateVideo";
import ReadyVideoPopup from "../../components/ReadyVideoPopup/ReadyVideoPopup";
import { loginWithGoogle } from "../../Api/googleAuth/loginWithGoogle";
import { cursortowait, cursortodefault } from "../../services/chatGPTService";
import mixpanel from "mixpanel-browser";
import { getUserVideoCount } from "../../Api/getUserVideoCount/getUserVideoCount";
import ClaimRewardPopup from "../../components/ClaimRewardPopup/ClaimRewardPopup";
import ModalView from "../../components/common/Modal/ModalView";
import { sendRewardEmail } from "../../Api/sendRewardEmail/sendRewardEmail";
import ClaimRewardSuccessPopup from "../../components/ClaimRewardSuccessPopup/ClaimRewardSuccessPopup";
import { getUSerVideoCountHandle } from "../../Helpers/getUSerVideoCountHandle";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { sendFeedBack } from "../../Api/sendFeedBack/sendFeedBack";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

function hideSurvay() {
  document.getElementById("survayDisplay").style.visibility = "hidden";
  document.getElementById("submitEmailsuccess").style.display = "block";
  //  document.getElementById('survayDisplay').style.visibility = 'visible';
}
function urlSurvay() {
  window.location.replace("https://hsfbl7bh4x0.typeform.com/to/PC3AjrRB");
}

function shownothanks() {
  document.getElementById("feedback-wrapper").style.display = "none";
  document.getElementById("feedback-wrapper1nothanks").style.display = "block";
  document.getElementById("feedback-wrapper2optin").style.display = "none";
}

function gobackbtnthanks() {
  document.getElementById("feedback-wrapper").style.display = "block";
  document.getElementById("feedback-wrapper1nothanks").style.display = "none";
  document.getElementById("feedback-wrapper2optin").style.display = "none";
}

function showoptin(email) {
  console.log(email);
  document.getElementById("feedback-wrapper").style.display = "none";
  document.getElementById("feedback-wrapper1nothanks").style.display = "none";
  document.getElementById("feedback-wrapper2optin").style.display = "block";
  let msg = "Opt In: YES. <br><br>Comment: NO <br><br>Email Is " + email + " ";
  sendoptinfoemailclick(msg, "Click on OptIn Button");
}

async function sendoptinfoemailclick(msg, formtype) {
  const emaildata3 = {
    msg: msg,
    formtype: formtype,
  };
  const resp3 = await AdminpostEmail(emaildata3);
  if (resp3) {
    console.log(resp3);
  } else {
    console.log("error", resp3);
  }
}

function getFilenameFromURL(fileUrl) {
  const lastSlashIndex = fileUrl.lastIndexOf("/");
  return fileUrl.substring(lastSlashIndex + 1); // Extracts the substring after the last slash
}

function Ready() {
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  //  phone: "",
  });
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    phone: "",
    signuplast: "yes",
    role:"customer",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState([]);
  console.log("Google User::", profile);
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const [thankyoutext, setthankyoutext] = useState("");
  const [optintext, setoptintext] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [formvalidationMessage, setformValidationMessage] = useState(true);
  const [formoptinvalidationMessage, setformoptinValidationMessage] =
    useState(true);
  const [thankyousent, setthankyousent] = useState(false);
  const [optinsent, setoptinsent] = useState(false);
  const location = useLocation();
  const [videolink, setvideolink] = useState();
  const [videodirectlink, setvideodirectlink] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isModalPopOpen, setIsModalPopOpen] = useState(false);
  const [isSuccessModalPopOpen, setIsSuccessModalPopOpen] = useState(false);
  const [ratingStar, setRatingStar] = useState(null);
  const [ratingMessage, setRatingMessage] = useState('');
  const [ratingErrorMessage, setRatingErrorMessage] = useState('');
  const [hover, setHover] = useState(null);
  const [totalStars, setTotalStars] = useState(5);
  const [feedBackEmailSent, setFeedBackEmailSent] = useState(false);
  const videoCount = localStorage.getItem('video_count')
  const MobileVideo = JSON.parse(localStorage.getItem("mobileImg"));
  console.log("MobileVideo", MobileVideo)
  const handleOptionChange = async (event) => {
    setSelectedOption(event.target.value);
    setFormErrors({
      ...formErrors,
      selectedOption: "",
    });
    user?.ID && (await mixpanel.identify(user?.ID));
    await mixpanel.track("Referred", {
      Referred: selectedOption,
    });
  };

  const handleRatingChange = (e) => {
    e.preventDefault();
    console.log('star value', e.target.value)
    setRatingStar(e.target.value);
    setRatingErrorMessage('')
  };
  const handleMessageChange = (e) => {
    setRatingMessage(e.target.value);
  };
  const handleSubmitthankyou = (event) => {
    // localStorage.setItem('Email', email)
    setthankyoutext(event.target.value);
    //setSubmit(true)
  };

  const handleSubmitoptin = (event) => {
    // localStorage.setItem('Email', email)
    setoptintext(event.target.value);
    //setSubmit(true)
  };

  const sendoptinformemail = async (msg, formtype) => {
    console.log(optintext);
    if (!optintext.trim()) {
      setformoptinValidationMessage(false);
      return;
    } else {
      setformoptinValidationMessage(true);
      const emaildata2 = {
        msg: optintext,
        formtype: formtype,
      };
      const resp2 = await AdminpostEmail(emaildata2);
      if (resp2) {
        console.log(resp2);
        setoptinsent(true);
      } else {
        console.log("error", resp2);
      }
    }
  };

  const sendformemail = async (msg, formtype) => {
    console.log(thankyoutext);
    if (!thankyoutext.trim()) {
      setformValidationMessage(false);
      return;
    } else {
      setformValidationMessage(true);
      const emaildata1 = {
        msg: thankyoutext,
        formtype: formtype,
      };
      const resp1 = await AdminpostEmail(emaildata1);
      if (resp1) {
        setthankyousent(true);
        console.log("sentin");
        console.log(resp1);
      } else {
        console.log("error", resp1);
      }
    }
  };

  const submitEmail = async (getEmail) => {
    console.log(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidationMessage("Please enter a valid email address.");
      return;
    } else {
      setValidationMessage("");
      document.getElementById("submitEmailsuccess").style.display = "block";
      document.getElementById("survayDisplay").style.visibility = "visible";
      console.log("in ELSE");
      const emaildata = {
        email: email,
        url: videolink,
      };
      console.log(emaildata);

      // console.log(localStorage.getItem('Email'));
      const resp = await postEmail(emaildata);
      if (resp) {
        console.log(resp);
        localStorage.removeItem("Email");
        setSubmit(true);
      } else {
        console.log("error", resp);
        localStorage.removeItem("Email");
      }
    } /*end of else*/
  };
  const sendEmail = async (emaildata) => {
    console.log("emaildata", emaildata);
    const resp = await postEmail(emaildata);
    console.log("resp", resp);
    if (resp?.status === 200) {
      // localStorage.removeItem("Email");
      toast.success("Video sent successfully to your email");
      setSubmit(true);
    } else {
      console.log("error", resp);
      toast.error("error", resp);
      // localStorage.removeItem("Email");
    }
  };
  useEffect(() => {
    // console.log(localStorage.getItem('Email'));
   // document.getElementById("signuptopid").style.display="none";

   /*let signupexist = document.getElementById('signuptopid');

      if (signupexist) {
          signupexist.style.display="none";
          // Perform operations with 'element' here
      } */
  }, []);

  useEffect(() => {
    const StorageData = JSON.parse(localStorage.getItem("alldata"));
    console.log('AbMobilede',StorageData);
    let MobileOrDesktop = StorageData && StorageData['MobileVideo'];
    console.log('Mobilede',MobileOrDesktop);
    if(MobileOrDesktop == 0)
    {
      document.getElementById('viewvideoin').classList.add('videodesktop');
    }
     else {
        document.getElementById('viewvideoin').classList.remove('videodesktop');
     }
    console.log(location);
    const data = location?.state?.forreadyvideo; // Accessing data passed from Screen 1
    console.log("in ready",data);
    let rnum= Math.random() * 100;
    let updatedlink=data+'?'+rnum;
    console.log(updatedlink);
    data && setvideodirectlink(updatedlink);
    const filename = data && getFilenameFromURL(data);
    let fulllink =
      "https://app.yatter.ai/webs/video/index.php?v=" + filename;
    setvideolink(fulllink);

    const emaildata = {
      email: user?.data?.user_email,
      url: fulllink,
    };
    // console.log(emaildata);
    // console.log("user coming", user);
    // const showToast = location?.state?.showMessage;
    // if (user && data && showToast) {
    //   sendEmail(emaildata);
    // }
  }, []);

  /* useEffect(() => {
     submitEmail()
   }, [videolink])
 */
  const handledwvideo = async (formData) => {
    // console.log(formData);
    // setLoading(true); // Start loading

    try {
      console.log(formData);
      const response = await dwvideofromWebService(formData);
      if (response) {
        // console.log(response);
        //         navigate('/GetImages', { state: { response } });
        //         return setImage(response);
      } else {
        return console.log("error");
      }
    } catch (error) {
      console.error("Error handling form submission:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  // Event handler for form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (formData.username.trim() === "") {
      errors.username = "Email is required.";
    }

   /* if (formData.phone.trim() === "") {
      errors.phone = "Phone number is required.";
    }
    // const regex = /^\d{10}$/;
    if (formData.phone.trim().length < 10) {
      errors.phone = "Invalid mobile number.";
    }
*/
    if (formData.password.trim() === "") {
      errors.password = "Password is required.";
    }
    if (selectedOption.trim() === "") {
      errors.selectedOption = "Please select where you heard about us";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      // Call the signup function with the form data
      cursortowait();
      const response = await signup(formData);
      // Handle successful signup (you can redirect to login page or show a success message)
      console.log(
        "Signup successful:",
        response,
        "response?.data",
        response?.data
      );
      if (response?.data) {
        console.log("response?.data?.ID", response?.data?.ID);
        const userId = response?.data?.ID;
        const videoId = localStorage.getItem("video_id");
        //  setShowModal(true)
        const data = await updateVideo(userId, videoId);
        if (data == "successfully updated") {
         // navigate("/confirmation");
         /*let detailsu=response.data;
          navigate("/confirmation", { state: { detailsu } });*/
          userId && await getUSerVideoCountHandle({ userid: userId })
          const userResponse = await login(formData);
          console.log("userResponse", userResponse);
          if (userResponse?.data?.data) {
            await getUSerVideoCountHandle({ userid: userResponse?.data?.ID })
            const userData = { ...userResponse.data };
            delete userData.data.user_pass;
            delete userData.data.user_activation_key;
            console.log("response", userData);
            localStorage.setItem("user", JSON.stringify(userData));
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Website Form",
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            localStorage.removeItem("video_id");
            localStorage.removeItem("video_url");
            // navigate("/dashboard");
            console.log("Login success");
            window.location.reload();
          }

        } else {
          console.log(data);
        }
        //navigate("/login");
      } else if (response?.message === "User already exists.") {
        // toast.error("User already exists.");
        const userResponse = await login(formData);
        console.log("userResponse", userResponse);
        if (userResponse?.data?.data) {
          const userData = { ...userResponse.data };
          delete userData.data.user_pass;
          delete userData.data.user_activation_key;
          console.log("response", userData);
          const userId = userData?.ID;
          const videoId = localStorage.getItem("video_id");
          const data = await updateVideo(userId, videoId);
          if (data == "successfully updated") {
            userId && await getUSerVideoCountHandle({ userid: userId })
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Website Form",
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            localStorage.setItem("user", JSON.stringify(userData));
            // navigate("/dashboard");
            // toast.success("Login success");
            localStorage.removeItem("video_id");
            localStorage.removeItem("video_url");
            window.location.reload();
          } else {
            console.log({ error: data });
          }
        }
      }
    } catch (error) {
      toast.error(error);
    }
    cursortodefault();
  };
  const googleSignup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      cursortowait();
      console.log({ codeResponse });
      // if (selectedOption.trim() === "") {
      //   return toast.error("Please select where you heard about us");
      // }
      const data = await googleAuth(codeResponse);
      if (data && data?.email) {
        console.log({ profile: data });
        const body = {
          email: data?.email,
          name: data?.name,
          signuplast: "yes",
        };
        const response = await loginWithGoogle(body);
        // Handle successful signup (you can redirect to login page or show a success message)
        console.log(
          "Signup successful:",
          response,
          "response?.data",
          response?.data
        );
        if (response?.data) {
          console.log("response?.data?.ID", response?.data?.ID);
          const userId = response?.data?.ID;
          const videoId = localStorage.getItem("video_id");
          //  setShowModal(true)
          const data = await updateVideo(userId, videoId);
          if (data === "successfully updated") {
            userId && await getUSerVideoCountHandle({ userid: userId })
            console.log("userResponse", response);
            const userData = { ...response?.data };
            delete userData.data.user_pass;
            delete userData.data.user_activation_key;
            console.log("response", userData);
            localStorage.setItem("user", JSON.stringify(userData));
            await mixpanel.identify(userData?.data?.ID);
            await mixpanel.people.set({
              $email: userData?.data?.user_email,
              $name: userData?.data?.user_email,
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            await mixpanel.track("Sign Up to get your video", {
              Email: userData?.data?.user_email,
              "Sign Up Platform": "Google",
              "User Id": userData?.data?.ID,
              Referred: selectedOption,
            });
            // navigate("/dashboard");
            console.log("Login success");
            localStorage.removeItem('video_id')
            localStorage.removeItem('video_url')
            // toast.success("Login success");
            window.location.reload();
          } else {
            console.log(data);
          }
        }
      }
      cursortodefault();
    },
    onError: (error) => toast.error(error),
  });

  // useEffect(
  //   () => {
  //      const googleUserAuth = async()=>{
  //       try {
  //           const data=await googleAuth(googleUser);
  //           setProfile(data)
  //     } catch (error) {
  //       toast.error(error);
  //     }
  //   }
  //   googleUserAuth()

  // },[ user ]);
  // Event handler to update form data when input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    cursortowait()
    if (!ratingStar) {
      setRatingErrorMessage('Please provide a rating.');
      return;
    }
    const payload = {
      rating: ratingStar,
      message: ratingMessage,
      useremail: user?.data?.user_email
    };
    console.log('API Payload:', payload);
    try {
      const data = await sendFeedBack(payload)
      if (data && data?.email === "Email sent successfully"){
          setRatingStar(null);
          setRatingMessage('');
          setRatingErrorMessage('');
          toast.success('Rating submitted successfully!');
          setFeedBackEmailSent(true)
      } else {
        console.log({feedackError:data})
        toast.error('Error occured during rating')
      }
    } catch (error) {
      console.log({feedackError:error})
      toast.error('Error occured during rating')
    }
    cursortodefault()
  };
  /* const data = {
     key: '850Q',
     // Other data to send
   };
 
 */
  //Cookies.remove('cookieName');

  //  const localvalue = localStorage.getItem('loglevel');
  //data[]=Array('localval')
  // const combineara=[...data,localvalue]
  //sendDataToWebService (combineara);
  return (
    <>
      {/* <Topbar/> */}

      <Layout HeaderName="Making your professional video">
        <div
          className={
            user ? "fullscreen-page hide-scrollbar" : "fullscreen-page_logout"
          }
          style={{ bottom: "auto" }}
        >
          <div
            className={
              user
                ? "short-creator-block customStyle"
                : "customStyleLogoutGetScript customStyleLogout short-creator-block"
            }
            style={{ width: "100%" }}
          >
            <div className="readyspecific">
              {/* <div class="short-ready-container">
        <div class="short-ready-wrap">
  */}
              {/* {!submit && ( */}
              <>
                {/* {!user && <>
                  <h3 class="mb-6 text-center">Your video is ready!</h3>
                  <h3 class="mb-6 text-center emailinstant">
                    Enter your email to receive it instantly
                  </h3>
                  <div class="w-full mb-6">
                    <div class="flex">
                      <input
                        id="insert-script"
                        class="input-control mb-0"
                        placeholder="Enter email here"
                        onChange={handleSubmit}
                      />

                      <button
                        type="button"
                        onClick={(e) => submitEmail(e.target.value)}
                        class="btn btn-gradient ml-2"
                        style={{ height: 45 }}
                      >
                        Continue
                      </button>
                    </div>
                    <div
                      // class="subscription-waiting hidden"
                      class="subscription-waiting"
                      id="submitEmailsuccess"
                      style={{
                        position: "absolute",
                        top: 71,
                        background: "white",
                        textAlign: "center",
                        width: "89%",
                      }}
                    >
                      <img
                        class="check-icon"
                        src="https://yatter.ai/wp-content/uploads/2024/02/check-gradient.svg"
                        alt="Check"
                        style={{ margin: "auto" }}
                      />
                      <h2>Great!</h2>
                      <p>Your video has been sent to your email</p>
                    </div>
                  </div>
                  <div className="validatemsgready">{validationMessage}</div>
                </>
                } */}
                {!user && (
                  <>
                    <div
                      class={
                        user
                          ? "premium-wrapper video-ready-block"
                          : "video-signup-wrapper video-signup-block"
                      }
                    >
                      <div class="video-view-wrap">
                        <div class="text-center">
                          <h2 class="mb-8">
                            <img
                              class="mx-auto mb-2"
                              src="images/check-gradient.svg"
                              alt="Check"
                            />
                            {/* <span class="text-red">Your video is ready!</span> */}
                            <span class="text-red">Bamm!</span> You’ve got a new AI video.
                          {/* <p className="ready-para-block">Download it and customize it more on your favorite editing tool.</p> */}
                          </h2>

                          <div className="video-view video-play-btn" id="viewvideoin" style={{ width: MobileVideo == 0 ? '100%' : '220px' }}>
                            {videodirectlink && (
                              <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button
                                  type="button"
                                  className="btn-circle play"
                                >
                                  <img
                                    className="mx-auto mb-2"
                                    src="images/video-gradient.svg"
                                    alt="Check"
                                  />
                                </button>
                                <h5>Completed</h5>
                              </div>
                            )}

                            {/*  key="../../images/short-1.mp4"  require('../../images/short-1.mp4')*/}
                            <video
                              key={videodirectlink}
                              style={{ opacity: "0.2" }}
                              controls={videodirectlink ? false : true}
                            >
                              {/* <source src={videodirectlink} type="video/mp4" /> */}
                              <source src={videodirectlink} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      </div>

                      <div
                        className="authentication-card text-center"
                        style={{ margin: 0 }}
                      >
                        <div className="head">
                          {/* <h2>Who can <span className="text-red">see and download</span> this New AI Video? Enter your info to access it instantly:</h2> */}
                          <h2>
                          Create your FREE account to see your new video and make <span className="text-red">unlimited more</span>
                          </h2>
                        </div>

                        <div className="body">
                          <div>
                            <button
                              type="button"
                              className="btn btn-black-ouline btn-text-icon w-full rounded-btn"
                              onClick={() => googleSignup()}
                              // disabled
                            >
                              <img
                                className="mr-2"
                                src={require("../../images/google.svg").default}
                                alt="Google"
                              />{" "}
                              Sign up with Google
                            </button>
                          </div>

                          <div className="or-divider">
                            <span>OR</span>
                          </div>
                          <form action="#">
                            <div className="input-group">
                              <input
                                type="email"
                                name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                                className="input-control"
                                placeholder="Your email"
                                required
                              />
                              {formErrors.username && (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: "-1.25rem",
                                    textAlign: "left",
                                    color: "red",
                                  }}
                                >
                                  {formErrors.username}
                                </p>
                              )}
                            </div>
                            {/* <div className="input-group">
                          <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className="input-control"
                            placeholder="Enter your phone number"
                            required
                          />
                          {formErrors.phone && (
                            <p style={{ color: "red", marginTop: '-1.25rem', textAlign: 'left', color: 'red' }}>{formErrors.phone}</p>
                          )}
                        </div> */}
                            <div className="input-group">
                              <input
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className="input-control"
                                placeholder="Your password"
                                required
                              />
                              {formErrors.password && (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: "-1.25rem",
                                    textAlign: "left",
                                    color: "red",
                                  }}
                                >
                                  {formErrors.password}
                                </p>
                              )}
                              <button
                                type="button"
                                className={`btn-icon ${formErrors.password ? 'show-custom-pwd' : 'show-pwd'}`}
                                onClick={togglePasswordVisibility}
                              >
                                <span className="material-icons text-gray f-20">
                                  {" "}
                                  visibility{" "}
                                </span>
                              </button>
                            </div>

                            <div className="input-group">
                              {/* <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className="input-control"
                                placeholder="Your Mobile"                          
                              /> */}

                              {/*
                              <PhoneInput
                                country={'us'}
                                inputProps={{
                                  name: 'phone',
                                  // required: true,
                                  autoFocus: true
                                }}
                                value={formData.phone}
                                onChange={(phone) => {
                                    console.log({phone})
                                    setFormData({...formData, phone})
                                  }}
                              />
                              {formErrors.phone && (
                                <p
                                  style={{
                                    color: "red",
                                    // marginTop: "-1.25rem",
                                    textAlign: "left",
                                    color: "red",
                                  }}
                                >
                                  {formErrors.phone}
                                </p>
                               )}
                              */}
                            </div>

                            <div className="input-group text-left">
                              <label className="f-14 font-bold mb-2 inline-block">
                                Where did you hear about us?
                              </label>
                              <div className="">
                                <div className="flex items-center">
                                  <input
                                    id="google-ads"
                                    type="radio"
                                    value="google-ads"
                                    name="source"
                                    className="w-4 h-4 bg-gradient rounded"
                                    checked={selectedOption === "google-ads"}
                                    onChange={handleOptionChange}
                                  />
                                  <label htmlFor="google-ads" className="ms-2">
                                    Inside Google Ads
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="friend-colleague"
                                    type="radio"
                                    value="friend-colleague"
                                    name="source"
                                    className="w-4 h-4 bg-gradient rounded"
                                    checked={
                                      selectedOption === "friend-colleague"
                                    }
                                    onChange={handleOptionChange}
                                  />
                                  <label
                                    htmlFor="friend-colleague"
                                    className="ms-2"
                                  >
                                    From a friend or colleague
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="youtube-shorts"
                                    type="radio"
                                    value="youtube-shorts"
                                    name="source"
                                    className="w-4 h-4 bg-gradient rounded"
                                    checked={
                                      selectedOption === "youtube-shorts"
                                    }
                                    onChange={handleOptionChange}
                                  />
                                  <label
                                    htmlFor="youtube-shorts"
                                    className="ms-2"
                                  >
                                    Inside YouTube shorts
                                  </label>
                                </div>
                              </div>
                            </div>
                            {formErrors?.selectedOption && (
                              <p
                                style={{
                                  color: "red",
                                  marginTop: "-1.25rem",
                                  textAlign: "left",
                                  color: "red",
                                }}
                              >
                                {formErrors.selectedOption}
                              </p>
                            )}
                            <div className="text-center mb-5">
                              <p className="f-14 font-medium">
                                By registering you agree to our{" "}
                                <a href="https://yatter.ai/privacy-policy" target="_blank"
                                  
                                  className="font-bold text-red"
                                >
                                  privacy policy
                                </a>
                                .
                              </p>
                            </div>
                            <div className="input-group">
                              <input
                                type="hidden"
                                name="signuplast"
                                value="yes"
                                className="input-control"
                              />
                            </div>
                            <div className="input-group mb-0">
                              <button
                                type="button"
                                className="btn btn-gradient"
                                onClick={handleFormSubmit}
                                // onClick={() => setShowModal(true)}
                              >
                                Access my New AI Video
                              </button>
                            </div>
                          </form>
                        </div>

                        <div className="perks-wrap perks-content-wrapper">
                          <div className="perk-block">
                            <span className="material-icons icon gradient-text f-24 mr-2">
                              {" "}
                              star{" "}
                            </span>
                            <span>
                              <span className="font-bold">4.9 stars</span> based
                              on 1,309 reviews
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {user && !email && (
                  <div
                    class="survey-premium-card short-creator-block block toppopupready"
                    id="survayDisplay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: "50%",
                      padding: "32px",
                      backgroundColor: "white",
                      display: "flex",
                      // justifyContent: "center",
                    }}
                  >
                    <div class="premium-wrapper video-ready-block custom-premium-wrapper">
                      <div class="video-view-wrap custom-video-view-wrap">
                        <div class="text-center">
                          <img
                            class="mx-auto mb-2"
                            src="images/check-gradient.svg"
                            alt="Check"
                          />
                          <h2 class="mb-8">
                            <span class="text-red">Bamm!</span> You’ve got a new AI video.
                          <p className="ready-para-block">Download it and customize it more on your favorite editing tool.</p>
                          </h2>
                          <div class="video-view" id="viewvideoin" style={{ width: MobileVideo == 0 ? '100%' : '220px' }}>
                            <video key={videodirectlink} controls style={{ width: MobileVideo == 0 ? '100%' : '' }}>
                              <source src={videodirectlink} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        
                        
                        <div class="flex justify-center items-center">
                        
                          <a
                            href={videolink}
                            class="btn btn-gray dnbtnready"
                            >
                            Download
                          </a>

                          <span class="text-gray mx-4">OR</span>
                          <button type="button" class="btn rerendera btn-gradient  btn-text-icon" 
                          onClick={async () => {
                            user?.ID && (await mixpanel.identify(user?.ID));
                            await mixpanel.track("Re-Render", {
                              Email: user?.data.user_email,
                              "User Id": user?.data.ID || "",
                              "User Type": user?.orders.order_type,
                            });
                            navigate("/dashboard")
                            }}> 
                            <span className="material-icons f-20 mr-2"> auto_awesome </span>Re-edit</button>

                          </div>




                          {/* <button type="button" class="btn btn-gray">Download</button>*/}
                        </div>
                      </div>
                      {/*---------------- FeedBack block -------- */}
                   {/* {!feedBackEmailSent &&   <div className="right-block text-center bg-light-gray"> */}
                   {!feedBackEmailSent &&   <div className="right-block text-center">
                        <div className="reward-card text-center mb-6">
                          <div className="mb-4">
                            <img className="mx-auto" src={require('../../images/rate-video.svg').default} alt="Dollar icon" />
                          </div>

                          <h1 className="mb-4">Important: Rate this video yourself:</h1>
                          <p className="mb-4">Are you a new life-long fan or you think our tool sucks? Simple as that. Let us know!</p>
                          
                          <form>
                            <div className="break-words">
                            {[...Array(totalStars)].map((star, index) => {
                              console.log(star, index)
                              const currentRating = index + 1;

                              return (
                                  <label key={index}>
                                      <input
                                          key={star}
                                          type="radio"
                                          name={`rating`}
                                          value={ratingStar}
                                          checked={currentRating === ratingStar}
                                          onChange={() => {
                                            console.log('currentRating', currentRating)
                                            // const newRating = currentRating === ratingStar ? null : currentRating;
                                            setRatingStar(currentRating);
                                            setRatingErrorMessage('');
                                          }}
                                          style={{ display: 'none' }}
                                      />
                                      <span
                                          className="newStar"
                                          style={{
                                              color:
                                              currentRating <= (hover || ratingStar) ? "#ffc107" : "#e4e5e9",
                                          }}
                                          onMouseEnter={() => setHover(currentRating)}
                                          onMouseLeave={() => setHover(null)}
                                      >
                                          &#9733;
                                      </span>
                                  </label>
                              );
                          })}


                              {/* <input type="radio" id="star1" name="rate1" value="5" onChange={handleRatingChange} />
                              <label htmlFor="star1" title="text"></label>
                              <input type="radio" id="star2" name="rate2" value="4" onChange={handleRatingChange} />
                              <label htmlFor="star2" title="text"></label>
                              <input type="radio" id="star3" name="rate3" value="3" onChange={handleRatingChange} />
                              <label htmlFor="star3" title="text"></label>
                              <input type="radio" id="star4" name="rate4" value="2" onChange={handleRatingChange} />
                              <label htmlFor="star4" title="text"></label>
                              <input type="radio" id="star5" name="rate5" value="1" onChange={handleRatingChange} /> */}
                              {/* <label htmlFor="star5" title="text"></label> */}
                            </div>

                            <textarea className="input-control" rows="4" placeholder="Write your own thought here..." value={ratingMessage} onChange={handleMessageChange}></textarea>
                            {ratingErrorMessage && (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: "-1.25rem",
                                    textAlign: "left",
                                    color: "red",
                                  }}
                                >
                                  {ratingErrorMessage}
                                </p>
                            )}
                          </form>

                          <button type="button" className="btn btn-gradient" onClick={handleRatingSubmit}>Submit</button>
                        </div>

                        <h2 className="mb-6">Once downloaded, you can export to:</h2>

                        <div className="inline-logos">
                          <img className="mr-4 border-light" src={require('../../images/premiere-logo.svg').default} alt="Premiere" />
                          <img className="mr-4 border-light" src={require('../../images/inshot-logo.svg').default} alt="inshot" />
                          <img className="mr-4 border-light" src={require('../../images/capcut-logo.svg').default} alt="capcut" />
                          <span className="font-medium text-gray">+15 Others</span>
                        </div>
                      </div>
                    }

                      {/* {feedBackEmailSent && <div className="right-block text-center bg-light-gray"> */}
                      {feedBackEmailSent && <div className="right-block text-center">
                        <div className="reward-card text-center mb-6">
                          <div className="mb-4">
                            <img className="mx-auto" src={require('../../images/rate-video.svg').default} alt="Dollar icon" />
                          </div>
                          <h1 className="mb-4 uppercase">You're the best!</h1>
                          <p className="mb-5">Whatever feedback you just gave us will get read by the team ASAP! Create an instant AI video below:</p>
                          <button type="button" className="btn btn-gradient" onClick={() => navigate('/')}>Create here</button>
                        </div>
                        <h2 className="mb-6">Once downloaded, you can export to:</h2>
                        <div className="inline-logos">
                          <img className="mr-4 border-light" src={require('../../images/premiere-logo.svg').default} alt="Premiere" />
                          <img className="mr-4 border-light" src={require('../../images/inshot-logo.svg').default} alt="inshot" />
                          <img className="mr-4 border-light" src={require('../../images/capcut-logo.svg').default} alt="capcut" />
                          <span className="font-medium text-gray">+15 Others</span>
                        </div>
                      </div> }


                      {/*---------------- FeedBack block -------- */}
                      {/* video-view-wrap getb 5 dollar block */}
                    {
                      // videoCount < 5 &&  <div className="right-block text-center bg-light-gray">
                      //   <div className="reward-card text-center mb-6">
                      //     <div className="mb-4">
                      //       <img className="mx-auto" src={require("../../images/dollar-coin.svg").default} alt="Dollar icon" />
                      //     </div>

                      //     <h1 className="font-medium mb-4">Get <span className="text-red font-bold">$5</span> for your First <span className="font-bold">5 Videos</span></h1>
                      //     <p className="mb-4">Once you complete <span className="font-bold">5 Yatter videos</span> - you can alert us and receive <span className="font-bold">$5</span> instantly!</p>
                          
                      //     <span className="tag-theme f-16 font-regular w-full mb-4 p-3"><span className="font-bold">You're almost there!</span> You've created <span className="text-red font-bold">{videoCount || '0'}/5</span> videos.</span>

                      //     <button type="button" className="btn btn-gradient" onClick={()=> navigate('/')}>Create more videos</button>
                      //     {/* <button type="button" className="btn btn-gradient" onClick={()=> setIsModalPopOpen(true)}>Create more videos</button> */}
                      //   </div>

                      //   <h2 className="mb-6">Once downloaded, you can export to:</h2>

                      //   <div className="inline-logos">
                      //     <img className="mr-4 border-light" src={require("../../images/premiere-logo.svg").default} alt="Premiere" />
                      //     <img className="mr-4 border-light" src={require("../../images/inshot-logo.svg").default} alt="inshot" />
                      //     <img className="mr-4 border-light" src={require("../../images/capcut-logo.svg").default} alt="capcut" />
                      //     <span className="font-medium text-gray">+15 Others</span>
                      //   </div>
                      // </div>
                    }
                      {/* claim reward block */}
                      {
                      // videoCount >= 5 && user?.claim_reward != "true"  && <div className="right-block text-center bg-light-gray">
                      //   <div className="reward-card text-center mb-6">
                      //     <div className="mb-4">
                      //       <img className="mx-auto" src={require("../../images/confetti-icon.svg").default} alt="confetti icon" />
                      //     </div>

                      //     <h1 className="font-bold text-red mb-4" style={{overflowWrap: 'break-word'}}>Congratulations!</h1>
                      //     <p className="font-bold mb-2">You're now a Yatter Pro!</p>
                      //     <p className="mb-4">You've officially created 5 or more Yatter videos.<br />Claim your reward:</p>
                          
                      //     <button type="button" className="btn btn-gradient" 
                      //     onClick={async() =>  {
                      //       user?.ID && (await mixpanel.identify(user?.ID));
                      //       await mixpanel.track("Reward Claim on ready page", {
                      //         Email: user?.data.user_email,
                      //         "User Id": user?.data.ID || "",
                      //         "User Type": user?.orders.order_type,
                      //       });
                      //       setIsModalPopOpen(true)
                      //     }}>Claim it here</button>
                      //   </div>

                      //   <h2 className="mb-6">Once downloaded, you can export to:</h2>

                      //   <div className="inline-logos">
                      //     <img className="mr-4 border-light" src={require("../../images/premiere-logo.svg").default} alt="Premiere" />
                      //     <img className="mr-4 border-light" src={require("../../images/inshot-logo.svg").default} alt="inshot" />
                      //     <img className="mr-4 border-light" src={require("../../images/capcut-logo.svg").default} alt="capcut" />
                      //     <span className="font-medium text-gray">+15 Others</span>
                      //   </div>
                      // </div>
                      }
                      {
                      // videoCount >= 5 && user?.claim_reward == 'true'  &&  <div className="right-block text-center">
                      //   <div className="bg-light-gray p-4 rounded-2xl">
                      //     <h2 className="mb-6">Once downloaded, you can export to:</h2>

                      //     <div className="inline-logos">
                      //       <img className="mr-4 border-light" src={require("../../images/premiere-logo.svg").default} alt="Premiere" />
                      //       <img className="mr-4 border-light" src={require("../../images/inshot-logo.svg").default} alt="inshot" />
                      //       <img className="mr-4 border-light" src={require("../../images/capcut-logo.svg").default} alt="capcut" />
                      //       <span className="font-medium text-gray">+15 Others</span>
                      //     </div>
                      //   </div>
                      // </div>
                      }
                      <div
                        class="feedback-wrapper text-center displaynone"
                        id="feedback-wrapper1nothanks"
                      >
                        <h1 class="mb-4">
                          Was Our Tool Really{" "}
                          <span class="text-red">Disappointing?</span>
                        </h1>

                        <h3 class="mb-8">
                          If we didn't meet your standards - that's on us.{" "}
                          <span class="text-red">
                            How could we be your new favorite tool?
                          </span>
                        </h3>

                        <div class="text-center my-4">
                          <textarea
                            class="input-control white"
                            placeholder="Insert my thoughts here"
                            onChange={handleSubmitthankyou}
                          ></textarea>

                          {formvalidationMessage ? (
                            ""
                          ) : (
                            <div
                              class="short-limit-info invideoscreen mt-5"
                              style={{ marginBottom: "8px" }}
                            >
                              <span class="material-icons">info</span>Please
                              enter your comments.
                            </div>
                          )}
                          {thankyousent ? (
                            <div
                              class="short-limit-info invideoscreen mt-5 yellowclass"
                              style={{ marginBottom: "8px" }}
                            >
                              <span class="material-icons">info</span>Thanks!
                              for your message.
                            </div>
                          ) : (
                            ""
                          )}
                          <button
                            type="button"
                            class="btn btn-gradient btn-xl mx-auto w-full"
                            onClick={(e) =>
                              sendformemail(e.target.value, "Thankyou form")
                            }
                          >
                            Submit
                          </button>
                        </div>

                        <div class="text-center">
                          <button
                            class="f-20 text-gray underline font-semibold"
                            onClick={gobackbtnthanks}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>

                      {/* feedback wrapper 1 no thanks - ends */}

                      {/* feedback wrapper 2 optin - starts */}
                      <div
                        class="feedback-wrapper text-center displaynone"
                        id="feedback-wrapper2optin"
                      >
                        <h1 class="mb-4">
                          Get Ready for <span class="text-red">May 1st!</span>
                        </h1>

                        <h3 class="mb-8">
                          On the release date, you'll have direct access to it.{" "}
                          <span class="text-red">
                            What would you like to see on Premium? Insert below:
                          </span>
                        </h3>

                        <div class="text-center my-4">
                          <textarea
                            class="input-control white"
                            placeholder="Insert my thoughts here"
                            onChange={handleSubmitoptin}
                          ></textarea>
                          {formoptinvalidationMessage ? (
                            ""
                          ) : (
                            <div
                              class="short-limit-info invideoscreen mt-5"
                              style={{ marginBottom: "8px" }}
                            >
                              <span class="material-icons">info</span>Please
                              enter your comments.
                            </div>
                          )}

                          {optinsent ? (
                            <div
                              class="short-limit-info invideoscreen mt-5 yellowclass"
                              style={{ marginBottom: "8px" }}
                            >
                              <span class="material-icons">info</span>Thanks!
                              for your message.
                            </div>
                          ) : (
                            ""
                          )}

                          <button
                            type="button"
                            class="btn btn-gradient btn-xl mx-auto w-full"
                            onClick={(e) =>
                              sendoptinformemail(
                                e.target.value,
                                "Successfull optin form"
                              )
                            }
                          >
                            Submit
                          </button>
                        </div>

                        <div class="text-center">
                          <button
                            class="f-20 text-gray underline font-semibold"
                            onClick={gobackbtnthanks}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                      {/* feedback wrapper 2 optin - ends */}
                    </div>

                    {/* <div class="survey-premium-block">
              <div class="section-title md w-full text-center">
                <h1 class="title-text"><span class="text-red italic">Wait: Before</span> you close this tab...</h1>
                <h5 class="sub-title mt-4">We're releasing 1 free month of Yatter Premium to 99 early users. Just fill out our 2-min survey to register:</h5>
              </div>
      
              <div class="premium-wrapper mb-6">
                <div class="premium-features">
                  <div class="flex items-center flex-wrap f-18 mb-8"><span class="mr-2">Are You Interested In:</span><span class="yatter-premium">
                    <img class="premium-icon" src="images/yatter-premium.svg" alt="Yatter Premium" /> Yatter Premium</span></div>
      
                  <ul class="features-list">
                    <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Get <span class="font-bold">50+ of these instant AI</span> videos per month!</span></li>
                    <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Make viral videos that have animations, captions, and <span class="font-bold">custom music.</span></span></li>
                    <li class="feature-item"><span class="material-symbols-outlined check-icon">task_alt</span> <span>Create <span class="font-bold">instant AI images</span> like ChatGPT's paid subscription.</span></li>
                  </ul>
                </div>
      
                <div class="survey-block">
                  <div class="text-center mb-4">
                    <span class="badge-theme">
                      <span class="material-icons f-24 mr-2 text-red">warning</span> 47 Spots Left...
                    </span>
                  </div>
      
                  <h5 class="mb-8">To <span class="underline">reserve</span> a spot, fill out this survey form:</h5>
      
                  <div class="text-center">
                  <button type="button" class="btn btn-gradient btn-text-icon btn-xl mx-auto" onClick={urlSurvay} >Complete it in 2 mins <span class="material-icons f-24 ml-2 bounce-arrow">arrow_right_alt</span></button>
                  </div>
                </div>
              </div>
      
              <div class="text-center mb-8 arrow-ltr">
                <img class="mx-auto" src="images/arrow-ltr.svg" alt="Arrow" />
              </div>
      
              <div class="text-center btnaslink">
                <button type="button" class="f-20 text-gray underline font-semibold" onClick={hideSurvay}>Not interested </button>
              </div>
            </div>
        */}
                  </div>
                )}
              </>
              {/* )} */}
              {/* {submit && (
              <h3 class="mb-6 text-center">
                Your email will be sent when short video will ready
              </h3>
            )} */}

              {/*  <img class="mx-auto mb-6" src={`${process.env.PUBLIC_URL}/images/check-gradient.svg`} alt="Check" />
            <h3 class="mb-4 text-center">Viral short is ready!</h3>
            <a href={videolink} class="btn btn-gradient downbtn">Download short</a>
        */}
              {/*</div>
      </div>*/}
            </div>

            {/*  <div class="example-part">
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
           <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      */}
          </div>
          {/* <Footer/> */}
        </div>
      </Layout>
      <ClaimRewardPopup
      isOpenPop={isModalPopOpen}
      setIsOpenPop={setIsModalPopOpen}
      setIsSuccessModalPopOpen={setIsSuccessModalPopOpen}
      isSuccessModalPopOpen={isSuccessModalPopOpen}
      />
      <ClaimRewardSuccessPopup
      isOpenPop={isSuccessModalPopOpen}
      setIsOpenPop={setIsSuccessModalPopOpen}
      />
    </>
  );
}

export default Ready;
