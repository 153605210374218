import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FreeTrialPopup from "../../components/FreeTrialPopup/FreeTrialPopup";
import FreeTrialSuccessPopup from "../../components/FreeTrialSuccessPopup/FreeTrialSuccessPopup";
import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const NewVideoLandingPage = () => {
  const location = useLocation();
  const MobileVideo = JSON.parse(localStorage.getItem("mobileImg"));
  const user = JSON.parse(localStorage.getItem("user"));
  const videoUrl = location?.state?.forreadyvideo || localStorage.getItem('created-video-url')
  // const videoUrl = localStorage.getItem("created-video-url");
  const navigate = useNavigate();
  const [isFreeTrialPopupOpen, setIsFreeTrialPopupOpen] = useState(false);
  const [expandedBlocks, setExpandedBlocks] = useState({});
  const [isFreeTrialSuccessPopupOpen, setIsFreeTrialSuccessPopupOpen] =
    useState(false);

  const toggleBlock = (index) => {
    setExpandedBlocks((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const faqData = [
    {
      question: "What if I’m not satisfied?",
      answer: [
        "Out of thousands of active subscriptions, less than 0.1% were not happy.",
        'That being said, if you want to cancel, you can do so directly from your account. To cancel your subscription, simply click "Downgrade".',
      ],
    },
    {
      question: "Is this the fastest way to make videos?",
      answer: [
        "Thousands of active customers fell in love with Yatter because of how easy it is to make videos about anything.",
        "From our side, we have never heard of a faster tool than Yatter. Most video tools out there require some editing skills to fully use them. Yatter does not.",
      ],
    },
    {
      question: "I’m sold, where can I unlock Premium now!",
      answer: [
        "That’s great! All you need to do is go to our Pricing page and upgrade immediately. We always have a solid 30-day guarantee to make sure you are 100% confident.",
      ],
    },
    {
      question: "Is this for me?",
      answer: [
        "1. Are you a beginner with video making/editing?",
        "2. Do you want to edit for hours on end? Or just have a tool help you and save hours of your day?",
        "3. Do you want to do research and find all your footage manually? Or just have a tool help you directly?",
        "Simply put, if you want to make quick videos, without burning your day away editing - then Yatter is perfect for you.",
      ],
    },
    {
      question: "Is it true that you offer over $2000 of value with Premium?",
      answer: [
        "Yes! When you upgrade to Premium, we have 3 benefits that you unlock instantly.",
        "Simply put, if you purchase these individually they can cost upwards of $2000!",
      ],
    },
    {
      question: "What is your 30-money back guarantee?",
      answer: [
        "Our guarantee is super simple and let’s you purchase with confidence.",
        "Meaning if you are not 100% satisfied within the first 30 days of upgrading, you can downgrade and get all your money back immediately.*",
        "*This only applies to the yearly plan",
      ],
    },
  ];

  const handletobottomClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const downloadFile = () => {
    const downloadPath = `https://app.yatter.ai/webs/video/index.php?v=${videoUrl.substring(
      videoUrl.lastIndexOf("/") + 1
    )}`;
    window.open(downloadPath, "_blank");
  };
  const navigateToOpenEditor = async () => {
    user?.ID && (await mixpanel.identify(user?.ID));
    await mixpanel.track("Re-Render", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || "",
      "User Type": user?.orders.order_type,
    });
    navigate("/dashboard");
  };

  const handleFreeTrialPopup = () => {
    !user && setIsFreeTrialPopupOpen(true);
  };

  return (
    <div className="downloaded-video-page">
      <div>
        <div className="header-container custom-header-container" style={{ padding: "1rem 8rem 0.75rem" }}>
          {/*} <div className="mail-link">
            <a href="mailto:info@yatter.ai">
              <span className="material-symbols-outlined f-24 mr-2">mail</span>
              info@yatter.ai
            </a>
  </div>*/}

          <img style={{ margin: "auto" }}
            className="brand-logo cursor-pointer"
            src={require("../../images/logo.png")}
            alt="Yatter"
            onClick={() => navigate("/")}
          />

          {/*} <span className="openai-tag theme">
            Powered by{" "}
            <img
              className="ml-2"
              src={require("../../images/openai.svg").default}
              alt="OpenAI"
            />
</span>*/}
        </div>
      </div>








      <div class="section-wrap-md generated-video overflow-hidden" style={{ padding: "10px" }}>
        <img class="shape-triangle-1"
          src={require("../../images/shape-triangle.svg").default}
          alt="shape" />
        <img class="shape-triangle-2"
          src={require("../../images/shape-triangle-2.svg").default}
          alt="shape" />
        <img class="shape-circle"
          src={require("../../images/shape-circle.svg").default}
          alt="shape" />
        <img class="shape-circle-lg"
          src={require("../../images/shape-circle-lg.svg").default}
          alt="shape" />

        <div class="container-xxl">

          <div class="generated-video-wrap" style={{ flexDirection: "column" }}>
            <div class="video-contents low-height" style={{ padding: "0px 15px" }}>
              <div class="section-title xl center" style={{ margin: "0px" }}>
                {/*<h1 class="title-text flex items-center text-red"><span class="material-symbols-outlined check-icon mr-2">task_alt</span> Done!</h1>*/}
                <h1 class="title-text"><span>Your <span class="text-red">VIRAL</span> AI video is ready!</span>
                </h1>
                <h5 class="sub-title">Watch your video for free! You can use this tool anytime you need: Scroll down for more...</h5>
              </div>
            </div>

            <div class="video-view-wrap" style={{ width: MobileVideo == 0 ? "60%" : "380px" }} >
              <div class="text-center">
                <div className="video-view video-play-btn"
                  style={{ width: MobileVideo == 0 ? "100%" : "220px", maxWidth: "600px" }}
                >
                  <video
                    controls
                    style={{ width: MobileVideo == 0 ? "100%" : "", maxWidth: MobileVideo == 0 ? "600px" : "auto" }}
                  >
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                </div>

                <div className="flex justify-center items-center" onClick={downloadFile}>
                  <span class="border-animation">
                    <button type="button" className="btn btn-white btn-text-icon">
                      <span class="gradient-text flex items-center"><span class="material-symbols-outlined mr-2">
                        {" "}
                        download{" "}
                      </span>
                        Download Now
                      </span>
                    </button>
                  </span>
                </div>
                <div className="flex justify-center items-center" onClick={navigateToOpenEditor}>
                  {/* <span class="border-animation"> */}
                  <button type="button" style={{ backgroundColor: "yellow" }} className="btn btn-white btn-text-icon">
                    <span class="gradient-text flex items-center"><span class="material-symbols-outlined mr-2">
                      {" "}
                      edit{" "}
                    </span>
                      Open Editor
                    </span>
                  </button>
                  {/* </span> */}
                </div>


              </div>
            </div>
          </div>

        </div>

        <div class="scrol-down-indicator">
          <p class="mb-0">Scroll down to explore our <span class="font-bold" style={{ color: "red" }}>Exclusive Free Trial Option</span></p>
          <a href="#premium-features"> <span class="material-symbols-outlined animated-icon gradient-text bounce"> keyboard_double_arrow_down </span> </a>
        </div>

      </div>






      <div class="section-wrap-lg bg-light-gray wave-section" id="premium-features">

        <img class="wave-top"
          src={require("../../images/banner-wave-top-shape-offwhite.svg").default} alt="wave shape" />
        <img class="wave-bottom"
          src={require("../../images/banner-wave-bottom-shape-offwhite.svg").default} alt="wave shape" />

        <img class="dot-horizontal top"
          src={require("../../images/dot-shape-horizontal.svg").default}
          alt="Dot" />
        <img class="dot-horizontal bottom"
          src={require("../../images/dot-shape-horizontal.svg").default}
          alt="Dot" />

        <div class="container-xxl" >
          <div class="flex justify-center mb-6">
            <span class="premium-badge font-bold">
              <img class="premium-icon"
                src={require("../../images/premium.svg").default}
                alt="premium" /> Unlock Premium Features for Free
            </span>
          </div>

          <div class="section-title xl">
            <h1 class="title-text"><span><span class="text-red">Create AI videos</span> like these - anytime you need.</span>
            </h1>
            <h5 class="sub-title eb-garamond-font">(+ get exclusive features ONLY available with Premium)</h5>
          </div>

          <div class="card-wrap lg">
            <h1 class="text-center mb-6"><span class="italic">As a new user,</span> you can unlock these Premium options instantly:</h1>

            <ul class="feature-options mb-6">
              <li>
                <div class="feature-option-block bg-light-gradient">
                  <span class="feature-icon"><img
                    src={require("../../images/stock-img-feature.svg").default}
                    alt="Feature Image" /></span>
                  <span>Access <span class="font-bold">3+ million</span> stock images + top quality AI images.</span>
                </div>
              </li>
              <li>
                <div class="feature-option-block bg-light-gradient">
                  <span class="feature-icon"><img src={require("../../images/songs-feature.svg").default}
                    alt="Feature Image" /></span>
                  <span><span class="font-bold">Select high-quality songs</span> for your videos.</span>
                </div>
              </li>
              <li>
                <div class="feature-option-block bg-light-gradient">
                  <span class="feature-icon"><img
                    src={require("../../images/replace-image-feature.svg").default}
                    alt="Feature Image" /></span>
                  <span>Customize your videos and replace your images.</span>
                </div>
              </li>
              <li>
                <div class="feature-option-block bg-light-gradient">
                  <span class="feature-icon"><img
                    src={require("../../images/watermark-feature.svg").default}
                    alt="Feature Image" /></span>
                  <span><span class="font-bold">Remove the watermark</span> on all your videos.</span>
                </div>
              </li>
            </ul>

            <div class="free-trial-cta-wrap" onClick={handleFreeTrialPopup} id="pricing">
              <h2 class="mb-6 text-center">Give the <span class="text-red underline">FREE TRIAL</span> option a try! Pay 0$</h2>
              <div class="plans-btn-wrap">

                <a href="#" class="plans-btn-block">
                  <span class="text-tag bottom">
                    <span class="f-14 font-regular bg-white px-2">Then Only <strike>$9,99</strike> <span class="font-extrabold text-red">$5.99/month</span></span>
                  </span>
                  <img
                    src={require("../../images/free-trial-icon.svg").default}
                    alt="Free Trial" />
                  <h4>Enable my 14-day trial</h4>
                  <span class="material-icons"> east </span>
                </a>

              </div>
            </div>

            <div class="flex justify-center items-center f-14 text-gray font-bold mt-4">
              <span class="material-icons f-20 text-orange mr-1"> verified_user </span>
              Pay NOTHING for the first 14-days
            </div>

          </div>

        </div>
      </div>




      <div class="section-wrap-lg" id="faq">

        <div class="container-xxl">
          <div>
            <div class="section-title xl">
              <h1 class="title-text">Frequently Asked <span class="text-red">Questions</span></h1>
            </div>

            <div class="faq-wrap">

              <div class="faq-block bg-light-gradient expnded">
                <div class="faq-head">
                  <h3>What if I'm not satisfied?</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>Out of thousands of active subscriptions, less than 0.1% were not happy.</p>
                  <p>That being said, if you want to cancel, you can do so directly from your account. Simply click “Cancel my subscription.”</p>
                </div>
              </div>

              <div class="faq-block bg-light-gradient">
                <div class="faq-head">
                  <h3>Is this the fastest way to make videos?</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>Thousands of active customers fell in love with Yatter because of how easy it is to make videos about anything.</p>
                  <p>From our side, we have never heard of a faster tool than Yatter. Most video tools out there require some editing skills to fully use them. Yatter does not.</p>
                </div>
              </div>

              <div class="faq-block bg-light-gradient">
                <div class="faq-head">
                  <h3>I'm sold, where can I unlock Premium now!</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>That's great! All you need to do is go to our Pricing page and upgrade immediately. We always have a solid 30-day guarantee to make sure you are 100% confident.</p>
                </div>
              </div>

              <div class="faq-block bg-light-gradient">
                <div class="faq-head">
                  <h3>Is this for me?</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>1. Are you a beginner with video making/editing?</p>
                  <p>2. Do you want to edit for hours on end? Or just have a tool help you and save hours of your day?</p>
                  <p>3. Do you want to do research and find all your footage manually? Or just have a a tool help you directly?</p>
                  <p>Simply put, if you want to make quick videos, without burning your day away editing - then Yatter is perfect for you.</p>
                </div>
              </div>

              <div class="faq-block bg-light-gradient">
                <div class="faq-head">
                  <h3>Is it true that you offer over $2000 of value with Premium?</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>Yes! When you upgrade to Premium, we have 3 benefits that you unlock instantly. Simply put, if you purchase these individually they can cost upwards of $2000!</p>
                </div>
              </div>

              <div class="faq-block bg-light-gradient">
                <div class="faq-head">
                  <h3>What is your 30-money back guarantee?</h3>
                  <span class="material-symbols-outlined arrow-icon"> expand_more </span>
                </div>
                <div class="faq-desc">
                  <p>Our guarantee is super simple and let's you purchase with confidence.</p>
                  <p>Meaning if you are not 100% satisfied within the first 30 days of upgrading, you can downgrade and get all your money back immediately.*</p>
                  <p class="f-14 font-regular text-gray">*This only applies to the yearly plan</p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>




      <footer style={{ paddingBottom: "15px" }}>
        <div class="footer-wrap" >
          <div class="" >
            <p>© 2024 Yatter. All rights reserved</p>
          </div>

          {/*<div class="right responsive-100" style={{float:"right",marginRight:"8rem"}}>
          <ul class="footer-menu">
            
            <li><a href="#faq">FAQ</a></li>
            <li><a href="#premium-features">Our Policy</a></li>
          </ul>
        </div>*/}
        </div>
      </footer>


      <FreeTrialPopup
        isOpenPop={isFreeTrialPopupOpen}
        setIsOpenPop={setIsFreeTrialPopupOpen}
        setIsFreeTrialSuccessPopupOpen={setIsFreeTrialSuccessPopupOpen}
      />
      <FreeTrialSuccessPopup
        isOpenPop={isFreeTrialSuccessPopupOpen}
        setIsOpenPop={setIsFreeTrialSuccessPopupOpen}
      />
    </div >
  );
};

export default NewVideoLandingPage;
