import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAudiofromWebService,
  uploadMusicGenerate,
} from "../../services/SendImageService";
import { getGenerateImage } from "../../services/SendImageService";
import Cookies from "js-cookie";
import Switch from "react-switch";
import Topbar from "../../components/Topbar/Topbar";
import { Footer } from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

const audioSources = [
  {
    id: 1,
    src: "https://app.yatter.ai/webs/staticcontent/staticaudio.mp3",
    name: "Derek (Australia)",
  },
  {
    id: 2,
    src: "https://app.yatter.ai/webs/staticcontent/echo.mp3",
    name: "Tommy (USA)",
  },
  {
    id: 3,
    src: "https://app.yatter.ai/webs/staticcontent/nova.mp3",
    name: "Isabella (USA)",
  },
  {
    id: 4,
    src: "https://app.yatter.ai/webs/staticcontent/fable.mp3",
    name: "Charlotte (British)",
  },
  {
    id: 5,
    src: "https://app.yatter.ai/webs/staticcontent/shimmer.mp3",
    name: "Amir (India)",
  },
];

function GetAudio() {
  const [AudioSrc, setAudioSrc] = useState("");
  const [CusAudioSrc, setCusAudioSrc] = useState("");
  const [cusMusicUpl, setcusMusicUpl] = useState("");
  const [loading, setLoading] = useState(false);
  const [Cusbgmusicfilename, setCusbgmusicfilename] = useState("");
  const [hover, setHover] = useState(false);
  const [voice, setVoice] = useState(true);
  const [music, setMusic] = useState(false);
  // const [count, setCount] = useState(0);
  const [count, setCount] = useState(audioSources[0].src);
  const [volume, setVolume] = useState(25); // Default volume is 100%
  const [whiteparticles, setWhiteParticles] = useState(false);
  const [playmusic, setPlayMusic] = useState(false);
  const [caption, setCaption] = useState({
    caption_switch: true,
    caption_color: "&HFFFFFF&",
    caption_align: "Bottom",
  });
  const user = JSON.parse(localStorage.getItem("user"));
  // const [selectedItem, setSelectedItem] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedId, setSelectId] = useState();
  const [selectedColorId, setSelectColorId] = useState();
  // const [check, setCheck] = useState();

  const [check, setCheck] = useState(audioSources[0].id);
  const [voiceId, setVoiceId] = useState();
  const [stopAudio, setStopAudio] = useState(false);
  const [audioTimeout, setAudioTimeout] = useState(false);
  // const audioData = [
  //   { id: 1, src: 'https://actions.google.com/sounds/v1/alarms/alarm_clock.ogg', name: 'Epic Battle Music' },
  //   { id: 2, src: 'http://webaudioapi.com/samples/audio-tag/chrono.mp3', name: 'Transquility-Anno Domini...' },
  //   { id: 3, src: 'https://app.yatter.ai/webs/mp3s/backmusic3.mp3', name: 'Forever-Anno Be...' },
  //   { id: 4, src: 'https://app.yatter.ai/webs/mp3s/backmusic4.mp3', name: 'Heaven and Hell-Jeremy...' },
  // ];
 /* const audioData = [
    {
      id: 1,
      src: "https://app.yatter.ai/webs/staticcontent/backmusic1.mp3",
      name: "Epic Battle Music",
    },
    {
      id: 2,
      src: "https://app.yatter.ai/webs/staticcontent/backmusic2.mp3",
      name: "Transquility-Anno Domini...",
    },
    {
      id: 3,
      src: "https://app.yatter.ai/webs/staticcontent/backmusic3.mp3",
      name: "Forever-Anno Be...",
    },
    {
      id: 4,
      src: "https://app.yatter.ai/webs/staticcontent/backmusic4.mp3",
      name: "Heaven and Hell-Jeremy...",
    },
  ];
*/
const [audioData, setAudioData]  = useState([
    {
        id: 1,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic1.mp3",
        name: "Funky vibes",
    },
    {
        id: 2,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic2.mp3",
        name: "Night vibes...",
    },
    {
        id: 3,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic3.mp3",
        name: "Action time!...",
    },
    {
        id: 4,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic4.mp3",
        name: "Mysteries roaming ...",
    },
    {
        id: 5,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic5.mp3",
        name: "Fantasy realms ...",
    },
    {
        id: 6,
        src: "https://app.yatter.ai/webs/staticcontent/backmusic6.mp3",
        name: "Cartoon vibes ...",
    },
    {
      id: 7,
      src: "https://app.yatter.ai/webs/staticcontent/backmusic7.mp3",
      name: "Classical Piano ...",
  },
  {
    id: 8,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic8.mp3",
    name: "Classical ...",
  },
  {
    id: 9,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic9.mp3",
    name: "Italian Romance ...",
  },
  {
    id: 10,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic10.mp3",
    name: "Lofi vibes ...",
  },
  {
    id: 11,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic11.mp3",
    name: "Relaxing Soiree ...",
  },
  {
    id: 12,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic12.mp3",
    name: "Slow jazz ...",
  },
  {
    id: 13,
    src: "https://app.yatter.ai/webs/staticcontent/backmusic13.mp3",
    name: "Upbeat and Fun ...",
  },
]);

const colorData = [
  {
      id: 1,
      code: "#FFFFFF",
      name: "&HFFFFFF&",
  },
  {
      id: 2,
      code: "#F77F07",
      name: "&H077FF7&",
  },
  {
      id: 3,
      code: "#F9E50D",
      name: "&H0DE5F9&",
  },
  {
      id: 4,
      code: "#03B61F",
      name: "&H1FB603&",
  },
  {
      id: 5,
      code: "#0135AF",
      name: "&HAF3501&",
  },
];

const captionAlignData = [
  {
      id: 1,
      name: "Top",
  },
  {
      id: 2,
      name: "Middle",
  },
  {
      id: 3,
      name: "Bottom",
  },
];


  // const bgMusic_data = [
  //   {
  //     id: 1,
  //     audioSrc: 'https://app.yatter.ai/webs/mp3s/staticaudio1.mp3',
  //     voiceName: 'Epic Battle Music',
  //   },
  //   {
  //     id: 2,
  //     audioSrc: 'https://pixabay.com/music/synthwave-stranger-things-124008/',
  //     voiceName: 'Transquility-Anno Domini...',
  //   },
  //   {
  //     id: 3,
  //     audioSrc: 'https://app.yatter.ai/webs/mp3s/staticaudio2.mp3',
  //     voiceName: 'Forever-Anno Domini Be...',
  //   },
  //   {
  //     id: 4,
  //     audioSrc: 'https://app.yatter.ai/webs/mp3s/staticaudio2.mp3',
  //     voiceName: 'Heaven and Hell-Jeremy...',
  //   },
  // ]
  // Handler for when the selection changes

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const items = location?.state?.items || []; // Accessing data passed from Screen 1
  // console.log(items);
  /* const data = {
     key: '850Q',
     // Other data to send
   };
 */
  //GetAudiofromWebService ("You are IN");

  useEffect(() => {
    const loadData = async () => {
      const scriptout = Cookies.get("scripttxt");
      console.log("cookie in getaudio");
      console.log(scriptout);
      setLoading(true);
      const result = await GetAudiofromWebService(scriptout);
      console.log("result is");
      //  console.log(result);
      if (result) {
        setLoading(false);
        // setCount(result);
        setCount(audioSources[0].src);
      }
    };

    loadData();

    document.getElementById("captionalign-3").checked = true;

    //const goin=GetAudiofromWebService ("You are IN");
    //console.log('go inhere');
    //console.log(goin);
    // setCount(goin); // Initialize or perform a side effect once
  }, []); // Empty dependency array

  /* setLoading(true);

   useEffect(() => {
       const audiores=GetAudiofromWebService('here you got');
       if(audiores)
       {
       setAudioSrc(audiores);
       setLoading(false);
       }
     }, []); // Empty dependency array
*/
  /*  const audiores=GetAudiofromWebService('here you got');
    if(audiores)
    {
    setAudioSrc(audiores);
    setLoading(false);
    }*/

  const navigateToVideo = async () => {
    console.log("items are :");
    console.log(items);

    // const newKey = Math.max(...Object.keys(items).map(Number)) + 1;
    const numericKeys = Object.keys(items).filter((key) => !isNaN(Number(key)));
    const newKey =
      numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
    let newlink = { audio: count };
    items[newKey] = newlink;
    // const updatedValue = { ...items, bgMusicData: { music_name: selectedValue.audioSrc, voice_switch: voice } };
    const updatedValue = {
      ...items,
      bgMusicData: {
        music_name: AudioSrc,
        music_switch: music,
        Volume: volume,
        music_name_custom: CusAudioSrc,
      },
      white_particles: whiteparticles,
      voice: voice,
      Caption: caption,
      MobileVideo:localStorage.getItem("mobileImg"),
    };
    console.log("getaudioupdated", updatedValue);
    const Audio = { audio: count };
    // const updatedItems = { ...items, Audio };
    console.log("updated items are :", items);
    // console.log(items);
    //  const dataToPass = { items };
    audioRefff?.current?.pause();
    // navigate("/dashboard", { state: { items: updatedValue } });
    navigate("/business-info", { state: { items: updatedValue } });
  };

  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  // const audioRef = useRef(Array.from({ length: audioSources.length }, () => React.createRef())); // Initialize with refs
  const audioRef = useRef(null); // Reference to the audio element
  const audioRefff = useRef(null); // Reference to the audio element

  // const audioRefff = useRef(new Audio());

  // const togglePlayPause = (items) => {
  //   e.preventDefault();
  //   console.log('check audio ref');
  //   if (audioRefff.current) {
  //     audioRefff.current.pause()
  //   }

  //   console.log('inside view', isPlaying);
  //   const audio = audioRef.current;
  //   console.log('audio', audio);
  //   // audio.play();
  //   if (isPlaying) {
  //     audio.pause();
  //     setIsPlaying(false);
  //   } else {
  //     audio.play();
  //     setIsPlaying(true);
  //   }
  //   setIsPlaying(!isPlaying); // Toggle the state
  // };
  const togglePlayPause = (items) => {
    console.log("items.id", items.id);
    if (voiceId === items.id) {
      const audio = audioRef.current;
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play();
        setIsPlaying(true);
        setCount(audio?.src);
      }
    } else {
      setVoiceId(items.id);
    }
  };

  useEffect(() => {
    // console.log('ccc', voiceId);
    if (voiceId) {
      const selectedTrack = audioSources.find((track) => track.id === voiceId);
      console.log("selectedTrack", selectedTrack);
      if (selectedTrack) {
        // console.log("id", voiceId);
        if (audioRefff.current) {
          console.log("Audio ref is available");
          audioRefff.current.pause(); // Corrected from audioRefff.current
        }
        const audio = audioRef.current;
        console.log("Setting audio source:", selectedTrack.src);
        audio.src = selectedTrack.src;
        setCount(audio?.src);
        setIsPlaying(true);
        audio
          .play()
          .then(() => console.log("Audio playback started"))
          .catch((error) => console.error("Error playing the audio:", error));
      }
    }
  }, [voiceId]);

  const handlePauseVoice = () => {
    if (voiceId) {
      const selectedTrack = audioSources.find((track) => track.id === voiceId);
      if (selectedTrack) {
        if (audioRefff.current) {
          audioRefff.current.pause();
        }
        const audio = audioRef.current;
        if (isPlaying) {
          audio.pause();
          setIsPlaying(false); // Pause music, update state
        } else {
          audio.play();
          setIsPlaying(true); // Start playing music, update state
        }
      }
    }
  };

  function getFileExtension(fileName) {
    // return fileName
    // Use a regex pattern to find the extension
    var pattern = /\.([a-zA-Z0-9]+)$/;
    var match = fileName.match(pattern);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  const handleFileChangesss = () => async (event) => {
    const allowedTypes = ["mp3"];
    const file = event.target.files[0];
    let randomId = Math.floor(Math.random() * 9000) + 1000
    if(file.name){
    var extension = getFileExtension(file.name);
    console.log(file.name);
    if (allowedTypes.includes(extension)) {
      let data = new FormData();
      setCusAudioSrc(file.name);
      data.append("image", file);
      console.log(file.name);
      const musicUpl = await uploadMusicGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        // setUploadProgress(progress);
      });
      setCusbgmusicfilename(file.name);
      setcusMusicUpl(musicUpl)
      setAudioData(prevAudioData => {
        return [
          ...prevAudioData,
          {
            id: randomId,
            src: musicUpl,
            name: file.name,
          }
        ];
    });
    if(musicUpl){
      setAudioTimeout(true)
    }
      setCusAudioSrc(musicUpl);
      setAudioSrc(musicUpl);
      console.log(CusAudioSrc);
      setSelectId(`${randomId}`)
    } else {
      console.log("File type not allowed. Please select a mp3 file.");
    }
    document.getElementById("filemp3open").value = ""; //clear the file input field
  }};

  const handlecusMusicClose = () => async (event) => {
    
      const musicUpldel = "";
     
      setcusMusicUpl(musicUpldel)
     
  };

  useEffect(() => {
    if (cusMusicUpl) {
      console.log("musicupl");
      console.log(cusMusicUpl)
      
    }
    else
    {
      console.log("musicuplnone");
    }
  }, [cusMusicUpl]); // This effect runs when cusMusicUpl changes.

  // const togglePlayaudio = (id, src) => {
  //   if (selectedId === id) {
  //     // Pause if the same audio is clicked again
  //     if (audioRefff.current.paused) {
  //       audioRefff.current.play();
  //     } else {
  //       audioRefff.current.pause();
  //     }
  //   } else {
  //     // Play a new audio and pause the currently playing one
  //     if (audioRefff.current) {
  //       audioRefff.current.pause();
  //     }
  //     audioRefff.current = new Audio(src);
  //     audioRefff.current.play();
  //     setSelectId(id);
  //     setAudioSrc(src);
  //   }
  // };

  const togglePlayaudio = async (event) => {
    setSelectId(event.target.value);
    user?.data?.ID && (await mixpanel.identify(user?.data.ID));
    await mixpanel.track("Choose Music - Video Creation Steps", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || "",
      'User Type': user?.orders.order_type || "guest",
    });
  };

  useEffect(() => {

    if (selectedId) {
      const selectedTrack = audioData.find(
        (track) => track.id.toString() === selectedId
      );

      let rnum= Math.random() * 100;

      if (selectedTrack) {
        if (![1, 2, 3, 4, 5, 6].includes(selectedId) && audioTimeout) {
          // If selectedId is not in the specified set, add a 5-second timeout before proceeding
          setTimeout(() => {
            if (audioRef.current) {
              audioRef?.current?.pause();
            }
            const audio = audioRefff.current;
            audio.src = selectedTrack.src;
            audio.className = selectedTrack.src;
            audio.key = rnum;
            // console.log(audio.src);
            setAudioSrc(audio?.src);
            setPlayMusic(true);
            audio
              .play()
              .catch((error) => console.error("Error playing the audio:", error));
              setAudioTimeout(false)
          }, 2000)
        }else{
          if (audioRef.current) {
            audioRef?.current?.pause();
          }
          const audio = audioRefff.current;
          audio.src = selectedTrack.src;
          audio.className = selectedTrack.src;
          audio.key = rnum;
          // console.log(audio.src);
          setAudioSrc(audio?.src);
          setPlayMusic(true);
          audio
            .play()
            .catch((error) => console.error("Error playing the audio:", error));
        }
      }
    }
  }, [selectedId]); // This effect runs when selectedId changes.

  // console.log('audioref', audioRef);

  let currentAudio = null;
  let currentAudioElement = null;

  const togglePlay = (item, index) => {
    // const selectedAudioUrl = item.audioSrc;
    // // setSelectedValue(selectedAudioUrl);
    // console.log('selectedAudioUrl', selectedAudioUrl);
    // // Play the selected audio
    // if (selectedAudioUrl) {
    //   const audio = new Audio(selectedAudioUrl);
    //   audio.play().catch(error => console.error("Error playing the audio file:", error));
    // }

    // const currentAudio = audioRefff.current[index].current;
    // const currentAudio = audioRefff.current;
    // console.log('currentAudio', currentAudio);
    // currentAudio.play();

    const selectedAudioUrl = item.audioSrc;
    console.log("selectedAudioUrl", selectedAudioUrl);

    // Stop the currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset audio to start
    }

    // If the selected item is the same as the currently playing, just stop and reset
    if (
      currentAudioElement &&
      currentAudioElement.id === `audioPlayer-${item.id}`
    ) {
      currentAudio = null;
      currentAudioElement = null;
      return;
    }

    // Play the selected audio
    const audioElement = document.getElementById(`audioPlayer-${item.id}`);
    if (audioElement) {
      audioElement
        .play()
        .then(() => {
          console.log("Audio is playing");
          currentAudio = audioElement;
          currentAudioElement = audioElement;
        })
        .catch((error) => {
          console.error("Error playing the audio file:", error);
          // Handle autoplay restriction or other errors
          // For example, you could show a message asking the user to click again
        });
    }
  };

  const handleVoice = (e) => {
    setVoice(!voice);
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
    let volumnchange=event.target.value/100;
    let mybgmusicsudio=document.getElementById("bgmusicaudio");
    mybgmusicsudio.volume = volumnchange;
  };

  const handleCheckboxChange = () => {
    setCaption((prevState) => ({
      ...prevState,
      caption_switch: !prevState.caption_switch,
    }));
  };

  const handleColorChange = async (event) => {
    const newColor = event.target.value;
    console.log('color is ',newColor);
    setCaption((prevState) => ({
      ...prevState,
      caption_color: newColor,
    }));
    user?.data?.ID && await mixpanel.identify(user?.data.ID);
    await mixpanel.track("Choose Captions Color - Video Creation Steps", {
      Email: user?.data.user_email,
      "User Id": user?.data.ID || '',
      'User Type': user?.orders.order_type || 'guest',
    });
  };

  const handleCaptionAlignChange = async (event) => {
    const newCaptionAlign = event.target.value;
    console.log('align is ',newCaptionAlign);
    setCaption((prevState) => ({
      ...prevState,
      caption_align: newCaptionAlign,
    }));
  };

  useEffect(() => {
    if (audioRefff.current) {
      // audioRefff.current.volume = volume;
      audioRefff.current.volume = volume / 100;
    }
  }, [volume]);

  // console.log(caption);

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  //   const selectedAudioUrl = event.target.value;
  //   setSelectedValue(selectedAudioUrl);

  //   // Play the selected audio
  //   if (selectedAudioUrl) {
  //     const audio = new Audio(selectedAudioUrl);
  //     audio.play().catch(error => console.error("Error playing the audio file:", error));
  //   }
  // };

  const handlePlayPausedd = () => {
    if (selectedId) {
      const selectedTrack = audioData.find(
        (track) => track.id.toString() === selectedId
      );
      if (selectedTrack) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        const audio = audioRefff.current;
        if (playmusic) {
          audio.pause();
          setPlayMusic(false); // Pause music, update state
        } else {
          audio.play();
          setPlayMusic(true); // Start playing music, update state
        }
      }
    }
  };

  return (
    <>
      {/* <Topbar /> */}
      <Layout HeaderName="Making your professional video">
        <div className={user ? "fullscreen-page" : "fullscreen-page_logout"}>
          <div
            className={
              user
                ? "short-creator-block customStyle"
                : "customStyleLogoutGetScript customStyleLogout short-creator-block"
            }
          >
            <div class="creator-wrapper">
              <h3 class="mb-4">AI Voice</h3>

              <div class="progress-steps">
                <div
                  class="step-block completed"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  style={{ cursor: hover && "pointer" }}
                  onClick={() => {
                    hover && navigate("/get-script");
                    localStorage.removeItem("imagePageState");
                  }}
                >
                  <span
                    class="step-label"
                    style={{ color: hover && "#F11D28" }}
                  >
                    1. Choose Script
                  </span>
                  <span
                    class="step-indicator"
                    style={{ transform: hover && "scale(1.06)" }}
                  >
                    {" "}
                    <span class="material-icons check-icon">check</span>
                  </span>
                </div>
                <div
                  class="step-block completed"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  style={{ cursor: hover && "pointer" }}
                  onClick={() => {
                    hover && navigate("/get-images");
                    // localStorage.removeItem('imagePageState');
                    // audioRefff.current.pause();
                  }}
                >
                  <span
                    class="step-label"
                    style={{ color: hover && "#F11D28" }}
                  >
                    2. Choose Images
                  </span>
                  <span
                    class="step-indicator"
                    style={{ transform: hover && "scale(1.06)" }}
                  >
                    <span class="material-icons check-icon">check</span>
                  </span>
                </div>
                <div class="step-block active">
                  <span class="step-label">3. Choose Video</span>
                  <span class="step-indicator">
                    <span class="material-icons check-icon">check</span>
                  </span>
                </div>
              </div>

              <form class="creator-form" action="#">
                <div class="checkbox-container">
                  <div class="flex items-center">
                    <input
                      class="w-4 h-4 bg-gradient rounded"
                      type="checkbox"
                      id="short-voice"
                      onChange={handleVoice}
                      checked={voice}
                      style={{display:"none"}}
                    />
                    <label for="short-voice" class="ms-2" style={{marginLeft:"1.5rem"}}>
                      Voice
                    </label>
                  </div>

                  {voice && (
                    <div class="voices">
                      {audioSources.map((items) => (
                        <div
                          key={items.id}
                          class="voice-block selected"
                          onClick={async () => {
                            setCheck(items.id);
                            setCount(items.src);
                            console.log("user?.data?.ID")
                            user?.data?.ID && await mixpanel.identify(user?.data.ID);
                            await mixpanel.track("Choosing Voice - Video Creation Steps", {
                              Email: user?.data.user_email,
                              "User Id": user?.data.ID || "",
                              'User Type': user?.orders.order_type || "guest",
                            });
                          }}
                        >
                          {/*  <audio ref={audioRef} id='audioPlayer' src={count}/> */}
                          <audio
                            ref={audioRef}
                            id={`audioPlayer_${items.id}`}
                            src={items.src}
                            onEnded={() => setIsPlaying(false)}
                          />
                          {loading ? (
                            ""
                          ) : isPlaying && voiceId === items.id ? (
                            <a
                              class="voice-play"
                              onClick={() => handlePauseVoice()}
                            >
                              <span class="material-icons check-icon">
                                pause
                              </span>
                            </a>
                          ) : (
                            <a
                              class="voice-play"
                              onClick={() => togglePlayPause(items)}
                            >
                              <span class="material-symbols-outlined">
                                play_circle
                              </span>
                            </a>
                          )}
                          {/*} <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'play' : 'pause'}<span class="material-symbols-outlined">play_circle</span></a>*/}
                          <span class="voice-name">
                            {items.name}
                            {/* {voiceId === items.id && <span className="material-icons check-icon">check</span>}</span> */}
                            {check === items.id && (
                              <span className="material-icons check-icon">
                                check
                              </span>
                            )}
                          </span>
                        </div>
                      ))}

                      {/* <audio ref={audioRef} src={count}></audio>
      <a onClick={togglePlayPause}>
        <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}<span class="material-symbols-outlined">play_circle</span></a>
  </a>*/}
                    </div>
                  )}

                 
                </div>

                {/* <div className='flex mb-2'>
            <label htmlFor="insert-qabout" className="caption">Background Music</label>
            <Switch
              onChange={() => setMusic(!music)}
              checked={music}
              onColor='#FFA12C'
              onHandleColor="#fffff"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={18}
              width={48}
              className="react-switch"
              id="material-switch"
            />
          </div> */}

                <div class="checkbox-container">
                  <div class="flex items-center">
                    <input
                      id="short-bg-music"
                      type="checkbox"
                      value={music}
                      onChange={() => setMusic(!music)}
                      class="w-4 h-4 bg-gradient rounded"
                    />
                    <label for="short-bg-music" class="ms-2">
                      Background Music
                    </label>
                  </div>

                  <div class="bg-music">
                    {music && (
                      <>
                        <span class="block f-14 font-regular mb-2 mt-3">
                          Choose background audio
                        </span>
                        
                        

                        

                      {cusMusicUpl && stopAudio ? (
                          <div class="own-file-uploaded">
                          <span class="file-icon"><span class="material-symbols-outlined"> music_note </span></span>
                          <span class="file-text">
                            <p class="f-14 font-medium cusmusicname">{Cusbgmusicfilename}</p>
                            
                          </span>
                          <button type="button" class="btn btn-close-icon" onClick={handlecusMusicClose()}><span class="material-symbols-outlined"> close </span></button>
                        </div>
                        ) : (
                          <><div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {/* {
selectedId&&
<span className="material-icons check-icon" style={{ cursor: "pointer" }} onClick={handlePlayPause}>
{playmusic ? 'pause' : 'play_circle'}
</span>
} */}
                              {AudioSrc && (
                                <span
                                  className="material-icons "
                                  style={{ cursor: "pointer" }}
                                  onClick={handlePlayPausedd}
                                >
                                  {playmusic ? "pause" : "play_circle"}
                                </span>
                              )}
                              <select
                                className="input-control"
                                value={selectedId}
                                onChange={togglePlayaudio}
                              >
                                <option selected value="">
                                  Select Music
                                </option>
                                {audioData?.map(({ id, name }) => (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                ))}
                              </select>
                              
                              <audio
                              id="bgmusicaudio"
                                ref={audioRefff}
                                onEnded={() => setPlayMusic(false)} />
                            </div>
                            <p class="mb-4 mt-4 text-gray">OR</p><div class="flex items-center ">
                                <div class="btn-premium-input">
                                  <button
                                    type="button"
                                    class="btn btn-premium"
                                  >
                                    <img
                                      class="premium-icon"
                                      src="images/premium.svg"
                                      alt="Premium" />{" "}
                                    Upload my own music
                                  </button>
                                  <input
                                  id="filemp3open"
                                    class="btn btn-premium sxs"
                                    type="file" accept=".mp3"
                                    onChange={handleFileChangesss()} />
                                </div>
                              {/*  <div class="flex justify-center m-2">
                                  <span class="f-14 text-gray flex items-center">
                                    <span class="material-symbols-outlined mr-1 f-20">
                                      info
                                    </span>{" "}
                                    Only with Premium. Coming May 1st
                                    
                                  </span>
                              </div>*/}
                              </div></>
                        )}



                        <div class="flex items-center mt-4">
                          <span class="text-gray mr-4">Volume:</span>
                          <div class="volume-control mr-4">
                            <input
                              type="range"
                              min="1"
                              max="50"
                              value={volume}
                              class="slider-volume"
                              onChange={handleVolumeChange}
                            />
                          </div>
                          <span class="font-medium">{volume}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* <div class="checkbox-container">
            <div class="flex items-center">
              <input
                id="short-caption"
                type="checkbox"
                class="w-4 h-4 bg-gradient rounded"
                checked={whiteparticles}
                onChange={() => setWhiteParticles(!whiteparticles)}
              />
              <label for="short-caption" class="ms-2">White particles</label>
            </div>
          </div> */}

                <div class="checkbox-container">
                  <div class="flex items-center">
                    <input
                      id="short-caption"
                      type="checkbox"
                      class="w-4 h-4 bg-gradient rounded"
                      checked={caption.caption_switch}
                      onChange={handleCheckboxChange}
                    />
                    <label for="short-caption" class="ms-2">
                      Captions
                    </label>
                  </div>

                  {/*
                    <div class="captions">
                      <div class="flex items-center">
                        <span class="text-gray mr-4">Caption color:</span>
                        <input
                          id="favcolor"
                          name="caption_color"
                          value={caption.caption_color}
                          type="color"
                          onChange={handleColorChange}
                          // onChange={(e) => setCaption({ ...caption, [e.target.name]: e.target.value })}
                        />
                      </div>
                    </div>
        */ }

                           {/* <select
                            className="input-control"
                            value={selectedColorId}
                            onChange={handleColorChange}
                            >
                            {colorData.map(({ id, code }) => (
                              <option key={id} value={code}>
                                {code}
                              </option>
                            ))}
                          </select>
                          */}

                        <div class="captions captionsradioboxes">
                      <div class="flex items-center">
                        <span class="text-gray mr-4">Caption color:</span>
                        {
                        
                            colorData.map(({ id, code, name }) => (
                              <div class="form-group">
                              <>
                              <input type="radio" key={id} id={`colorradio-${id}`} name="colorradio" value={name} onChange={handleColorChange}  />
                              <label for={`colorradio-${id}`} style={{'background-color':code}}></label>                            
                              </>                             
                              </div>
                            ))
                          }
                      </div>
                    </div>

                    <div class="mt-5">
                  <span class="text-gray block mb-3" style={{marginBottom:"20px"}}>Caption position:</span>

                  <div class="custom-radio-btn-wrap captionalignradioboxes">


                  {  
                        captionAlignData.map(({ id, name }) => (
                          <div class="radio-btn-block">
                          <>
                          <input type="radio" className="custom-radio-btn" key={id} id={`captionalign-${id}`} name="captionalign" value={name} onChange={handleCaptionAlignChange}  />
                          <label  className="custom-radio-btn" id={`captionalignlabel-${id}`} for={`captionalign-${id}`}>{name}</label>
                          </>                             
                          </div>
                        ))
                      }



                   {/* <div class="radio-btn-block">
                      <span class="custom-radio-btn">Top</span>
                    </div>

                    <div class="radio-btn-block">
                      <span class="custom-radio-btn">Middle</span>
                    </div>

                    <div class="radio-btn-block">
                      <span class="custom-radio-btn selected">Bottom</span>
                      </div>*/}






                        <div class="radio-btn-block">
                          <span class="flex items-center text-red pb-3">
                            <span class="material-symbols-outlined f-18 mr-1"> star </span>
                            <span>Most Popular</span>
                          </span>
                        </div>

                  </div>
                </div>





                </div>

                {/* {
            music &&
            <>
              {audioData.map(({ id, src, name }) => (
                <div key={id} className={`voices ${selectedId === id ? 'selected' : ''}`}>
                  <div
                    // className="voice-block"
                    className={`voice-block ${selectedId === id ? 'selected' : ''}`}
                  >
                    <audio ref={audioRefff} src={src} />
                    <a className="voice-play"
                      onClick={() => togglePlayaudio(id, src)}
                    >
                      <span className="material-symbols-outlined">play_circle</span>
                    </a>
                    <span className="voice-name">{name} <span className="material-icons check-icon">check</span></span>
                  </div>
                </div>
              ))}

            </>
          } */}

                {/* <div>
          <span class="text-gray mr-4">Volume:</span>
            <input
              id="volumeControl"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
                 <span class="font-medium">{volume}</span>
          </div> */}
                {/* <div class="flex items-center">
            <span class="text-gray mr-4">Volume:</span>
            <div class="volume-control mr-4">
              <input type="range" min="1" max="100" value="50" class="slider-volume" id="myRange" />
            </div>
            <span class="font-medium">50</span>
          </div> */}
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <button
                    type="button"
                    class="btn btn-gradient"
                    onClick={navigateToVideo}
                  >
                    Generate short
                  </button>
                )}
              </form>
            </div>

            <div class="advice-wrapper getaudiowrapper gap-4 flex-col flex">
            <div class="advice-wrapper !ml-0">
              <h5 class="font-bold mb-3">Yatter's Premium Advice:</h5>
              <ul class="advice-points">
                <li class="flex">
                  <span class="material-symbols-outlined f-24 gradient-text mr-3">
                    {" "}
                    task_alt{" "}
                  </span>
                  <span class="f-14">
                    Our {" "}<span class="font-semibold">highest converting music</span> is Classical and Upbeat.
                  </span>
                </li>
                <li class="flex">
                  <span class="material-symbols-outlined f-24 gradient-text mr-3">
                    {" "}
                    task_alt{" "}
                  </span>
                  <span class="f-14">
                    We keep captions super simple because fancy captions actually make videos confusing.
                  </span>
                </li>
                <li class="flex">
                  <span class="material-symbols-outlined f-24 gradient-text mr-3">
                    {" "}
                    task_alt{" "}
                  </span>
                  <span class="f-14">
                    Our{" "}
                    <span class="font-semibold">best performing voices</span>are American and British.
                  </span>
                </li>
              </ul>
              <div class="formula-tooltip">
                <a href="#!" class="text-red flex items-center font-medium">
                  <span class="material-symbols-outlined f-22 mr-2">
                    {" "}
                    help{" "}
                  </span>
                  How do we get this formula?
                </a>
                <div class="custom-tooltip relative">
                  <span class="text-white">
                    Our AI algorithm studies 1,500+ viral videos per hour and
                    determines exactly what people use in these famous videos.
                    This blueprint is directly given to you at no cost!
                  </span>
                </div>
              </div>
            </div>
         {/* <div class="my-plan-wrap w-full">
            <div class="my-plan-card">
              <div class="head">
                <span class="premium-icon">
                  <img src="images/premium.svg" alt="Premium"/>
                </span>
                <h5>Yatter Premium <span class="font-normal">lets you:</span></h5>
              </div>
              <div class="mb-2">
                <ul class="list-wrapper text-left">
                  <li class="list-item"><span class="material-symbols-outlined f-18 mr-2 text-white">task_alt</span> <span><span class="f-14 font-normal">Add your <span class="font-bold">custom music</span></span></span></li>
                  <li class="list-item"><span class="material-symbols-outlined f-18 mr-2 text-white">task_alt</span> <span><span class="f-14 font-normal">Add your <span class="font-bold">own voice</span></span></span></li>
                  <li class="list-item"><span class="material-symbols-outlined f-18 mr-2 text-white">task_alt</span> <span><span class="f-14 font-normal">Add <span class="font-bold">custom captions</span></span></span></li>
                </ul>
              </div>
              <div>
                <button type="button" class="btn btn-white btn-sm w-full">Coming Soon</button>
              </div>
            </div>
          </div> */}
        </div>

            {/*<div class="example-part">
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
          <img className="media-file" src={`${process.env.PUBLIC_URL}/images/ai-example.jpg`} alt="Example media"/> 
          <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      */}
          </div>
        </div>
      </Layout>
      {/* <Footer/> */}
    </>
  );
}

export default GetAudio;
