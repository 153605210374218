import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dino from "../../components/Dino/Dino";
import Cookies from "js-cookie";
import {
  generateImage,
  generateImaged3,
  generateImageSingle,
  generateImagedStb,
  cursortowait,
  cursortodefault,
} from "../../services/chatGPTService";
import { ImageModal } from "../../ImageModal";
import Modal from "../../Modal";
import trash from "../../images/trash.svg";
import {
  getGenerateImage,
  uploadImageGenerate,
} from "../../services/SendImageService";
import {
  getUploadImage,
  getUploadImageId,
  imageGenerate,
  imageGenerateID,
  uploadImage,
} from "../../services/leonardoService";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDrag, useDrop } from "react-dnd";
// import image from "./images/images.svg";
import image from "../../images/images.svg";
import Topbar from "../../components/Topbar/Topbar";
import { Footer } from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import loaderImage from "../../images/Spinner@1x-1.0s-200px-200px.gif";
import loaderImage2 from "../../images/Spinner2@1x-1.0s-200px-200px.gif";
import { getPexelsvideoApi } from "../../Api/getPexelsvideoApi/getPexelsvideoApi.js";
import { getStockImages } from "../../Api/getStockImages/getStockImages.js";
import { getStockVideos } from "../../Api/getStockVideos/getStockVideos.js";
import { all } from "axios";
import { getSearchPexels } from "../../Api/getSearchPexels/getSearchPexels.js";
import { getThreeAiImages } from "../../Api/getThreeAiImages/getThreeAiImages.js";
import { getSingleAiImages } from "../../Api/getSingleAiImages/getSingleAiImages.js";
import { getRemainingImages } from "../../Api/getRemainingImages/getRemainingImages.js";
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });



function GetImages() {

  //new code 
  const [GetNewImages, GetImagess] = useState(true);
  // const [GetImagess, setGetImages] = useState(false);
  // const handleSignin = () => {
  //   setGetNewImages(false)
  // } 
  // const handleSignins = () => {
  //   setGetImages(true)
  // }
  //new-end
  const location = useLocation();

  //new code
  const [isVisible, setIsVisible] = useState(true);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  let aiText;
  const [showGetImages, setShowGetImages] = useState(true);
  const [show2btns, setshow2btns] = useState(true);
  const [showrestpage, setrestpage] = useState(false);
  /* const handleGenerate = () => {
   navigate("/dashboard", {
     state: {
       subFormData: subformData,
       call: true,
     },
   });
   localStorage.setItem("generateVideo", true);
 }*/
  //end new
  const formDataString = localStorage.getItem("getFormData");
  const user = JSON.parse(localStorage.getItem("user"));

  const getformData = formDataString ? JSON.parse(formDataString) : {};
  const scriptout = Cookies.get("scripttxt");
  const videoScript = getformData?.subformData?.question || getformData?.subformData?.userPrompt
  console.log("videoScriptvideoScript", videoScript)
  // console.log('location', location);
  // const { subFormData } = location.state || {};
  const navigate = useNavigate();
  // const items = null;
  //let items;
  const allImages = JSON.parse(localStorage.getItem("all_images"))?.filter(
    (item) => item?.url && item?.url?.trim() !== ""
  );
  const pexelVideos = JSON.parse(localStorage.getItem("pexel_videos"))?.filter(
    (item) => item.link && item.link.trim() !== ""
  );
  const [items, setitems] = useState(allImages || []);
  const [regeneratecount, setregeneratecount] = useState(0);
  const [generatecount, setGeneratecount] = useState(0);
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [addhoveredIndex, setAddHoveredIndex] = useState(false);
  const [ifImgIndex, setIfImgIndex] = useState(null);
  const [ifImgIndexText, setIfImgIndexText] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadAddProgress, setUploadAddProgress] = useState(null);
  const [regenProgress, setReGenProgress] = useState(null);
  const [regenerate, setRegenerate] = useState(null);
  const [regenerateLoader, setRegenerateLoader] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(null);
  const [uploadSelfLoader, setUploadSelfLoader] = useState(null);
  const [uploadSelfProgress, setUploadSelfProgress] = useState(null);
  const [uploadAddLoader, setUploadAddLoader] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timecompleted, settimeCompleted] = useState(false);
  const [firstRegenerate, setFirstRegenerate] = useState(false);
  const [hover, setHover] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [progress, setProgress] = useState(0);
  const [checkData, setCheckData] = useState();
  const [imageLimit, setImageLimit] = useState(false);
  const [imgvalid, setimgvalid] = useState("");
  const [animationKey, setAnimationKey] = useState(0);
  const [isModalPopOpen, setIsModalPopOpen] = useState(false);
  const [addAnotherImagePopup, setAddAnotherImagePopup] = useState(false);
  const [vidItems, setVidITems] = useState(pexelVideos || []);
  const [validatemsg, setvalidatemsg] = useState(false);
  const [showDetails, setShowDetails] = useState("");
  const [loadagain, setloadagain] = useState(false);
  const [showAiScript, setShowAiScript] = useState(false);
  const [showStockDataPopup, setShowStockDataPopup] = useState(false);
  const [stockImages, setStockImages] = useState([]);
  const [stockVideos, setStockVideos] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [isSelectedEmpty, setIsSelectedEmpty] = useState(false);
  const [isTabOpen, setIsTabOpen] = useState("image");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadCustomProgress, setUploadCustomProgress] = useState(0);
  const [isInitialModal, setIsInitialModel] = useState(false);
  const [showStockScreen, setShowStockScreen] = useState(true);
  const [warningText, setWarningText] = useState('');
  const [loaderText, setLoaderText] = useState('');
  const textareaRef = useRef(null);
  const tooltipRef = useRef(null);
  const addTovideoRef = useRef(null);
  const [chooseImageTooltip, setChooseImageTooltip] = useState(false);
  const [chooseImageTooltipid, setChooseImageTooltipid] = useState();
  const [stockDataSuccess, setStockDataSuccess] = useState(false);
  useEffect(() => {
    // Function to handle clicks outside of the div
    const handleClickOutside = (event) => {
      if (addTovideoRef.current && !addTovideoRef.current.contains(event.target)) {
        setIsSelectedEmpty(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addTovideoRef]);

  console.log({ allImages, isTabOpen });
  const openPopModal = () => setIsModalPopOpen(true);
  const closePopModal = () => setIsModalPopOpen(false);
  const getStockData = async () => {
   // cursortowait()
    try {
      const stockImageData = await getStockImages(
        searchInputRef.current.value
      );
      stockImageData && setStockImages(stockImageData);
      const stockVideoData = await getStockVideos(
        searchInputRef.current.value
      );
      stockVideoData?.length > 0 && setStockVideos(stockVideoData);
      console.log({ stockImageData, stockVideoData });
      searchInputRef.current.value = "";
      setStockDataSuccess(true)
    } catch (error) {
      console.log({ error });
    }
  //  cursortodefault()
  };
  useEffect(() => {
    if (allImages?.length > 0) {
      setitems(allImages);
      setShowStockScreen(false)
    }

  }, []);

  const toggleItemSelection = (item) => {
    setSelectedContent((prevSelectedImages) => {
      if (prevSelectedImages.length + items.length == 5) {
        // If already 5 selected, allow removing but not adding
        setChooseImageTooltip(true);
        setChooseImageTooltipid(item.id);
        return prevSelectedImages.filter(
          (selectedImage) => selectedImage.id !== item.id
        );
      } else {
        setChooseImageTooltip(false);
        // Add or remove item based on current selection state
        return prevSelectedImages.some(
          (selectedImage) => selectedImage.id === item.id
        )
          ? prevSelectedImages.filter(
            (selectedImage) => selectedImage.id !== item.id
          )
          : [
            ...prevSelectedImages,
            { ...item, url: item.link, link: undefined },
          ];
      }
    });
  };
  console.log({ "sc": selectedContent, lenth: selectedContent.length });
  const [keyword, setKeyword] = useState('');
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getStockData();
    }
  }
  const handleSearch = async () => {
   // cursortowait()
    try {
      // Call your API here with the payload
      const payload = {
        keyword: searchInputRef.current.value,
        type: isTabOpen
      };
      await getStockData(
        searchInputRef.current.value
      );
      setTimeout(() => cursortodefault(), 2000);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleGenerate = () => {

    const getformData = JSON.parse(localStorage.getItem("getFormData"));
    const subformData = getformData?.subformData
    navigate("/dashboard", {
        state: {
            subFormData: subformData,
            call: true,
        },
    });
    localStorage.setItem("generateVideo", true);
}
  const handleClear = () => {
    setKeyword('');
  };

  function getFileExtension(fileName) {
    // return fileName
    // Use a regex pattern to find the extension
    var pattern = /\.([a-zA-Z0-9]+)$/;
    var match = fileName.match(pattern);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }
  const isFileSizeValid = (file) => {
    // const maxSizeInBytes = 3 * 1024 * 1024;
    const maxSizeInBytes = 10 * 1024 * 1024;
    return file?.size <= maxSizeInBytes;
  };

  const handleStockAddFileChange = async (event) => {

    // cursortowait();
    // setUploadSelfProgress
    // const allowedTypes = ["jpg", "jpeg", "png", "webp", "avif"];
    const allowedTypes = ["jpg", "jpeg", "png", "webp", "avif", "mp4"];
    const file = event.target?.files[0];
    const filSize = await isFileSizeValid(file)
    if (!filSize) {
      toast.error(`Please upload files under 3MB. Thanks!`);
      return;
    }
    const extension = getFileExtension(file?.name);
    console.log("file?.name", file, file?.name)
    if (file && allowedTypes.includes(extension)) {
      setUploadSelfLoader(true)
      setFirstRegenerate(false)
      setUploadSelfProgress(0);
      setShowStockScreen(false)
      setLoaderText('We are uploading your media. Give us 15 seconds.')
      const file = event.target?.files[0];
      // console.log('fileee', file);
      console.log("nisaldhpowe", file);
      let data = new FormData();
      data.append("image", file);
      // // const imageResp = await uploadImageGenerate(data);
      const imageResp = await uploadImageGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 70;
        setUploadSelfProgress(progress);
      });
      if (imageResp?.url) {
        user?.data?.ID && (await mixpanel.identify(user?.data.ID));
        await mixpanel.track("Upload my own image", {
          Email: user?.data.user_email,
          "User Id": user?.data.ID || "",
          "User Type": user?.orders.order_type || "guest",
          "User Image": imageResp?.url,
        });

        setTimeout(() => {
          setitems([...items, imageResp]);

          localStorage.setItem(
            "all_images",
            JSON.stringify([...items, imageResp])
          );
          setStockImages([])
          setStockVideos([])
          setStockDataSuccess(false)
        }, 2000)
        setUploadSelfLoader(false)
        setFirstRegenerate(true)
        setUploadSelfProgress(null);
        setLoaderText('')


      } /* extension IF ends */ else {
        console.log(
          "File type not allowed 1. Please select a JPG, PNG, or WEBP file."
        );
        //alert("You can upload only png , jpg , webp");
        // document.getElementById("wrongfiletypemsg").style.display = "flex";
        setWarningText('File type not allowed. Please select a JPG, PNG, or WEBP file.')
        setTimeout(() => {
          setWarningText('')
        }, 5000);
      }
    } else {
      toast.error('File type not allowed. Please select a JPG, PNG, or WEBP file.')
    }
    // cursortodefault();
  }


  const handleAddFileChange = async (event) => {
    // const allowedTypes = ["jpg", "jpeg", "png", "webp", "mp4", "webm", "avif"];
    // const allowedTypes = ["jpg", "jpeg", "png", "webp","avif"];
    const allowedTypes = ["jpg", "jpeg", "png", "webp", "avif", "mp4"];
    // const allowedTypes = ["jpg", "jpeg", "png", "avif"];
    const file = event.target?.files[0];
    const filSize = await isFileSizeValid(file)
    if (!filSize) {
      setWarningText(`Please upload files under 3MB. Thanks!`);
      setTimeout(() => {
        setWarningText('');
      }, 5000);
      return;
    }
    var extension = getFileExtension(file?.name);

    setshow2btns(false);
    setrestpage(true);



    if (items?.length < 6) {
      if (allowedTypes.includes(extension)) {
        setUploadAddLoader(true);
        setUploadAddProgress(0);
        const file = event.target?.files[0];
        // console.log('fileee', file);
        console.log("nisaldhpowe", file);
        let data = new FormData();
        data.append("image", file);
        // // const imageResp = await uploadImageGenerate(data);
        const imageResp = await uploadImageGenerate(data, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadAddProgress(progress);
        });
        user?.data?.ID && (await mixpanel.identify(user?.data.ID));
        await mixpanel.track("Upload my own image", {
          Email: user?.data.user_email,
          "User Id": user?.data.ID || "",
          "User Type": user?.orders.order_type || "guest",
          "User Image": imageResp?.url,
        });
        // const imgData = {
        //   url: imageResp.url,
        //   type: 'upload'
        // }
        // ([...items, imgData]);
        console.log(ifImgIndex + "selected");
        setTimeout(() => {
          if (ifImgIndex) {

            setUploadLoader(ifImgIndex - 1);
            const updatedImages = [...items];
            updatedImages[ifImgIndex - 1] = imageResp;
            setitems(updatedImages);
          } else {
            setitems([...items, imageResp]);
          }
          setIfImgIndex(null);
          setIfImgIndexText(null);

          localStorage.setItem(
            "all_images",
            JSON.stringify([...items, imageResp])
          );
          Cookies.set("scripttxt", videoScript, {
            path: "/",
            domain: ".yatter.ai",
          });
          Cookies.set("scripttxt", videoScript);
          setUploadAddProgress(null);

          // setUploadAddLoader(false)
          setUploadAddLoader(null);
          // if (file) {
          //   const reader = new FileReader();
          //   reader.onloadend = () => {
          //     // Store the Base64 string in local storage
          //     console.log('reader.result', reader.result)
          //     localStorage.setItem("uploadedImage", reader.result);
          //   };
          //   reader.readAsDataURL(file);
          // }
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const dbRequest = indexedDB.open("fileStorage", 1);
              dbRequest.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains("files")) {
                  db.createObjectStore("files", { keyPath: "id" });
                }
              };

              dbRequest.onsuccess = (event) => {
                const db = event.target.result;
                const transaction = db.transaction(["files"], "readwrite");
                const objectStore = transaction.objectStore("files");
                const fileData = { id: "uploadedImage", data: reader.result };

                const request = objectStore.put(fileData);
                request.onsuccess = () => {
                  console.log("File stored successfully in IndexedDB");
                };
                request.onerror = (err) => {
                  console.error("Error storing file in IndexedDB", err);
                };
              };

              dbRequest.onerror = (err) => {
                console.error("Error opening IndexedDB", err);
              };
            };
            reader.readAsDataURL(file);
          }
        }, 2000)
      } /* extension IF ends */ else {
        console.log(
          "File type not allowed 1. Please select a JPG, PNG, or WEBP file."
        );
        //alert("You can upload only png , jpg , webp");
        // document.getElementById("wrongfiletypemsg").style.display = "flex";
        setWarningText('File type not allowed. Please select a JPG, PNG, or WEBP file.')
        setTimeout(() => {
          // document.getElementById("wrongfiletypemsg").style.display = "none";
          setWarningText('')
        }, 5000);
      }
    } else {
      setImageLimit(true);
    }
    const fileinputfieldingElement = document.getElementById("fileinputfielding");
    if (fileinputfieldingElement) {
      fileinputfieldingElement.value = ""
    }
    cursortodefault();
    setStockImages([])
    setStockVideos([])
    setStockDataSuccess(false)
  };

  const handleFileChangesss = (index) => async (event) => {
    // const allowedTypes = ["jpg", "jpeg", "png", "webp"];
    const allowedTypes = ["jpg", "jpeg", "png", "webp", "mp4"];
    const file = event.target.files[0];
    const filSize = await isFileSizeValid(file)
    if (!filSize) {
      setWarningText(`Please upload files under 3MB. Thanks!`);
      setTimeout(() => {
        setWarningText('');
      }, 5000);
      return;
    }
    var extension = getFileExtension(file.name);

    if (allowedTypes.includes(extension)) {
      setUploadProgress(null);
      setUploadLoader(index);
      let data = new FormData();
      data.append("image", file);
      const imageResp = await uploadImageGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      });
      const updatedImages = [...items];
      // const imgData = {
      //   url: imageResp.url,
      //   type: 'upload'
      // }
      updatedImages[index] = imageResp;
      // updatedImages[index] = imgData;
      setitems(updatedImages);
      localStorage.setItem("all_images", JSON.stringify(updatedImages));
      Cookies.set("scripttxt", videoScript, {
        path: "/",
        domain: ".yatter.ai",
      });

      Cookies.set("scripttxt", videoScript);

      setUploadLoader(null);
      setUploadProgress(null);
      // setUploadLoader(false)
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Store the Base64 string in local storage
          localStorage.setItem("uploadedImage", reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      console.log(
        "File type not allowed. Please select a JPG, PNG, or WEBP file."
      );
      // alert("You can upload only png , jpg , webp");
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    handleMouseAddLeave();
  };

  const handleMouseLeave = (index) => {
    // if (uploadLoader === null) {
    setHoveredIndex(false);
    // }
  };

  const handleMouseAddEnter = () => {
    if (uploadAddLoader != null || uploadAddProgress === 100) {
      setAddHoveredIndex(true);
    } else {
      setAddHoveredIndex(true);
    }
    handleMouseLeave();
  };

  const handleMouseAddLeave = () => {
    if (uploadAddLoader === null) {
      setAddHoveredIndex(false);
    }
  };

  const cliearImageHover = (index) => {
    handleMouseLeave();
    handleMouseAddLeave();
  };

  function navigateToVideo(items) {
    navigate("/get-audio", { state: { items } });
  }

  function formatMilliseconds(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${paddedSeconds}`;
  }
  // console.log('iteenn', items?.[0]);
  var forreadyimage = "";

  async function handleRegenerate(indexData) {
    if (items.length <= 6) {
      setRegenerateLoader(indexData);
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        setReGenProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = totalDuration - elapsedTime;
        const calculatedTimeRemaining = formatMilliseconds(
          calculatedTimeRemaining1
        );

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {
          console.log("forreadyimage");
          console.log("forreadyimage");
          if (forreadyimage == "") {
            console.log("data not ready");
            elapsedTime = 0;
            setRegenerateLoader(null);
          } else {
            setReGenProgress(100);
            clearInterval(interval);
            settimeCompleted(true);
            setReGenProgress(100);
            setRegenerateLoader(null);
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }
        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        question: getformData.subformData.question,
        qabout: textAreaValue,
        mobileimage: localStorage.getItem("mobileImg"),
      };

      try {
        // const response = await generateEssay(formData);
        // setProgress(0);
        // console.log('too much 2');
        // console.log(formData);
        // const response = await generateImage(formData);
        const response = await generateImageSingle(formData);
        // const response = await generateImage(location.state.subFormData);
        // const response = await generateImage(location.state.subFormData, (progressEvent) => {
        //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
        //   console.log('porrr', progress);
        //   setReGenProgress(progress);
        // });
        // const response = await generateImage(location.state.subFormData);
        // const response = await maskImage(formData);
        // return console.log('resp', response);
        //console.log("in app");
        //console.log(response);
        console.log("respnosessss", response);
        if (response) {
          // setLoading(false); // Start loading
          // navigate('/GetImages', { state: { response } });
          // return setImage(response);
          // (response);
          // const newImageData = items[indexData] = response;
          // const imagData = {
          //   url: response?.[0],
          //   type: "generate"
          // }
          const data = {
            images: response,
          };
          console.log(data);
          // setregeneratecount(prevCount => prevCount + 1);
          const get_response = await getGenerateImage(data);
          console.log("get_response", get_response);
          const updatedImages = [...items];
          // updatedImages[indexData] = response?.[0];
          updatedImages[indexData] = get_response?.images;

          // updatedImages[indexData] = imagData;
          // (updatedImages);
          // console.log(updatedImages);
          // const updatedImages = items.map((item, index) => (
          //   index === indexData ? response : item
          // ));

          // // Update the state with the modified array
          // (updatedImages);
          // setReGenProgress(null)
          // console.log('in items response', updatedImages);
          setregeneratecount((prevCount) => prevCount + 1);
          forreadyimage = get_response?.images;
          //  setLoading(false); // Start loading
          // forreadyimage = response;
          let timerem = totalDuration - elapsedTime;
          // console.log(timerem);

          setitems(updatedImages);
          localStorage.setItem("all_images", JSON.stringify(updatedImages));

          // setTimeout(() => {
          //     navigate('/GetImages', {
          //         state: {
          //             response: response, // your response data
          //             subFormData: subformData // your additional data
          //         }
          //     });
          // }, timerem); // 2000 milliseconds = 2 seconds
          //console.log(items);
          /* items=response;
           console.log('in items respnose');
           console.log(items);
           */
        } else {
          // setReGenProgress(null)
          return console.log("error in regenerate");
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error("Error handling form submission:", error);
      }
    } else {
      setImageLimit(true);
    }
  }

  async function handleFirstRegenerate() {
    /* closeImagePopModal();
    setMobileImg(MobileImgValue);
    console.log ("mibileimage");
    console.log (MobileImgValue);
    console.log (mobileImg);
     localStorage.removeItem("mobileImg");
    localStorage.setItem("mobileImg", MobileImgValue);*/
    if (!videoScript) {
      navigate("/");
      toast.error("Please type your video idea.");
      return;
    }
    console.log("i have entered successfully");
    localStorage.removeItem("imagePageState");
    setUploadSelfLoader(true);
    setFirstRegenerate(false);
    setLoaderText('We are getting amazing footage for your video. Give us 15 seconds.')
    //  document.getElementById("msgbottom5images").style.display = "none";
    // console.log('index', indexData);
    const totalDuration = 60000;
    // const totalDuration = 150000;
    let elapsedTime = 0;
    const interval = setInterval(() => {
      elapsedTime += 1000;
      const calculatedProgress = (elapsedTime / totalDuration) * 100;
      setUploadSelfProgress(calculatedProgress);

      //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
      const calculatedTimeRemaining1 = totalDuration - elapsedTime;
      const calculatedTimeRemaining = formatMilliseconds(
        calculatedTimeRemaining1
      );

      setTimeRemaining(calculatedTimeRemaining);

      if (elapsedTime >= totalDuration) {
        // console.log('forreadyimage');
        // console.log('forreadyimage');
        if (forreadyimage == "") {
          console.log("data not ready");
          setvalidatemsg(true);
          setloadagain(true);
          elapsedTime = 0;
          // setRegenerateLoader(null);
          setUploadSelfLoader(true);
          setWarningText('Not working? Remove special characters that are not used often like these: /\/;:~')
        } else {
          // setReGenProgress(100);
          setUploadSelfProgress(100);
          clearInterval(interval);
          settimeCompleted(true);
          // setReGenProgress(100);
          setUploadSelfProgress(100);
          // setRegenerateLoader(null)
          setUploadSelfLoader(false);
          setUploadSelfLoader(null);
          setLoaderText('')
          handleResponse();
          //  navigate('/GetImages', { state: { forreadyvideo } });
        }
      }
    }, 1000);
    // setLoading(true); // Start loading
    const formData = {
      question: videoScript,
      mobileimage: localStorage.getItem("mobileImg"),
    };
    console.log("formDataformData in images", formData);
    try {
      const responseData = await getPexelsvideoApi(formData?.question);
      // const response = await generateImagedStb(formData);
      // ([]);
      console.log("respnoses", responseData, "videoResponse");
      if (responseData) {
        // ([...items, response]);
        // ([response]);
        const response = responseData?.map((item) => ({
          ...item,
          url: item?.link,
          link: undefined,
        }));
        const videoResponse = responseData?.filter(
          (item) => item.type === "video"
        );
        const data = {
          images: response,
        };
        console.log(data);
        // setregeneratecount(prevCount => prevCount + 1);
        // const get_response = await getGenerateImage(data);
        const get_response = data;
        // console.log("get_response", get_response);
        console.log("get_response", get_response);
        setGeneratecount(3);
        forreadyimage = get_response?.images;
        let timerem = totalDuration - elapsedTime;
        // console.log('forreadyimage', forreadyimage);
        console.log("forreadyimage", forreadyimage?.images);

        // (updatedImages);
        // if (timeRemaining===100) {
        // ([...items, response?.[0]]);
        // }
        console.log("videoResponse", videoResponse);
        localStorage.setItem("pexel_videos", JSON.stringify(videoResponse));
        setVidITems(videoResponse);
        setUploadSelfProgress(100);
        clearInterval(interval);
        settimeCompleted(true);
        // setReGenProgress(100);
        setUploadSelfProgress(100);
        // setRegenerateLoader(null)
        setUploadSelfLoader(false);
        setUploadSelfLoader(null);
        setIsInitialModel(true)
        handleResponse();

        // console.log(timeRemaining);
      } else {
        // setReGenProgress(null)
        return console.log("error in regenerate");
      }
    } catch (error) {
      // setReGenProgress(null)
      console.error("Error handling form submission:", error);
    }

    function handleResponse() {
      // const imagData = {
      //   url: forreadyimage?.[0].url,
      //   type: "generate"
      // }
      // const updatedImages = [...items, imagData];
      // const updatedImages = forreadyimage.map(image => ({
      //   ...image,
      //   type: 'generate'
      // }));
      const updatedImages = items ? [...items, forreadyimage] : [forreadyimage];
      const newItems = updatedImages.flat();
      setWarningText('')
      // document.getElementById("msgbottomimage").style.display = "none";
      // document.getElementById("msgbottom5images").style.display = "flex";
      setitems(newItems);
      localStorage.setItem("all_images", JSON.stringify(newItems));
      // console.log('fore', forreadyimage);
      // return console.log('responsefirst', newItems);
    }
    // console.log('newItems', items);

    // return console.log('check', getformData.subformData.question);
  }

  async function handleRegenerate() {
    if (generatecount < 5 && items.length < 5) {
      setUploadAddLoader(true);
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        setUploadAddProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = totalDuration - elapsedTime;
        const calculatedTimeRemaining = formatMilliseconds(
          calculatedTimeRemaining1
        );

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {
          // console.log('forreadyimage');
          // console.log('forreadyimage');
          if (forreadyimage == "") {
            console.log("data not ready");
            elapsedTime = 0;
            setUploadAddLoader(true);
          } else {
            // setReGenProgress(100);
            setUploadAddProgress(100);
            clearInterval(interval);
            settimeCompleted(true);
            // setReGenProgress(100);
            setUploadAddProgress(100);
            // setRegenerateLoader(null)
            // setUploadAddLoader(false)
            setUploadAddLoader(null);
            // setUploadAddProgress(null)
            handleResponse();
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }
        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        // question: location.state.subFormData.question,
        question: getformData.subformData.question,
        mobileimage: localStorage.getItem("mobileImg"),
        // qabout: getformData.subformData.qabout
      };

      try {
        const response = await generateImageSingle(formData);

        console.log("respnosessss_gene", response);
        if (response) {
          const data = {
            images: response,
          };
          console.log("gene_data", data);
          // ([...items, response]);
          const get_response = await getGenerateImage(data);
          // console.log('getrespnosessss_gene', get_response);
          if (generatecount === 4) {
            setregeneratecount((prevCount) => prevCount + 1);
          }

          setGeneratecount((prevCount) => prevCount + 1);
          forreadyimage = get_response?.images;
          // forreadyimage = response;
          //  setLoading(false); // Start loading
          // forreadyimage = response;
          let timerem = totalDuration - elapsedTime;
          console.log("forreadyimage", forreadyimage);

          // (updatedImages);
          // if (timeRemaining===100) {
          // ([...items, response?.[0]]);
          // }

          console.log(timeRemaining);
        } else {
          // setReGenProgress(null)
          return console.log("error in regenerate");
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error("Error handling form submission:", error);
      }

      function handleResponse() {
        // const imagData = {
        //   url: forreadyimage?.[0]?.url,
        //   type: "generate"
        // }
        // const updatedImages = [...items, imagData];
        console.log("abchhs");
        const updatedImages = [...items, forreadyimage?.[0]];
        setitems(updatedImages);
        localStorage.setItem("all_images", JSON.stringify(updatedImages));
        setUploadAddProgress(null);
        console.log("forreadyimage", forreadyimage);
      }
    } else {
      setImageLimit(true);
    }
  }
  const videoExtensions = [".mp4", ".webm", ".avi", ".mov", ".mkv", ".flv"];
  async function handleSelfRegenerate(indexData, userText) {
    if (generatecount < 9) {
      setUploadLoader(indexData);
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        // setReGenProgress(calculatedProgress);
        setUploadProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = totalDuration - elapsedTime;
        const calculatedTimeRemaining = formatMilliseconds(
          calculatedTimeRemaining1
        );

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {
          console.log("forreadyimage");
          console.log("forreadyimage");
          if (forreadyimage == "") {
            console.log("data not ready");
            elapsedTime = 0;
            //   setRegenerateLoader(null);
            // setUploadLoader(null)
            setUploadLoader(indexData);
          } else {
            // setReGenProgress(100);
            setUploadProgress(100);
            clearInterval(interval);
            settimeCompleted(true);
            // setReGenProgress(100);
            setUploadProgress(100);
            // setRegenerateLoader(null)
            setUploadLoader(null);
            handleResponse();
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }
        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        question: document.getElementById("regenerate-text")?.value || userText,
        mobileimage: localStorage.getItem("mobileImg"),
        //question: textAreaValue,
        // qabout: getformData.subformData.question
      };
      console.log(formData);
      console.log(textAreaValue);
      try {
        const response = await generateImageSingle(formData);
        // const response = await generateImage(location.state.subFormData);
        // const response = await generateImage(location.state.subFormData, (progressEvent) => {
        //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
        //   console.log('porrr', progress);
        //   setReGenProgress(progress);
        // });
        // const response = await generateImage(location.state.subFormData);
        // const response = await maskImage(formData);
        // return console.log('resp', response);
        //console.log("in app");
        //console.log(response);
        console.log("respnose_regen", response);
        if (response) {
          // setLoading(false); // Start loading
          // navigate('/GetImages', { state: { response } });
          // return setImage(response);
          // (response);
          // const newImageData = items[indexData] = response;

          // (updatedImages);
          // console.log(updatedImages);
          // const updatedImages = items.map((item, index) => (
          //   index === indexData ? response : item
          // ));

          // // Update the state with the modified array
          // (updatedImages);
          // setReGenProgress(null)
          // console.log('in items response', updatedImages);
          const data = {
            images: response,
          };
          console.log("gene_data", data);
          // ([...items, response]);
          const get_response = await getGenerateImage(data);
          setGeneratecount((prevCount) => prevCount + 1);
          if (generatecount === 4) {
            setregeneratecount((prevCount) => prevCount + 1);
          } else {
            setregeneratecount((prevCount) => prevCount + 1);
          }
          forreadyimage = get_response?.images;
          //  setLoading(false); // Start loading
          // forreadyimage = response;
          let timerem = totalDuration - elapsedTime;

          // setTimeout(() => {
          //     navigate('/GetImages', {
          //         state: {
          //             response: response, // your response data
          //             subFormData: subformData // your additional data
          //         }
          //     });
          // }, timerem); // 2000 milliseconds = 2 seconds
          //console.log(items);
          /* items=response;
           console.log('in items respnose');
           console.log(items);
           */
        } else {
          // setReGenProgress(null)
          return console.log("error in regenerate");
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error("Error handling form submission:", error);
      }

      function handleResponse() {
        // const imagData = {
        //   url: forreadyimage?.[0]?.url,
        //   type: "generate"
        // }
        const updatedImages = [...items];
        // updatedImages[indexData] = imagData;
        updatedImages[indexData] = forreadyimage?.[0];
        // const updatedImages = [...items, forreadyimage?.[0]];
        setitems(updatedImages);
        localStorage.setItem("all_images", JSON.stringify(updatedImages));
        setUploadProgress(null);
        console.log("forreadyimage", forreadyimage);
      }
    }
    // else {
    //   setregeneratecount(prevCount => prevCount + 1);
    // }

    // setRegenerateLoader(indexData)
  }

  const handleTextAreaChange = (e) => {
    // Step 2
    setTextAreaValue(e.target.value);
  };

  const handleTrashClick = (indexToRemove) => {
    console.log("Trash button clicked");
    console.log(items.length);

    let whichelempopup = "delbuttontooltip-" + indexToRemove;
    console.log(whichelempopup);
    let whichdelbtn = "delbutton-" + indexToRemove;
    console.log(whichdelbtn);
    const removeSelectedItem = items[indexToRemove]
    if (items.length > 1) {
      const filteredItems = items?.filter(
        (item, index) => index !== indexToRemove
      );

      if (selectedContent.length > 0) {
        const filteredselectedContent = selectedContent?.filter(
          (item, index) => item?.id !== removeSelectedItem?.id
        );
        setSelectedContent(filteredselectedContent);
      }

      setitems(filteredItems);
      localStorage.setItem("all_images", JSON.stringify(filteredItems));
      if (items.length === 0) {
        setFirstRegenerate(false);
      }
      // Add your trash click handling logic here
    } else {
      document.getElementById(whichelempopup).style.display = "block";
      document.getElementById(whichdelbtn).classList.add("delcursorbtn");
      /*  setTimeout(function() {
        document.getElementById(whichelempopup).style.display="none";
        document.getElementById(whichdelbtn).classList.remove('delcursorbtn');
    }, 3000);*/
    }
  };

  const handleModal = (items) => {
    if (items.length >= 3) {
      return openPopModal();
    } else {
      console.log("bkjd");
      setGenerate(true);
      setAnimationKey((prevKey) => prevKey + 1);
      // Remove the generate state after 3 seconds
      // setTimeout(() => {
      //   setGenerate(false);
      // }, 6000);
    }
  };
  // const handleContinue = async (items) => {

  //   // if (items?.length >= 3) {
  //   if (items?.length >= 1) {
  //     // const captiondata = { captionData: location.state.caption }
  //     // const updatedValue = { ...items, captionData: getformData?.caption || location.state.caption };
  //     let updatedItems = items?.map((item) => {
  //       if (item.url && item.url.trim() !== "") {
  //         return {
  //           ...item,
  //           type: videoExtensions?.some((ext) => item?.url.includes(ext))
  //             ? "video"
  //             : "image",
  //         };
  //       }
  //       return item;
  //     });
  //     updatedItems = updatedItems?.filter(
  //       (item) => item.url && item.url.trim() !== ""
  //     );
  //     console.log("updatedItemstype", updatedItems);
  //     const updatedValue = { ...updatedItems };
  //     const stateValue = { ...updatedItems, firstRegenerate, generatecount };
  //     localStorage.setItem("imagePageState", JSON.stringify(stateValue));
  //     localStorage.setItem("all_images", JSON.stringify(updatedItems));
  //     // let updatedValue = []
  //     // updatedValue = [...items, location.state.caption]
  //     // updatedValue.push(items, location.state.caption);
  //     // updatedValue.push(items, { captionData: location.state.caption });
  //     console.log("smpow", updatedValue);
  //     user?.data?.ID && (await mixpanel.identify(user?.data.ID));
  //     await mixpanel.track("Choosing Image - Video Creation Step 2", {
  //       Email: user?.data.user_email,
  //       "User Id": user?.data.ID || "",
  //       "User Type": user?.orders.order_type || "guest",
  //     });
  //     if (user) {
  //       navigateToVideo(updatedValue);
  //       closePopModal();
  //     } else {
  //       const newItems = [...items]
  //       const numericKeys = Object.keys(newItems).filter((key) => !isNaN(Number(key)));
  //       const newKey =
  //         numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
  //       let newlink = { audio: "https://app.yatter.ai/webs/staticcontent/echo.mp3" };
  //       newItems[newKey] = newlink;
  //       const data = {
  //         bgMusicData: {
  //           music_name: "https://app.yatter.ai/webs/staticcontent/backmusic8.mp3",
  //           music_switch: true,
  //           Volume: 25,
  //           music_name_custom: "",
  //         },
  //         white_particles: false,
  //         voice: true,
  //         Caption: {
  //           caption_switch: true,
  //           caption_color: "&HFFFFFF&",
  //           caption_align: "Bottom",
  //         },
  //         MobileVideo: localStorage.getItem("mobileImg"),
  //         Scripted: { text: videoScript },
  //         wpuser: {
  //           id: user?.ID,
  //           useremail: user?.data?.user_email,
  //           usertype: user?.orders?.order_type,
  //         },
  //         businessInfo: {
  //           image: '',
  //           email: '',
  //           number: '',
  //           website: ''
  //         }
  //       }

  //       const updatedData = { ...newItems, ...data };
  //       navigate("/dashboard", { state: { items: updatedData, call: true } });
  //     }
  //     // const updatedData = { ...items, location.state.caption };
  //     // navigateToVideo(items)
  //   } else {
  //     console.log("bkjd");
  //     setGenerate(true);
  //     setAnimationKey((prevKey) => prevKey + 1);
  //     // Remove the generate state after 3 seconds
  //     // setTimeout(() => {
  //     //   setGenerate(false);
  //     // }, 6000);
  //   }
  // };




  const mobileimage = localStorage.getItem("mobileImg")
  const handleContinue = async (items) => {
    
    localStorage.setItem("upitems",JSON.stringify(items));
    localStorage.setItem("upitemscount",items.length);


    const getformData = JSON.parse(localStorage.getItem("getFormData"));
    const subformData = getformData?.subformData
    navigate("/dashboard", {
      state: {
          subFormData: subformData,
          call: true,
      },
  });

  localStorage.setItem("generateVideo", true);
  return;


  /*  console.log("items are ", items);
    return;*/



    cursortowait();
    if (items.length < 5) {
      const sendData = {
        textscript: getformData?.subformData?.question,
        mobileimage: mobileimage,
        remimages: 5 - items?.length
      }
      console.log('check1', items, sendData);
      const getImageResponse = await getRemainingImages(sendData)
      console.log('check_responseS', typeof (getImageResponse));
      console.log('check_response', getImageResponse);
      // const imageData = getImageResponse.map(JSON.parse);
      // console.log('check-responseimagw', typeof (imageData));
      if (getImageResponse) {

        console.log('check_response_success', getImageResponse);
        // const newItems = itemssss?.map(entry => ({
        const newItems = getImageResponse?.map(entry => ({
          url: entry?.data?.url,
          type: entry?.data?.type
        }));
        console.log('newItems', newItems);
        setitems(prevItems => [...prevItems, ...newItems]);
        const newDataArray = [
          ...items,
          ...getImageResponse.map(item => ({
            url: item.data.url,
            type: item.data.type
          }))
        ];
        // console.log('newDataArray', newDataArray);
        // setTimeout(handleGenerateVideo(), 5000)
        handleGenerateVideo(newDataArray)

      } else {
        console.log('check_response_error', getImageResponse);
      }
      cursortodefault();
      return console.log('sendData', sendData);
    }
    else if (items.length === 5) {
      // return console.log('check2');
      // const captiondata = { captionData: location.state.caption }
      // const updatedValue = { ...items, captionData: getformData?.caption || location.state.caption };
      let updatedItems = items?.map((item) => {
        if (item.url && item.url.trim() !== "") {
          return {
            ...item,
            type: videoExtensions?.some((ext) => item?.url.includes(ext))
              ? "video"
              : "image",
          };
        }
        return item;
      });
      updatedItems = updatedItems?.filter(
        (item) => item.url && item.url.trim() !== ""
      );
      console.log("updatedItemstype", updatedItems);
      const updatedValue = { ...updatedItems };
      const stateValue = { ...updatedItems, firstRegenerate, generatecount };
      localStorage.setItem("imagePageState", JSON.stringify(stateValue));
      localStorage.setItem("all_images", JSON.stringify(updatedItems));
      // let updatedValue = []
      // updatedValue = [...items, location.state.caption]
      // updatedValue.push(items, location.state.caption);
      // updatedValue.push(items, { captionData: location.state.caption });
      console.log("smpow", updatedValue);
      user?.data?.ID && (await mixpanel.identify(user?.data.ID));
      await mixpanel.track("Choosing Image - Video Creation Step 2", {
        Email: user?.data.user_email,
        "User Id": user?.data.ID || "",
        "User Type": user?.orders.order_type || "guest",
      });
      const newItems = [...items]
      const numericKeys = Object.keys(newItems).filter((key) => !isNaN(Number(key)));
      const newKey =
        numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
      let newlink = { audio: "https://app.yatter.ai/webs/staticcontent/echo.mp3" };
      newItems[newKey] = newlink;
      const data = {
        bgMusicData: {
          music_name: "https://app.yatter.ai/webs/staticcontent/backmusic8.mp3",
          music_switch: true,
          Volume: 25,
          music_name_custom: "",
        },
        white_particles: false,
        voice: true,
        Caption: {
          caption_switch: true,
          caption_color: "&HFFFFFF&",
          caption_align: "Bottom",
        },
        MobileVideo: localStorage.getItem("mobileImg"),
        Scripted: { text: videoScript },
        wpuser: {
          id: user?.ID,
          useremail: user?.data?.user_email,
          usertype: user?.orders?.order_type,
        },
        businessInfo: {
          image: '',
          email: '',
          number: '',
          website: ''
        }
      }

      const updatedData = { ...newItems, ...data };
      console.log('updateee', updatedData);
      navigate("/dashboard", {
        state:
        {
          items: updatedData,
          call: true
        }

      });
      localStorage.setItem("generateVideo", true);
      // const updatedData = { ...items, location.state.caption };
      // navigateToVideo(items)

    } else {
      return console.log('check3');
      console.log("bkjd");
      setGenerate(true);
      setAnimationKey((prevKey) => prevKey + 1);
      // Remove the generate state after 3 seconds
      // setTimeout(() => {
      //   setGenerate(false);
      // }, 6000);
    }
    // if (items?.length >= 3) {

  };
  const handleGenerateVideo = async (items) => {
    console.log('handleGenerateVideo', items);
    if (items?.length === 5) {
      console.log('handleGenerateVideo1');
      // const captiondata = { captionData: location.state.caption }
      // const updatedValue = { ...items, captionData: getformData?.caption || location.state.caption };
      let updatedItems = items?.map((item) => {
        if (item.url && item.url.trim() !== "") {
          return {
            ...item,
            type: videoExtensions?.some((ext) => item?.url.includes(ext))
              ? "video"
              : "image",
          };
        }
        return item;
      });
      updatedItems = updatedItems?.filter(
        (item) => item.url && item.url.trim() !== ""
      );
      console.log("updatedItemstype", updatedItems);
      const updatedValue = { ...updatedItems };
      const stateValue = { ...updatedItems, firstRegenerate, generatecount };
      localStorage.setItem("imagePageState", JSON.stringify(stateValue));
      localStorage.setItem("all_images", JSON.stringify(updatedItems));
      // let updatedValue = []
      // updatedValue = [...items, location.state.caption]
      // updatedValue.push(items, location.state.caption);
      // updatedValue.push(items, { captionData: location.state.caption });
      console.log("smpow", updatedValue);
      user?.data?.ID && (await mixpanel.identify(user?.data.ID));
      await mixpanel.track("Choosing Image - Video Creation Step 2", {
        Email: user?.data.user_email,
        "User Id": user?.data.ID || "",
        "User Type": user?.orders.order_type || "guest",
      });
      const newItems = [...items]
      const numericKeys = Object.keys(newItems).filter((key) => !isNaN(Number(key)));
      const newKey =
        numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
      let newlink = { audio: "https://app.yatter.ai/webs/staticcontent/echo.mp3" };
      newItems[newKey] = newlink;
      const data = {
        bgMusicData: {
          music_name: "https://app.yatter.ai/webs/staticcontent/backmusic8.mp3",
          music_switch: true,
          Volume: 25,
          music_name_custom: "",
        },
        white_particles: false,
        voice: true,
        Caption: {
          caption_switch: true,
          caption_color: "&HFFFFFF&",
          caption_align: "Bottom",
        },
        MobileVideo: localStorage.getItem("mobileImg"),
        Scripted: { text: videoScript },
        wpuser: {
          id: user?.ID,
          useremail: user?.data?.user_email,
          usertype: user?.orders?.order_type,
        },
        businessInfo: {
          image: '',
          email: '',
          number: '',
          website: ''
        }
      }

      const updatedData = { ...newItems, ...data };
      console.log('updateee', updatedData);
      navigate("/dashboard", {
        state:
        {
          items: updatedData,
          call: true
        }

      });
      localStorage.setItem("generateVideo", true);
      // const updatedData = { ...items, location.state.caption };
      // navigateToVideo(items)

    } else {
      return console.log('check3');
      console.log("bkjd");
      setGenerate(true);
      setAnimationKey((prevKey) => prevKey + 1);
      // Remove the generate state after 3 seconds
      // setTimeout(() => {
      //   setGenerate(false);
      // }, 6000);
    }
    // if (items?.length >= 3) {

  };


  useEffect(() => {
    const scriptout = Cookies.get("scripttxt");
    console.log("SABDGSQ", scriptout);
    const savedStateString = localStorage.getItem("imagePageState");
    console.log("Raw savedState from localStorage:", savedStateString);

    if (savedStateString) {
      setCheckData(savedStateString);
      try {
        // Parse the JSON string to an object
        const savedStateObject = JSON.parse(savedStateString);
        console.log("Parsed savedStateObject:", savedStateObject);

        // Access the 'firstRegenerate' property correctly
        if ("generatecount" in savedStateObject) {
          console.log("generatecount", savedStateObject.generatecount);
          setGeneratecount(savedStateObject.generatecount);
          // setFirstRegenerate(savedStateObject.firstRegenerate);
        }
        if ("firstRegenerate" in savedStateObject) {
          setFirstRegenerate(true);
          // setFirstRegenerate(savedStateObject.firstRegenerate);
        } else {
          console.error(
            "firstRegenerate property is missing in the saved state object."
          );
        }

        // Assuming you want to extract all URL items into an array
        const itemsArray = Object.values(savedStateObject).filter(
          (item) => typeof item === "object" && "url" in item
        );
        setitems(itemsArray);
        localStorage.setItem("all_images", JSON.stringify(itemsArray));
      } catch (error) {
        console.error(
          "Error parsing savedStateString or accessing properties:",
          error
        );
      }
    }
  }, []);
  useEffect(() => {
    switch (true) {
      // case items?.length < 3:
      //   setWarningText('Use at least 3 images/videos. This will make your video look much better.');
      //   break;
      case items?.length > 5:
        setWarningText('5 media files are the optimal amount for high converting videos. You can only add 5 max.');
        break;
      case items?.length >= 3 && items?.length <= 5:
        setWarningText('');
        break;
      default:
        setWarningText('');
    }
  }, [items]);

  useEffect(() => {
    // Check local storage for the clearData flag
    const shouldClearData = localStorage.getItem("clearData") === "true";
    if (shouldClearData) {
      // Clear local state based on the flag
      setitems([]); // Clear the state
      setFirstRegenerate(false);
      localStorage.removeItem("imagePageState");
      setCheckData();

      // Optionally, reset or remove the flag to prevent clearing data on subsequent loads
      localStorage.removeItem("clearData");
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("imagePageState");
    // console.log('length check', items.length);
    // return
    if (items?.length === 0) {
      setFirstRegenerate(false);
    } else if (items?.length > 0) {
      setFirstRegenerate(true);
      setUploadSelfProgress(100);
      setCheckData();
    }
    // else if (checkData && items.length === 0) {
    //   setFirstRegenerate(false)
    // }
    // else if (checkData && items.length > 0) {
    //   setFirstRegenerate(true)
    // }
  }, [items]);
  const Card = ({ src, title, id, index, moveImage }) => {
    console.log("srcsrc", src);
    const ref = React.useRef(null);
    // const handleMouseEnterPlay = () => {
    //   if (ref.current) {
    //     ref.current.play();
    //   }
    // };

    // const handleMouseLeavePause = () => {
    //   if (ref.current) {
    //     ref.current.pause();
    //   }
    // };
    const [, drop] = useDrop({
      accept: "image",
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        /* console.log("drag index ",dragIndex);
 
         console.log("hover index ",hoverIndex);
 
         console.log("hover client Y ",hoverClientY);
 
         console.log("hover Middle Y ",hoverMiddleY);
 */

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        /*  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            console.log("in drop");
            return;
          }*/

        moveImage(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "image",
      item: () => {
        return { id, index };
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    const handlePlay = (event) => {
      if (ref.current) {
        ref.current.pause();
      }
    };
    return (
      // <div ref={ref} style={{ opacity }} className="card">

      <div
        className="generated-img-block"
        key={index}
        onMouseEnter={() => {
          setHoveredIndex(index);
          if (uploadAddLoader === null) {
            setAddHoveredIndex(false);
          }
        }}
        onMouseLeave={() => handleMouseLeave(index)}
      >
        {" "}
        {/* <div className="animated-border"></div> */}
        {videoExtensions?.some((ext) => src?.includes(ext)) ? (
          <>
            <button
              type="button"
              className="btn-circle play"
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                zIndex: 4,
                transition: '0.4s',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                padding: '10px',
                fontSize: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className="material-icons icon">play_arrow</span>
            </button>
            <video
              id={`video-${src}`}
              ref={ref}
              //  style={{ height: '100%', width: '100%' }} // Make video take up entire space
              src={src}
              type="video/mp4"
              // controls
              width={200}
              height={200}
              style={{ zIndex: 2, position: "inherit" }}
              key={index}
              className="imgzoom"
            // onPlay={handlePlay}
            // autoPlay={false}
            ></video>
            {/* <img
              src={require("../../images/video-icon.png")}
              alt="Video Icon"
              style={{ position: "absolute", top: '4px', left: '1px', zIndex: 3, height: '30px', width: '30px' }}
            /> */}
          </>
        ) : (
          <>
            {/* <div className="animated-border"></div> */}
            <img
              ref={ref}
              style={{ opacity }}
              key={index}
              src={src}
              className="imgzoom"
              alt="Generated from AI"
              width={200}
              height={200}
            // onClick={() => setSelectedImg(src)}
            />
            {/* <img
              src={require("../../images/image-icon.png")}
              alt="Image Icon"
              style={{ position: "absolute", top: '5px', left: '5px', zIndex: 3, height: '27px', width: '27px' }}
            /> */}
          </>
        )}
        <button onClick={toggleVisibility} type="button" className="btn-dots" >
          <span className="material-symbols-outlined dots-icon">more_vert</span>
        </button>
        {hoveredIndex === index && (
          <>
            {/* <button onClick={() => handleTrashClick(index)} className="icon-button" style={{ position: "absolute", top: -10, right: 4 }}>
              <img style={{ width: 24, height: 24, margin: 0, border: 'none' }} src={trash} alt="dlete_btn" />
            </button> */}

            {uploadLoader === index && uploadProgress != null && (
              <div
                className="custom-tooltip regenerate-action"
                style={{ zIndex: 5 }}
              >
                <div className="">
                  <p className="text-white font-bold mb-3">Generating image...</p>
                  <div className="flex items-center w-full">
                    <div className="progress-bar white mr-4">
                      <div
                        className="progress"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <h4 className="text-white">{uploadProgress.toFixed(0)}%</h4>
                  </div>
                </div>
              </div>
              // <div className="flex items-center w-100  mt-2">
              //   <div className="progress-bar ml-4">
              //     <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
              //   </div>
              //   <h4 className="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
              // </div>
            )}

            {uploadProgress === null && (
              <div
                className="custom-tooltip regenerate-action active"
                style={{ zIndex: 5 }}
                ref={tooltipRef}
              >
                {regenerate === index ? (
                  // <div>

                  //   <p style={{ color: "white", fontSize: 10 }}>Describe your image how you want it</p>
                  //   <textarea
                  //     name="enter desc" rows={4}
                  //     cols={40}
                  //     style={{
                  //       fontSize: 10,
                  //       color: "white",
                  //       backgroundColor:
                  //         "transparent",
                  //       border: '1px solid white',
                  //       borderRadius: 5,
                  //       marginTop: 3,
                  //       paddingLeft: 5,
                  //       paddingTop: 5
                  //     }}
                  //     value={textAreaValue}
                  //     onChange={handleTextAreaChange}
                  //   />
                  //   <button
                  //     className="btn btn-white btn-sm absolute bottom-4 right-3"
                  //     type="button"
                  //     onClick={() =>
                  //       setRegenerate(null)

                  //     }
                  //   >
                  //     ←
                  //   </button>
                  //   <button
                  //     className="btn btn-white btn-sm"
                  //     type="button"
                  //     onClick={() => {
                  //       handleSelfRegenerate(index);
                  //       setRegenerate(null);
                  //     }}

                  //   >
                  //     Submit
                  //   </button>

                  // </div>
                  <div className="">
                    <label className="text-white f-14">
                      Describe your image how you want it
                    </label>
                    <textarea
                      id="regenerate-text"
                      rows="2"
                      className="input-control white "
                      style={{ marginTop: 2 }}
                      placeholder="Write description..."
                    ></textarea>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-white btn-sm mr-2"
                        onClick={() => {
                          handleSelfRegenerate(index);
                          setRegenerate(null);
                        }}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-white-outline btn-sm"
                        onClick={() => setRegenerate(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  // </div>
                  <div className="text-center">
                    <button
                      className="btn btn-white btn-sm w-full"
                      type="button"
                      style={{ zIndex: 5 }}
                      // disabled={(generatecount === 7 || regeneratecount > 3) && true}
                      // onClick={() => setRegenerate(index)}
                      onClick={() => {
                        setIfImgIndex(index + 1); setIfImgIndexText("Re");
                        uploadAddProgress === null &&
                          !uploadProgress &&
                          setAddAnotherImagePopup(true)
                      }}
                    >
                      Change this media
                    </button>

                    {/* <div className="mt-2 mb-2">
                      <span className="text-white">OR</span>
                      </div> */}

                    <div className="flex justify-center mt-2 mb-4">
                      <span className="f-12 text-light-gray flex items-center" style={{ display: "none" }}>
                        <span className="material-symbols-outlined mr-1 f-18">
                          info
                        </span>{" "}
                        You can only regenerate image once
                      </span>
                    </div>

                    {/*  <div className='btn_main w-full'>
                        <button type="button" className="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                        </button>

                        <input className="btn btn-white btn-sm sxs" type="file" onChange={handleFileChangesss(index)} />

                      </div> 
                            */}
                    <div
                      className="tooltip-footer-action"
                      style={{ justifyContent: "center" }}
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-text-icon preview"
                        onClick={() => setSelectedImg(src)}
                        style={{ borderRight: "none" }}
                      >
                        <span className="material-symbols-outlined mr-2">
                          {" "}
                          visibility{" "}
                        </span>{" "}
                        Preview
                      </button>
                      <button
                        type="button"
                        id={`delbutton-${index}`}
                        className="btn btn-sm btn-text-icon delete"
                        onClick={() => handleTrashClick(index)}
                      >
                        <span className="material-symbols-outlined mr-2">
                          {" "}
                          delete{" "}
                        </span>{" "}
                        Delete
                      </button>
                      <div
                        className="deletebtnnext"
                        id={`delbuttontooltip-${index}`}
                        style={{ display: "none" }}
                      >
                        1 images minimum
                        {/* 3 images minimum */}
                      </div>
                    </div>

                    {/* {uploadLoader === index && uploadProgress != null && (
                      <div className="flex items-center w-100  mt-2">
                        <div className="progress-bar ml-4">
                          <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                        </div>
                        <h4 className="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      // </div>
    );
  };

  const moveImage = React.useCallback((dragIndex, hoverIndex) => {
    setitems((prevCards) => {
      const clonedCards = [...prevCards];
      const removedItem = clonedCards.splice(dragIndex, 1)[0];

      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);

  function ModalView({ isOpenPop, closePopupModal, children, increaseWidth, increaseHeight }) {
    if (!isOpenPop) return null;
    const popupStyle = {
      backgroundColor: "white",
    };

    if (increaseHeight) {
      popupStyle.height = increaseHeight;
    }
    if (increaseWidth) {
      popupStyle.maxWidth = increaseWidth;
    }
    return (
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
        style={{ zIndex: 6, padding: '10px' }}
      >
        <div
          className="reserve-my-spot-instantly-popup flex flex-col"
          // className="bg-white p-5 rounded-lg shadow-lg max-w-md"
          style={popupStyle}
        >
          {/* <div style={{ flex: "1 1 auto", overflowY: "auto" }}> */}
          {children}
          {/* </div> */}
        </div>
      </div>
    );
  }
  const openFilePicker = () => {
    document.getElementById("fileInput").click();
  };
  const openAnotherFilePicker = () => {
    document.getElementById("fileinputfielding")?.click();
  };
  const openStockFilePicker = () => {
    document.getElementById("fileinputfieldinginstock")?.click();
  };
  console.log("firstRegeneratefirstRegenerate", firstRegenerate);

  const simulateUploadProgress = () => {
    let progress = 0;
    const intervalId = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        setUploadCustomProgress(progress);
      } else {
        setIsLoading(false);
        clearInterval(intervalId);
        setUploadCustomProgress(0);
      }
    }, 1000);
    return intervalId;
  };
  const handleSingleAiImageGenetrate = async (aiText) => {
    // const waitingItem = [
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question }
    // ];
    // setitems([...items, ...waitingItem]);    
    // const lastIndex = items?.length;
    // handleSelfRegenerate(lastIndex, aiText);
    setIsLoading(true);
    const intervalId = simulateUploadProgress();
    const threeAiImages = await getThreeAiImages(aiText)
    if (threeAiImages) {
      const newData = threeAiImages?.map(item => ({
        ...item,
        url: item.link,
        link: undefined // Optional: remove the 'link' key if you don't need it anymore
      }));
      setitems([...items, ...newData])
      localStorage.setItem(
        "all_images",
        JSON.stringify([...items, ...newData])
      );
      setTimeout(() => {
        clearInterval(intervalId);
        setAddAnotherImagePopup(false)
        setIsLoading(false);
        setUploadCustomProgress(0);
      }, 2000);
    }
  };

  const handleReSingleAiImageGenetrate = async (aiText) => {
    // const waitingItem = [
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question },
    //   { url: "", vidtext: getformData?.subformData?.question }
    // ];
    // setitems([...items, ...waitingItem]);    
    // const lastIndex = items?.length;
    // 
    // handleSelfRegenerate(lastIndex, aiText);
    setIsLoading(true);
    const intervalId = simulateUploadProgress();
    const threeAiImages = await getThreeAiImages(aiText)
    if (threeAiImages) {
      const newData = threeAiImages?.map(item => ({
        ...item,
        url: item.link,
        link: undefined // Optional: remove the 'link' key if you don't need it anymore
      }));

      if (ifImgIndex) {
        setitems([...items,]);
        const updatedImages = [...items];
        updatedImages[ifImgIndex - 1] = newData[0];
        setitems(updatedImages);
        localStorage.setItem(
          "all_images",
          JSON.stringify(updatedImages)
        );
        console.log(updatedImages)
        console.log("updatedImages")
      } else {
        setitems([...items, ...newData])
        localStorage.setItem(
          "all_images",
          JSON.stringify([...items, ...newData])
        );
      }
      setTimeout(() => {
        setIfImgIndex(null);
        setIfImgIndexText(null);
        setUploadAddProgress(null);
        clearInterval(intervalId);
        setIsLoading(false);
        setUploadCustomProgress(0);
        setAddAnotherImagePopup(false)
      }, 2000);
    }
  };

  const searchInputRef = useRef(null);
  const handlePromptformSubmit = (e) => {
    e.preventDefault();
    console.log("textareaRef.current.value", textareaRef.current.value);
    const aiText = textareaRef.current.value;
    // setAddAnotherImagePopup(false);
    setShowAiScript(false);
    setIsLoading(true)
    // handleSingleAiImageGenetrate(aiText);
    if (ifImgIndex) {

      handleReSingleAiImageGenetrate(aiText)


    } else {
      handleSingleAiImageGenetrate(aiText)
    }
  };

  function InitialModal({ isOpen, title, body, btn_text, onClick }) {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "1000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        > */}
        <div className="custom-modal md custom-left">
          <div className="head">
            <img
              className="logo mx-auto mb-4"
              src="images/check-gradient.svg"
              alt="Check"
            />
            <h2 className="text-red mb-2" style={{ textAlign: "center" }}>
              It gets even better...
            </h2>
            <p
              style={{ textAlign: "center" }}
            >With your video idea,  <span className="font-semibold">our Al saved you a few hours </span><br /> and found high quality media for your video. Add more!
            </p>{" "}
          </div>

          <div className="body">
            <div
              className="mb-0"
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button type="button" className="btn btn-gradient" onClick={onClick}>
                Got it!
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }

  console.log({ vidItems });
  // useEffect(() =>{
  //   if(isSelectedEmpty){
  //     setTimeout(() => {
  //   setIsSelectedEmpty(false)
  //     }, 5000)
  //   }
  // },[isSelectedEmpty])
  return (
    <>
      {/* <Topbar /> */}
      <Layout HeaderName="Making an AI video">
        {/* <div> */}

        {/* new code html */}

        <div className="page-container">
          <div className="page-wrapper">
            <div className="container-xl mb-8">
              {show2btns ? (
                <>
                  <div className="ai-icon-animation mx-auto mb-2">
                    <img className="ai-icon-1" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img className="ai-icon-2" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img className="ai-icon-3" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img className="ai-icon-4" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                  </div>

                  <h4 className="text-center mb-8">Want to include your own images/videos?</h4>

                  <div className="container-sm">
                    <div className="selector-block-wrap">
                      <div className="card-wrap selector-block" onClick={() =>
                        uploadAddProgress === null &&
                        !uploadProgress &&
                        openAnotherFilePicker()}>
                        <img className="mb-2" src={require('../../images/upload-your-own-img.svg').default} alt="Vector" />
                        <h5>Upload your own</h5>
                        <input
                          className="btn btn-white btn-sm"
                          type="file"
                          onChange={(event) =>
                            handleAddFileChange(event)
                          }
                        />
   
                        <input
                          type="file"
                          style={{ display: 'none' }}

                        />
                      </div>


                      <div className="card-wrap selector-block" onClick={() => handleGenerate()}>
                        <img className="mb-2" src={require("../../images/choose-from-ai-img.svg").default} alt="Vector" />
                        <h5>Generate from AI</h5>
                      </div>


                    </div>
                    {/*uploadedImage && (
                    <div className="uploaded-image-preview">
                      <h5 className="text-center mt-4">Uploaded Image:</h5>
                      <img src={uploadedImage} alt="Uploaded" style={{ maxWidth: '100%', marginTop: '20px' }} />
                    </div>
                  )*/}
                  </div>
                </>
              ) : (
                <div className="get-images-container">


                </div>
              )}
            </div>
          </div>
        </div>


        {/* new code html ends */}


















        {/* {!showStockScreen &&  */}
        {/* <div
          className={
            user
              ? "fullscreen-page fullpageimg"
              : "fullscreen-page_logout fullpageimg"
          }
          style={{ overflow: "user ? " }}
        > */}


        {showrestpage && (
          <>
            <div
              className={
                user
                  ? "short-creator-block customStyle"
                  : "customStyleLogoutGetScript customStyleLogout short-creator-block"
              }
            >
              <div className="creator-wrapper">
                <div
                  className="mouseleave"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                  }}
                  onMouseEnter={() => cliearImageHover()}
                ></div>
                <div
                  className="mouseleave"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "25px",
                    right: "32%",
                  }}
                  onMouseEnter={() => cliearImageHover()}
                ></div>
                <div
                  className="mouseleave"
                  style={{
                    position: "absolute",
                    height: "20%",
                    width: "100%",
                    left: 0,
                    bottom: "-70px",
                  }}
                  onMouseEnter={() => cliearImageHover()}
                ></div>

                {/* <h3 className="mb-4">Make your video look amazing with images/videos.</h3>
              <label for="insert-script" className="label">
                By clicking "Add more" you can find 3+ million files from our library.
              </label> */}
                {/* <div
                className="progress-steps"
                onMouseEnter={() => cliearImageHover()}
              >
                <div
                  className="step-block completed"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  style={{ cursor: hover && "pointer" }}
                  onClick={() => {
                    console.log("hoverhover", hover);
                    hover && navigate("/get-script");
                    setitems([]);
                    localStorage.removeItem("imagePageState");
                  }}
                >
                  <span
                    className="step-label"
                    style={{ color: hover && "#F11D28" }}
                  >
                    1. Choose Script
                  </span>
                  <span
                    className="step-indicator"
                    style={{ transform: hover && "scale(1.06)" }}
                  >
                    <span className="material-icons check-icon">check</span>
                  </span>
                </div>
                <div className="step-block active">
                  <span className="step-label">2. Choose Images</span>
                  <span className="step-indicator">
                    <span className="material-icons check-icon">check</span>
                  </span>
                </div>
                {user && <div className="step-block">
                  <span className="step-label">3. Choose Video</span>
                  <span className="step-indicator">
                    <span className="material-icons check-icon">check</span>
                  </span>
                </div>
                }
              </div> */}

                {/* <div style={{ "background": "url(" + loaderImage2 + ") no-repeat center center", "backgroundSize": "160px", "minHeight": "185px", "position": "absolute", "width": "67%", "zIndex": "0" }}></div> */}





                <>
                  <div class="ai-icon-animation mx-auto mb-2">
                    <img class="ai-icon-1" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img class="ai-icon-2" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img class="ai-icon-3" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                    <img class="ai-icon-4" src={require('../../images/ai-icon.svg').default} alt="AI Icon" />
                  </div>
                  <h4 class="text-center mb-8">Your own chosen images</h4>
                  <div className="generated-images" style={{ "position": "relative", "background": "white" }}>

                    {
                      // items?.map((item, index) => (
                      <>
                        {React.Children.toArray(
                          items?.map((image, index) => (
                            <Card
                              key={index} // Added key prop to fix React warning
                              src={image?.url}
                              title={image?.title}
                              id={index}
                              index={index}
                              moveImage={moveImage}
                            />
                          ))
                        )}
                      </>
                    }
                    <input
                      className="btn btn-white btn-sm hidden"
                      type="file"
                      id="fileinputfielding"
                      onChange={(event) => {
                        handleAddFileChange(event);
                        setAddAnotherImagePopup(false);
                      }}
                    // onChange={() => console.log('File input clicked')}
                    />
                    {items?.length < 5 && (
                      <div
                        className="generated-img-block blank"
                        onMouseEnter={handleMouseAddEnter}
                      // onMouseLeave={handleMouseAddLeave}
                      >
                        <div
                          className="text-center cursor-pointer"
                          onClick={() =>
                            uploadAddProgress === null &&
                            !uploadProgress &&
                            // setAddAnotherImagePopup(true)
                            openAnotherFilePicker()
                          }
                        >
                          <span className="material-symbols-outlined text-gray mb-4">
                            cloud_upload
                          </span>
                          {/* <p className="text-gray m-0">Add more</p> */}
                          <p className="text-gray m-0">Add another image</p>

                          <div className="btn_main">
                            <button
                              type="button"
                              className="btn btn-white btn-sm myownuploadimg"
                              style={{ cursor: "pointer" }}
                            >
                              Add more
                            </button>
                          </div>
                        </div>

                        {addhoveredIndex && (
                          <>
                            {uploadAddProgress === null && !uploadProgress && (
                              <div className="custom-tooltip regenerate-action active">
                                <div className="text-center">
                                  {/*   <button
                                  className="btn btn-white btn-sm w-full customclassauto"
                                  type="button"
                                  onClick={handleRegenerate}
                                  disabled={generatecount === 5 && true}
                                  // onClick={() =>
                                  //   setRegenerate()
                                  // }
                                >
                                  Generate with AI
                        </button>
                                <div className="mt-2 mb-2">
                          <span className="text-white">OR</span>
                        </div> 
                      */}
                                  <div className="flex justify-center mt-2 mb-4">
                                    <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                                      <span className="material-symbols-outlined mr-1 f-18">
                                        info
                                      </span>{" "}
                                      {/* File types : .jpg, .jpeg, .png, .webp */}
                                      File types : .jpg, .jpeg, .png, .webp, .mp4
                                      {/* File types : .jpg, .jpeg, .png, */}
                                    </span>
                                  </div>

                                  <div className="btn_main">
                                    <button type="button" className="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                                    </button>

                                    <input
                                      className="btn btn-white btn-sm"
                                      type="file"
                                      onChange={(event) =>
                                        handleAddFileChange(event)
                                      }
                                    // onChange={() => console.log('File input clicked')}
                                    />
                                  </div>


                                  {uploadAddLoader && uploadAddProgress != null && (
                                    <div className="flex items-center w-100  mt-2">
                                      <div className="progress-bar ml-4">
                                        <div className="progress" style={{ width: `${uploadAddProgress}%` }}></div>
                                      </div>
                                      <h4 className="ml-4" style={{ color: "white" }}>{uploadAddProgress.toFixed(0)}%</h4>
                                    </div>

                                  )}
                                </div>
                              </div>
                            )}
                            {uploadAddProgress === null && uploadProgress && (
                              <div
                                className="custom-tooltip regenerate-action active"
                                style={{ zIndex: 6 }}
                              >
                                <div className="text-center">
                                  <div className="flex justify-center mt-2 mb-4">
                                    <span className="f-14 text-light-gray flex items-center uploadallowedtypes">
                                      Wait until AI generates the image
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}

                            {uploadAddLoader && uploadAddProgress != null && (
                              <div className="custom-tooltip regenerate-action">
                                <div className="">
                                  <p className="text-white font-bold mb-3">
                                    Generating image...
                                  </p>
                                  <div className="flex items-center w-full">
                                    <div className="progress-bar white mr-4">
                                      <div
                                        className="progress"
                                        style={{
                                          width: `${uploadAddProgress}%`,
                                        }}
                                      ></div>
                                    </div>
                                    <h4 className="text-white">
                                      {uploadAddProgress.toFixed(0)}%
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                          // <div className="popup" style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#fff' }}>
                          //   {regeneratecount == 0 ? (
                          //     <a href="#" className="f-14 text-gray flex items-center mb-2" onClick={() => handleFormSubmit(index)}><span className="material-icons mr-1 f-20">restart_alt</span> Regenerate</a>
                          //   ) : (
                          //     <p></p>
                          //   )}

                          //
                          // </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* <div
                    className="short-limit-info invideoscreen"
                    id="wrongfiletypemsg"
                    style={{ display: "none", marginBottom: "5px" }}
                  >
                    <span className="material-icons">info</span>File type not
                    allowed. Please select a JPG, PNG, or WEBP file.
                  </div> */}

                  <div
                    className="loaderbtnscript"
                    onMouseEnter={() => cliearImageHover()}
                  >
                    <div class="flex justify-center">
                      <button type="button" class="btn btn-gradient btn-text-icon mb-2" onClick={() => handleContinue(items)}><span class="material-icons f-24 mr-2">auto_awesome</span>Generate my video</button>
                    </div>
                    {/* <button
                  type="button"
                  className="btn btn-gradient"
                  onClick={() => handleContinue(items)}
                >
                  Continue
                </button> */}
                    {loading ? <div className="loader"></div> : ""}
                  </div>
                  {/* {!warningText &&
                <div
                  className="short-limit-info invideoscreen"
                  style={{
                    marginRight: "auto",
                    marginTop: "20px",
                    alignItems: "center",
                    width: "fit-content",
                    backgroundColor: "rgba(255, 161, 44, 0.1)",
                  }}
                >
                  <span className="material-icons" style={{ color: "#ffa12c" }}>info</span>Early feature: Our stock footage may not be super accurate but we're working hard to make it better.
                </div>
              } */}
                  {warningText &&
                    <div
                      key={animationKey}
                      className="short-limit-info invideoscreen mt-5 warning-animation"
                      style={{ width: "max-content" }}
                      onMouseEnter={() => cliearImageHover()}
                    >
                      <span className="material-icons">info</span>
                      {warningText}
                    </div>
                  }
                  {/* 
            {
              imageLimit &&
              <p style={{ color: "red", marginTop: 5 }}>you cannot upload image more than 5 images</p>
            } */}

                  {/* <p className='short-limit-info' style={{ color: "red", marginTop: 8 }}>
              <span className="material-icons">check_circle</span>You can only get two more upload/AI images
            </p> */}
                  {/* {
                    generate && items.length < 3 && (
                      <div
                        key={animationKey}
                        className="short-limit-info invideoscreen mt-5 warning-animation"
                        style={{ width: "max-content" }}
                        onMouseEnter={() => cliearImageHover()}
                      >
                        <span className="material-icons">info</span>
                        Use at least 3 images. This will make your video look
                        much better.
                      </div>
                    )
                  } */}
                  {/* {
                    items.length > 5 && (
                      <div
                        key={animationKey}
                        className="short-limit-info invideoscreen mt-5 warning-animation"
                        style={{ width: "max-content" }}
                        onMouseEnter={() => cliearImageHover()}
                      >
                        <span className="material-icons">info</span>
                        5 media files are the optimal amount for high converting videos You can only add 5 max.
                      </div>
                    )
                  } */}
                  {/* { !generate && (
                      <div
                        className="short-limit-info invideoscreen mt-5"
                        style={{ width: "max-content" }}
                        onMouseEnter={() => cliearImageHover()}
                      >
                        <span className="material-icons">info</span>
                        You can only generate 5 images from AI.
                      </div>
                    )  }*/}
                  {/* <p className='short-limit-info' style={{ color: "red", marginTop: 8 }}>
                   <span className="material-icons" style={{ color: "red" }}>check_circle</span>you can only generate 5 images from AI.
                  </p> */}
                </>

                {checkData && items?.length > 0 && (
                  <>
                    <label htmlfor="insert-script" className="label">
                      Generated Imagesww
                    </label>

                    <div className="generated-images" style={{ "position": "relative", "background": "white" }}>
                      {
                        items?.map((item, index) => (
                          <>
                            <div
                              className="generated-img-block"
                              key={index}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <img
                                key={index}
                                src={item.url}
                                className="imgzoom"
                                alt="Generated from DALL-E"
                                width={200}
                                height={200}
                                onClick={() => setSelectedImg(item.url)}
                              />
                              {hoveredIndex === index && (
                                <>
                                  <button
                                    onClick={() => handleTrashClick(index)}
                                    className="icon-button"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: 4,
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 24,
                                        height: 24,
                                        margin: 0,
                                        border: "none",
                                      }}
                                      src={trash}
                                      alt="dlete_btn"
                                    />
                                  </button>

                                  <div className="custom-tooltip regenerate-action active">
                                    {regenerate === index ? (
                                      <div>
                                        <button
                                          className="btn btn-white btn-sm absolute bottom-4 right-3"
                                          type="button"
                                          onClick={() => setRegenerate(null)}
                                        >
                                          ←
                                        </button>

                                        <p
                                          style={{ color: "white", fontSize: 10 }}
                                        >
                                          Describe your image how you want it
                                        </p>
                                        <textarea
                                          name="enter desc"
                                          rows={4}
                                          cols={40}
                                          style={{
                                            fontSize: 10,
                                            color: "white",
                                            backgroundColor: "transparent",
                                            border: "1px solid white",
                                            borderRadius: 5,
                                            marginTop: 3,
                                            paddingLeft: 5,
                                            paddingTop: 5,
                                          }}
                                          value={textAreaValue}
                                          onChange={handleTextAreaChange}
                                        />
                                        <button
                                          className="btn btn-white btn-sm"
                                          type="button"
                                          disabled={regeneratecount > 0 && true}
                                          onClick={() => {
                                            handleSelfRegenerate(index);
                                            setRegenerate(null);
                                          }}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="text-center">
                                        <button
                                          className="btn btn-white btn-sm"
                                          type="button"
                                          disabled={regeneratecount > 0 && true}
                                          onClick={() => setRegenerate(index)}
                                        >
                                          Regenerate
                                        </button>
                                        <div className="flex justify-center mt-3 mb-3">
                                          <span className="f-12 text-light-gray flex items-center">
                                            <span className="material-symbols-outlined mr-1 f-18">
                                              info
                                            </span>{" "}
                                            You can only regenerate image once
                                          </span>
                                        </div>
                                        <div className="mt-2 mb-2">
                                          <span className="text-white">OR</span>
                                        </div>

                                        <div className="btn_main">
                                          <button
                                            type="button"
                                            className="btn btn-white btn-sm "
                                            style={{ cursor: "pointer" }}
                                          >
                                            Upload your own
                                          </button>

                                          <input
                                            className="btn btn-white btn-sm sxs"
                                            type="file"
                                            onChange={handleFileChangesss(index)}
                                          />
                                        </div>

                                        {uploadLoader === index &&
                                          uploadProgress != null && (
                                            <div className="flex items-center w-100  mt-2">
                                              <div className="progress-bar ml-4">
                                                <div
                                                  className="progress"
                                                  style={{
                                                    width: `${uploadProgress}%`,
                                                  }}
                                                ></div>
                                              </div>
                                              <h4
                                                className="ml-4"
                                                style={{ color: "white" }}
                                              >
                                                {uploadProgress.toFixed(0)}%
                                              </h4>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ))

                        // )
                      }
                      {items.length < 5 && (
                        <div
                          className="generated-img-block blank"
                          onMouseEnter={handleMouseAddEnter}
                          onMouseLeave={handleMouseAddLeave}
                        >
                          <div className="text-center">
                            <span className="material-symbols-outlined text-gray mb-4">
                              cloud_upload
                            </span>
                            <p className="text-gray m-0">Add more</p>
                          </div>
                          {addhoveredIndex && (
                            <>
                              <div className="custom-tooltip regenerate-action active">
                                <div className="text-center">
                                  <button
                                    className="btn btn-white btn-sm"
                                    type="button"
                                    onClick={handleRegenerate}
                                  // onClick={() =>
                                  //   setRegenerate()
                                  // }
                                  >
                                    Generate with AI
                                  </button>
                                  <div className="mt-2 mb-2">
                                    <span className="text-white">OR</span>
                                  </div>

                                  <div className="btn_main">
                                    <button
                                      type="button"
                                      className="btn btn-white btn-sm "
                                      style={{ cursor: "pointer" }}
                                    >
                                      Upload your own
                                    </button>

                                    <input
                                      className="btn btn-white btn-sm"
                                      type="file"
                                      onChange={(event) =>
                                        handleAddFileChange(event)
                                      }
                                    />
                                  </div>

                                  {uploadAddLoader &&
                                    uploadAddProgress != null && (
                                      <div className="flex items-center w-100  mt-2">
                                        <div className="progress-bar ml-4">
                                          <div
                                            className="progress"
                                            style={{
                                              width: `${uploadAddProgress}%`,
                                            }}
                                          ></div>
                                        </div>
                                        <h4
                                          className="ml-4"
                                          style={{ color: "white" }}
                                        >
                                          {uploadAddProgress.toFixed(0)}%
                                        </h4>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </>
                            // <div className="popup" style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#fff' }}>
                            //   {regeneratecount == 0 ? (
                            //     <a href="#" className="f-14 text-gray flex items-center mb-2" onClick={() => handleFormSubmit(index)}><span className="material-icons mr-1 f-20">restart_alt</span> Regenerate</a>
                            //   ) : (
                            //     <p></p>
                            //   )}

                            //
                            // </div>
                          )}
                        </div>
                      )}
                    </div>

                    <Modal
                      isOpen={selectedImg !== null}
                      imgSrc={selectedImg}
                      onClose={() => setSelectedImg(null)}
                    />

                    <div className="loaderbtnscript">
                      <button
                        type="button"
                        className="btn btn-gradient"
                        onClick={() => handleContinue(items)}
                      >
                        Continue
                      </button>
                      {loading ? <div className="loader"></div> : ""}
                    </div>

                    {imageLimit && (
                      <p style={{ color: "red", marginTop: 5 }}>
                        you cannot upload image more than 5 images
                      </p>
                    )}
                    <p
                      className="short-limit-info"
                      style={{ color: "red", marginTop: 8 }}
                    >
                      <span className="material-icons">check_circle</span>You can only
                      get two more upload/AI images
                    </p>
                  </>
                )}

                <div className="mb-6" onMouseEnter={() => cliearImageHover()}>

                </div>

              </div>

              {/* <div className="advice-wrapper">
            <h5 className="font-bold mb-3">Yatter's Premium Advice:</h5>
            <ul className="advice-points">
              <li className="flex">
                <span className="material-symbols-outlined f-24 gradient-text mr-3">
                  {" "}
                  task_alt{" "}
                </span>
                <span className="f-14">
                  Our best performing videos use stock footage. You can access {' '}
                  <span className="font-semibold"> 3+ million videos </span> with Premium!
                </span>
              </li>
              <li className="flex">
                <span className="material-symbols-outlined f-24 gradient-text mr-3">
                  {" "}
                  task_alt{" "}
                </span>
                <span className="f-14">
                  Stock videos perform so well because they{" "}
                  <span className="font-semibold">make your video more dynamic.</span>
                </span>
              </li>
              <li className="flex">
                <span className="material-symbols-outlined f-24 gradient-text mr-3">
                  {" "}
                  task_alt{" "}
                </span>
                <span className="f-14">
                  If possible, use people. It makes your video{" "}
                  <span className="font-semibold">super relatable</span>{" "}
                  and professional!
                </span>
              </li>
            </ul>
            <div className="formula-tooltip">
              <a href="#!" className="text-red flex items-center font-medium">
                <span className="material-symbols-outlined f-22 mr-2">
                  {" "}
                  help{" "}
                </span>
                How do we get this formula?
              </a>
              <div className="custom-tooltip relative">
                <span className="text-white">
                  Our advanced AI studies millions of high quality videos and it makes sure that your video looks and feels even better than a professional video (except it's done in 5 mins instead of 1 week!)
                </span>
              </div>
            </div>
          </div> */}
            </div>

          </>
        )}
        <div
          className="short-limit-info invideoscreen"
          style={{
            display: "none",
            marginRight: "auto",
            marginTop: "20px",
            alignItems: "center",
          }}
          id="msgbottomimage"
        >
          <span className="material-icons">info</span>Not working? Remove special
          characters that are not used often like these: <>/\/</>;:~
        </div>

        {/* </div> */}
        {/* // } */}

        {/* </div> */}
        {/* <Footer /> */}

        {/*<div className="example-part" onMouseEnter={() => cliearImageHover()}>
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
           <img className="media-file" src={`${process.env.PUBLIC_URL}/images/ai-example.jpg`} alt="Example media"/> 
          <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>*/}
      </Layout>
      <ModalView isOpenPop={isModalPopOpen} closePopupModal={closePopModal}>
        {/* <div className="reserve-my-spot-instantly-popup" onMouseEnter={() => cliearImageHover()}> */}
        <div className="text-center">
          <img className="mx-auto mb-6" src={image} alt="Images" />

          <div className="mb-6">
            {/* <h3 className="mb-6 text-center">Are Images Ready to Go?</h3>
                    <p className="f-18 mb-0">
                      Images will appear in this order during the video. Change
                      the order or delete any if necessary.
                    </p> */}
            <h3 className="mb-6 text-center" onClick={() =>
              uploadAddProgress === null &&
              !uploadProgress &&
              // setAddAnotherImagePopup(true)
              openAnotherFilePicker()
            }>Add your own images:</h3>
            <p className="f-18 mb-0">
              Personalize your video for your entire audience with your own
              images! Or just continue to the next step...
            </p>
          </div>

          <div>
            <button
              type="button"
              className="btn btn-gradient mb-6"
              onClick={() => handleContinue(items)}
            >
              Confirm & Continue
            </button>
            <br />
            <a className="text-gray" href="#!" onClick={closePopModal}>
              Go back to modify
            </a>
          </div>
        </div>
        {/* </div> */}
      </ModalView>

      <Modal
        isOpen={selectedImg !== null}
        imgSrc={selectedImg}
        onClose={() => setSelectedImg(null)}
      />
      <ModalView isOpenPop={addAnotherImagePopup} increaseWidth={"980px"} increaseHeight={!isLoading && !showAiScript && stockDataSuccess ? "100%" : false}>
        <>
          <button
            type="button"
            className="btn-close-icon"
            onClick={() => {
              setIfImgIndex(null);
              setIfImgIndexText(null);
              setAddAnotherImagePopup(false);
              setShowAiScript(false);
              setShowDetails("");
            }}
          >
            <span className="material-icons"> close </span>
          </button>
          {!isLoading && !showAiScript && <h1 className="">Add more files</h1>}
          {!isLoading && !showAiScript && (
            <div className="custom-modal lg overflow-y-auto">
              <div className="mb-5">
                <div className="search-input-wrap mb-6">
                  <form >
                    <input type="text"
                      ref={searchInputRef}
                      // name="keyword"
                      onKeyPress={handleKeyPress}
                      id="insert-script" className="input-control !m-0 imitatefocus" placeholder="Search any topic here..." />
                    <button type="button" className="btn btn-gradient"
                      onClick={() => getStockData()}
                      style={{ cursor: "pointer" }}>Search</button>
                  </form>
                </div>


                {stockDataSuccess && <>  <div className="tab-nav mb-5">
                  <button
                    type="button"
                    className={`${isTabOpen === "image" ? "tab-link active" : "tab-link"
                      }`}
                    onClick={() => setIsTabOpen("image")}
                  >
                    Images
                  </button>
                  <button
                    type="button"
                    className={`${isTabOpen === "video" ? "tab-link active" : "tab-link"
                      }`}
                    onClick={() => setIsTabOpen("video")}
                  >
                    Videos
                  </button>
                </div>
                  {isTabOpen === "image" ? (
                    <>
                      <div
                        className="stock-media-wrap"
                        style={{ position: "relative" }}
                      >
                        {stockImages?.map((item, index) => (
                          <div key={item?.id} style={{ position: "relative" }}>
                            <div
                              key={item?.id}
                              className={`stock-img-block ${selectedContent?.some(
                                (selectedImage) => selectedImage?.id === item?.id
                              )
                                ? "selected"
                                : ""
                                }`}
                              onClick={() => {
                                toggleItemSelection(item);
                              }}
                            >
                              {/* <div className="animated-border"></div> */}
                              <img src={item?.link} alt="Generated Image" />
                              <span className="select-circle">
                                <span className="material-symbols-outlined check-icon f-18">
                                  {" "}
                                  done{" "}
                                </span>
                              </span>
                              <img
                                src={require("../../images/image-icon.png")}
                                alt="Image Icon"
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  left: "5px",
                                  zIndex: 3,
                                  height: "27px",
                                  width: "27px",
                                }}
                              />
                            </div>
                            {chooseImageTooltip && chooseImageTooltipid === item.id && (
                              <div className="custom-tooltip regenerate-action active" style={{ position: "absolute", paddingBottom: 30, bottom: "10px", left: "50%", transform: "translateX(-50%)" }}>
                                <div className="text-center">
                                  <div className="flex justify-center">
                                    <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                                      You can choose 5 files.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="vid_class stock-media-wrap"
                        style={{ position: "relative" }}
                      >
                        {stockVideos?.map((item, index) => (
                          <div key={item?.id} style={{ position: "relative" }}>
                            <div
                              key={item?.id}
                              className={`stock-img-block relative ${selectedContent?.some(
                                (selectedImage) => selectedImage?.id === item?.id
                              )
                                ? "selected"
                                : ""
                                }`}
                              onClick={() => {
                                toggleItemSelection(item);
                              }}
                            >
                              {/* <div className="animated-border"></div> */}
                              <button
                                type="button"
                                className="btn-circle play"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  margin: "auto",
                                  zIndex: 4,
                                  transition: "0.4s",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  padding: "10px",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedImg(item?.link);
                                  // setSelectedImg('../../images/index.H.264')
                                }}
                              >
                                <span className="material-icons icon">play_arrow</span>
                              </button>
                              <video>
                                <source src={item?.link} type="video/mp4" />
                              </video>
                              <span className="select-circle">
                                <span className="material-symbols-outlined check-icon f-18">
                                  {" "}
                                  done{" "}
                                </span>
                              </span>
                              <img
                                src={require("../../images/video-icon.png")}
                                alt="Video Icon"
                                style={{
                                  position: "absolute",
                                  top: "4px",
                                  left: "1px",
                                  zIndex: 3,
                                  height: "30px",
                                  width: "30px",
                                }}
                              />


                            </div>
                            {chooseImageTooltip && chooseImageTooltipid === item.id && (
                              <div className="custom-tooltip regenerate-action active" style={{ position: "absolute", paddingBottom: 30, bottom: "0px", left: "50%", transform: "translateX(-50%)" }}>
                                <div className="text-center">
                                  <div className="flex justify-center">
                                    <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                                      You can choose 5 files.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                        ))}
                      </div>
                    </>
                  )}
                </>
                }
              </div>

              {stockDataSuccess && <div className="stock-image-footer border-light-top pt-3">
                <span>
                  <span className="font-semibold">
                    {selectedContent?.length} selected
                  </span>{" "}
                  (You can choose {5 - (items.length + selectedContent?.length)} more files)
                </span>
                <button
                  ref={addTovideoRef}
                  type="button"
                  className="btn btn-gradient relative"
                  // onClick={() => setShowStockScreen(false)}
                  onClick={() => {
                    if (selectedContent?.length == 0) {
                      setIsSelectedEmpty(true)
                      return;
                    }
                    if (ifImgIndex) {
                      setUploadLoader(ifImgIndex - 1);
                      console.log('ifImgIndex', ifImgIndex)
                      const updatedImages = [...items];
                      updatedImages.splice(ifImgIndex - 1, 1, ...selectedContent);
                      setitems(updatedImages);
                      localStorage.setItem(
                        "all_images",
                        JSON.stringify(updatedImages)
                      );
                      setSelectedContent([])

                    } else {
                      setitems([...items, ...selectedContent]);
                      localStorage.setItem(
                        "all_images",
                        JSON.stringify([...items, ...selectedContent])
                      );
                    }
                    setIfImgIndex(null);
                    setIfImgIndexText(null)
                    setUploadAddProgress(null);
                    setAddAnotherImagePopup(false);
                    setSelectedContent([])
                    // setIsTabOpen('image')
                  }}
                >
                  Add to my video
                  {isSelectedEmpty &&
                    <div className="custom-tooltip regenerate-action active" style={{ width: '180px' }}>
                      <div className="text-center">
                        <div className="flex justify-center mt-2 mb-4">
                          <span className="f-12 text-light-gray flex items-center uploadallowedtypes">
                            <span className="material-symbols-outlined mr-1 f-18">
                              info
                            </span>{" "}
                            Please choose atleast 1 image or video first. Thanks!
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </button>
              </div>
              }

              <div className="or-divider full">
                <span>OR</span>
              </div>

              <div className="selector-block-wrap compact">
                <div className="selector-block" onClick={openAnotherFilePicker}>
                  <img
                    src={require("../../images/upload-your-own-img.svg").default}
                    alt="Vector"
                  />
                  <h5>Upload your own</h5>
                </div>

                {/* <div className="selector-block" onClick={() => {
                            setShowAiScript(true);
                            setSelectedContent([])
                            setStockImages([])
                            setStockVideos([])
                            setStockDataSuccess(false)
                        }}>
                          <img
                            src={require("../../images/choose-from-ai-img.svg").default}
                            alt="Vector"
                          />
                          <h5>Generate from AI</h5>
                        </div> */}
                <div className="selector-block prmeium-feature-block bg-light-gradient" onClick={() => {
                  //if(user){ 
                  setShowAiScript(true);
                  setSelectedContent([])
                  setStockImages([])
                  setStockVideos([])
                  setStockDataSuccess(false)
                  //}
                }}>
                  {/*<span className="prmeium-feature-tag">
                  <img src={require("../../images/premium.svg").default} alt="premium"/>
                  <span>Only with Premium</span>
                      </span>*/}

                  <img src={require("../../images/choose-from-ai-img.svg").default} alt="Vector" />
                  <h5 className="flex items-center">Generate from AI
                    <span className="text-black flex justify-center items-center">
                      <span className="material-symbols-outlined f-24 ml-2"> info </span>
                    </span>
                  </h5>
                </div>
                {/* selector-block */}
              </div>
            </div>

          )}
          {showAiScript && !isLoading && (
            <form className="creator-form" onSubmit={handlePromptformSubmit}>
              <div className="mb-6">
                <label for="insert-script" className="label mb-2">
                  Describe your image how you want it
                  <br />
                  {/* <span className="f-13 text-gray">
               This script will be said inside your AI video. You can edit it
                below before continuing.
      
              </span> */}
                </label>

                <div className="ai-script-card bg-light-gray p-3 border-light rounded">
                  {/* <div className="flex items-center justify-center mb-2">
                <span className="material-symbols-outlined f-24 gradient-text mr-2">
                  {" "}
                  task_alt{" "}
                </span>
                <span className="f-14 font-bold">Read it below:</span>
              </div> */}
                  <textarea
                    id="insert-script"
                    name="aiText"
                    // value={aiText}
                    ref={textareaRef}
                    rows="4"
                    className="input-control white border-light"
                    placeholder="Write description..."
                  // onChange={(e) => {
                  //   e.stopPropagation();
                  //   setAiText(e.target.value )
                  //   }
                  // }
                  ></textarea>
                </div>
              </div>

              <div className="loaderbtnscript">
                <button
                  type="submit"
                  className="btn btn-gradient"
                  disabled={aiText ? false : true}
                  onClick={() => {
                    setAddAnotherImagePopup(false)
                    setShowAiScript(false)
                    handleSingleAiImageGenetrate()
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          {isLoading && !showAiScript && (
            <div className="choose-img-inputs ai-generated-img-loader">
              <div className="w-full ai-generated-img-inner">
                <div className="flex justify-center p-4 pb-8">
                  <div className="spinner-box">
                    <div className="configure-border-1">
                      <div className="configure-core"></div>
                    </div>
                    <div className="configure-border-2">
                      <div className="configure-core"></div>
                    </div>
                  </div>
                </div>
                <p className="font-bold mb-3 text-center">
                  We're creating 1 cool AI image for your video. Give us
                  20 seconds
                </p>

                <div className="flex items-center w-full">
                  <div className="progress-bar mr-4">
                    <div
                      className="progress"
                      style={{ width: `${uploadCustomProgress}%` }}
                    ></div>
                  </div>
                  <h4 className="">{uploadCustomProgress.toFixed(0)}%</h4>
                </div>
              </div>
            </div>
          )}
        </>
      </ModalView>
      <InitialModal
        isOpen={isInitialModal}
        onClick={() => {
          setIsInitialModel(false);
        }}
      />
    </>
  );
}




export default GetImages;