import axios from "axios";
import Cookies from "js-cookie";

export const getThreeAiImages = async (textscript) => {
  try {
    const mobileimage= localStorage.getItem("mobileImg")
      const response = await axios.post(
      `https://app.yatter.ai/webs/getpexels_regenerate_3imagesapi.php`,
      {textscript, mobileimage}
    );
    console.log({stockImageResponse: response})
      return response?.data;
  } catch (error) {
    return error;
  }
};